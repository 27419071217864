import React, { useState } from 'react';
import { Box, Chip, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { CloseSmallIcon, SearchIconPro } from '../Icons';
import { useDispatch, useSelector } from 'react-redux';
import { filterdeleteSearchOption, filtersearchOption } from 'src/store/action/editor.Action';
import { useTranslation } from 'react-i18next';

const FilterSearchChip = () => {
    const dispatch = useDispatch()
    const filterClauses = useSelector(state=>state.editorReducer.filterClauses)
    const [value, setValue] = useState("");
    const { t } = useTranslation();

    const handleChange = e => {
        setValue(e.target.value);
    };

    const handleKeyDown = evt => {
        if (['Enter', 'Tab', ','].includes(evt.key)) {
            evt.preventDefault();
            const clause = value.trim();

            if (clause) {
                dispatch(filtersearchOption(clause))
                setValue('');
            }
        }
    };

    const handleDelete = clause => e => {
        dispatch(filterdeleteSearchOption(clause))
    };

    const isOperator = clause => {
        return ['OR', 'AND'].includes(clause.toUpperCase());
    };

    return (
        <Box className="search-chip dark">
            <OutlinedInput
                id="clauses"
                placeholder={t("editorBase.search")}
                fullWidth
                endAdornment={<InputAdornment position="end"><SearchIconPro /></InputAdornment>}
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            {filterClauses.map((clause, i) => (
                <Chip
                    key={i}
                    label={!isOperator(clause) ? clause : clause.toUpperCase()}
                    title={clause}
                    onDelete={handleDelete(clause)}
                    deleteIcon={<IconButton><CloseSmallIcon color="#ffffff" height="8" width="8" /></IconButton>}
                />
            ))}
        </Box>
    );
};

export default FilterSearchChip;
