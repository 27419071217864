import React, { useState } from 'react';
import { Box } from '@mui/material';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@mui/material';

const tabProps = index => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
};
const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Box>
    );
};
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

const TabNav = props => {
    const [tabValue, setTabValue] = useState(props.defaultValue || 0);
    // tap function onchange
    const handleChangeTap = (event, newValue) => {
        setTabValue(newValue);
        if(props.onChangeTab) {
            props.onChangeTab({ filter: props.data[newValue].heading });
        }
    };
    return (
        <Box className={props.classes ? `tabbed-navigation ${props.classes}` : 'tabbed-navigation'}>
            <Tabs value={tabValue} onChange={handleChangeTap} variant={props.variant} centered={props.centered}>
                {map(props.data,
                    (item, i) => {
                        return <Tab key={i} label={item.heading} {...tabProps(i)} {...item.headingProps} disableRipple={true} />;
                    }
                )}
            </Tabs>
            {map(props.data,
                (item, i) => {
                    return (
                        <TabPanel key={i} value={tabValue} index={i} className={props.tabpanl}>
                            {item.content}
                        </TabPanel>
                    );
                })
            }
        </Box>
    );
};

export default TabNav;
