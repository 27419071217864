import { createReducer } from "@reduxjs/toolkit";
import { helpButtonAction, portalsButtonAction } from "../action/bottomNavActions";

const initialState = {
    helpPageOpen: false,
    portalsPageOpen: false
};

const bottomNavReducer = createReducer(initialState, builder => {
    builder
        .addCase(helpButtonAction, (state, action) => {
            state.helpPageOpen = action.payload;
        })
        .addCase(portalsButtonAction, (state, action) => {
            state.portalsPageOpen = action.payload;
        })
        .addDefaultCase((state, action) => { });
});

export default bottomNavReducer;