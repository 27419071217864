import { createReducer } from '@reduxjs/toolkit';
import { contentPostById } from '../action/content.action';

const initialState = {
  contentData:""
};

const contentReducer = createReducer(initialState, builder => {
  builder
    .addCase(contentPostById.fulfilled, (state, action) => {
        state.newsData = action.payload.newsPost;
      })
    .addDefaultCase((state, action) => {});
});

export default contentReducer;
