/** @format */

import { css, cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { Divider, SwipeableDrawer } from '@mui/material';
import React, { Fragment, lazy, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollAble } from 'src/components/ScrollAble';
import { postDetailLayerAction, clearData } from 'src/store/action/detailsLayerAction';
import CloseButton from 'src/components/Buttons/closeButton';
import { CallIcon, LeftArrow, MessageIcon } from 'src/components/Icons';
import { Box } from '@mui/system';
import { ChipBasic } from 'src/components/Tags';
import userImage from 'src/assets/imgs/bg-login.jpg';
import { format } from 'date-fns';
import { topicDisplayName } from 'src/utils/topicDisplayName';
import useAuth from 'src/hooks/useAuth';

const MultiplePDFViewer = lazy(() => import('src/widgets/cardLayer/multiplePDFViewer'));

const PostDetailLayer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const postDetailOpenState = useSelector(state => state.detailsLayerReducer.postDetailOpenState);
  const postDetailLayerWidth = useSelector(state => state.detailsLayerReducer.postDetailWidth);
  const postDetailLayerHead = useSelector(state => state.detailsLayerReducer.postDetailLayerHead);
  const postDetailLayerData = useSelector(state => state.detailsLayerReducer.postDetailLayerData);
  const scrollAbleRef = useRef();
  const toggleDetails = openEvent => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(postDetailLayerAction(openEvent));
  };
  const customCSS = {
    previewLayer: css`
      &.MuiPaper-root {
        background: #fffdfa;
        padding: 40px 60px;
        border-radius: 40px 0 0 40px;
        width: ${postDetailLayerWidth}%;
        @media screen and (max-width: 768px) {
          padding: 20px 16px;
        }
      }
    `,
    layerCloseButton: css`
      &.close-button {
        justify-content: flex-start;
        @media screen and (max-width: 768px) {
          justify-content: flex-end;
        }
      }
    `,
    heading: css`
      margin-top: 40px;
    `,
    cardsWrap: css`
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    `,
    postDetailContainer: css`
      max-width: 1348px;
      margin: 40px auto;
      & > * {
        max-width: 800px;
        margin: 0 auto;
        & hr {
          text-align: center;
        }
      }
    `,
    postDate: css`
      color: #6b6b6b;
      letter-spacing: 0.01em;
    `,
    postHeading: css`
      margin-top: 8px;
    `,
    postTags: css`
      display: flex;
      column-gap: 4px;
      margin-top: 40px;
      flex-wrap: wrap;
      row-gap: 4px;
    `,
    activeChip: css`
      &.MuiChip-filled {
        background: #f4e7d4;
      }
    `,
    divider: css`
      &.MuiDivider-root {
        background: rgba(0, 0, 0, 0.3);

        margin: 24px auto 0;
      }
    `,
    postAuthorImage: css`
      width: 72px;
      height: 72px;
      border-radius: 6.74286px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    `,
    postAuthorName: css`
      font-size: 18px;
      line-height: 29px;
    `,
    postAuthorTitle: css`
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #969696;
    `,
    authorAction: css`
      height: 50px;
      width: 50px;
      display: grid;
      place-items: center;
      background: #b3b3b3;
      border-radius: 50%;
    `,
    authorActionDetail: css`
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #969696;
      margin-left: 16px;
      cursor: pointer;
    `,
    authorSig: css`
      margin-top: 24px;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #000000;
      display: flex;
      justify-content: flex-end;
      span {
        color: #969696;
      }
    `,
    postContent: css`
      font-size: 18px;
      ul,
      ol {
        padding-left: 30px;
        line-height: 25px;
      }
      .image {
        img {
          max-width: 100%;
        }
      }
    `
  };
  const handleClosePostDetailLayer = () => {
    dispatch(clearData());
    dispatch(postDetailLayerAction(!postDetailOpenState));
  };

  const makeCall = number => {
    window.open(`tel:${number}`);
  };

  const showTags = user?.roles?.includes('Extranet Admin') || user?.roles?.includes('Extranet Editor');

  return (
    <SwipeableDrawer
      anchor={'right'}
      onClose={toggleDetails(false)}
      onOpen={toggleDetails(true)}
      PaperProps={{ className: `${cx(customCSS.previewLayer)}` }}
      ModalProps={{ sx: { zIndex: 6000 } }}
      open={postDetailOpenState}>
      <CloseButton
        label={t('detailPage.back')}
        startclose={+true}
        startIcon={<LeftArrow />}
        onClick={() => handleClosePostDetailLayer()}
        className={`close-button dark ${cx(customCSS.layerCloseButton)}`}
      />
      <div ref={scrollAbleRef} style={{ height: 'inherit' }}>
        <ScrollAble>
          {/* <PDFRenderViewer/> */}
          <Box className={cx(customCSS.postDetailContainer)}>
            <Box className={`p-small ${cx(customCSS.postDate)}`}>
              {postDetailLayerData && format(new Date(postDetailLayerData.releaseDate), 'PPP')}
            </Box>
            <h2 className={cx(customCSS.postHeading)}>{postDetailLayerHead && postDetailLayerHead.title}</h2>
            {showTags && (
              <Box className={cx(customCSS.postTags)}>
                {postDetailLayerData &&
                  postDetailLayerData.topics &&
                  postDetailLayerData.topics.length &&
                  postDetailLayerData.topics.map((item, index) => {
                    return <ChipBasic key={index} label={topicDisplayName(item)} background={'rgba(0, 0, 0, 0.05)'} />;
                  })}
                {postDetailLayerData &&
                  postDetailLayerData.tags &&
                  postDetailLayerData.tags.length &&
                  postDetailLayerData.tags.map((item, index) => {
                    return <ChipBasic key={index} label={topicDisplayName(item)} background={'#F4E7D4'} />;
                  })}
              </Box>
            )}
            <Divider className={cx(customCSS.divider)} />

            <Box
              className={cx(customCSS.postContent)}
              sx={{ mt: '56px' }}
              dangerouslySetInnerHTML={{ __html: postDetailLayerData && postDetailLayerData.text }}></Box>
            {/* {postDetailLayerData && postDetailLayerData.documents && postDetailLayerData.documents.length && postDetailLayerData.documents.map((item, index)=>{
                        return <PDFFileRenderViewer file={item} key={index}/>
                    })} */}
            {postDetailLayerData && postDetailLayerData.documents && postDetailLayerData.documents.length && (
              <MultiplePDFViewer documents={postDetailLayerData.documents} scrollAbleRef={scrollAbleRef} />
            )}

            {/* <PDFViewer /> */}

            {/* Temporarily disable department */}
            {false && postDetailLayerData && postDetailLayerData.departments && postDetailLayerData.departments.length
              ? postDetailLayerData.departments.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: '80px' }}>
                        <Box className={cx(customCSS.postAuthorImage)}>
                          <img src={userImage} alt="User Profile" />
                        </Box>
                        <Box sx={{ ml: '16px' }}>
                          <Box className={cx(customCSS.postAuthorName)}>{item?.contactPerson?.name}</Box>
                          <Box className={cx(customCSS.postAuthorTitle)}>{item?.name}</Box>
                        </Box>
                      </Box>

                      <Box sx={{ mt: '24px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box className={cx(customCSS.authorAction)}>
                            <CallIcon width="18.04" height="18.05" color="#fff" />
                          </Box>
                          <Box
                            className={cx(customCSS.authorActionDetail)}
                            onClick={() => makeCall(item?.contactPerson?.phone)}>
                            {t('detailPage.contact.call')} {item?.contactPerson?.phone}
                            {/* <a href={`tel:${item?.contactPerson?.phone}`} target="_blank" rel="noreferrer">{item?.contactPerson?.phone}</a>   */}
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: '8px' }}>
                          <Box className={cx(customCSS.authorAction)}>
                            <MessageIcon height="18.78" width="18.78" color="#fff" />
                          </Box>
                          <Box className={cx(customCSS.authorActionDetail)}>
                            {t('detailPage.contact.email')}{' '}
                            <a href={`mailto:${item?.contactPerson?.email}`} target="_blank" rel="noreferrer">
                              {item?.contactPerson?.email}
                            </a>
                          </Box>
                        </Box>

                        {/* <Box className={cx(customCSS.authorSig)}>
                                    Name of Editor <Box component="span">&nbsp;|&nbsp;Date</Box>
                                </Box> */}
                      </Box>
                    </Fragment>
                  );
                })
              : null}
            <Divider className={cx(customCSS.divider)} sx={{ mt: '80px !important' }} />
            {/* <Box sx={{ display: 'flex', alignItems: "center", mt: '80px' }}>
                        <Box className={cx(customCSS.postAuthorImage)}>
                            <img src={userImage} alt="User Profile" />
                        </Box>
                        <Box sx={{ ml: '16px' }}>
                            <Box className={cx(customCSS.postAuthorName)}>Martin Klostermann</Box>
                            <Box className={cx(customCSS.postAuthorTitle)}>Service Assistant</Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: '24px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box className={cx(customCSS.authorAction)}><CallIcon width="18.04" height="18.05" color="#fff" /></Box>
                            <Box className={cx(customCSS.authorActionDetail)}>Call 636532336</Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: '8px' }}>
                            <Box className={cx(customCSS.authorAction)}><MessageIcon height='18.78' width='18.78' color='#fff' /></Box>
                            <Box className={cx(customCSS.authorActionDetail)}>Call 636532336</Box>
                        </Box>

                        <Divider className={cx(customCSS.divider)} sx={{ mt: '80px !important' }} />

                        <Box className={cx(customCSS.authorSig)}>
                            Name of Editor <Box component="span">&nbsp;|&nbsp;Date</Box>
                        </Box>
                    </Box> */}
          </Box>
        </ScrollAble>
      </div>
    </SwipeableDrawer>
  );
};

export default PostDetailLayer;
