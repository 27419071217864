/** @format */

import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { REPAIR_IT_CHECK, REPAIR_IT_RECORD, REPAIR_IT_EXPORT, CLEAR } from './type.actions';
import axiosInstance from '../../utils/axios';

/**
 * @param {Number} serialNumber - id of article to delete from cart
 */

export const repairItCheck = createAsyncThunk(REPAIR_IT_CHECK, async payload => {
  try {
    const response = await axiosInstance.get(`/repair-it/check?sn=${payload}`);
    return response.status;
  } catch (error) {
    return error.status;
  }
  // return response.data;
});
/**
 * @param {Object[]: {component, serialNumber, productCode}}
 */
export const repairItRecord = createAsyncThunk(REPAIR_IT_RECORD, async payload => {
  const result = await axiosInstance({
    url: `/repair-it/report`,
    method: 'POST',
    data: payload,
    responseType: 'blob'
  })
    .then(({ data }) => data)
    .catch(error => error);
  return result;
});

export const repairItExport = createAsyncThunk(REPAIR_IT_EXPORT, async () => {
  // const {serialNumber, component, productCode} = data
  const response = await axiosInstance.get(`/repair-it/export`);
  return response.data;
});

export const clear = createAction(CLEAR);
