/** @format */

import React from 'react';

const loader = () => {
  return (
    <div className="loader-progress-outer">
      <div className="loader-progress" />
    </div>
  );
};

export default loader;
