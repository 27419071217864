/** @format */

import React, { Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import { ChevronDown } from '../Icons';
import { CheckLabelTransparentDark } from '../Checkbox';
import { useTranslation } from 'react-i18next';
import { dataLayerPushEvent, SEARCH_FILTER_EVENT } from 'src/utils/dataLayer';
import { topicDisplayName } from 'src/utils/topicDisplayName';

const MultiSelectDropdownDark = props => {
  const { t } = useTranslation();
  const { setdatabyname, listdata, ...data } = props;

  return (
    <Box className="multiselect-dropdown-wrapper dark">
      <Autocomplete
        className="multiselect-dropdown dark"
        multiple
        id="checkboxes-tags-demo"
        options={props.listdata}
        disableCloseOnSelect
        getOptionLabel={option => topicDisplayName(option)}
        renderOption={(data, option, { selected }) => (
          <li {...data}>
            <CheckLabelTransparentDark label={topicDisplayName(option)} checked={selected} />
          </li>
        )}
        ListboxProps={{ className: 'custom-dark-autocomp-popup' }}
        disablePortal={true}
        noOptionsText={t('placeholder.noOptions')}
        isOptionEqualToValue={(option, value) => {
          if (option.id && value.id) {
            return option.id === value.id;
          } else {
            return option === value;
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {props.defaultValue && props.defaultValue.length > 0 ? (
                    <Fragment>
                      <InputAdornment position="start">
                        <Box className="selection-count">{props.defaultValue ? props.defaultValue.length : 0}</Box>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </Fragment>
                  ) : (
                    <p style={{ color: 'grey' }}>{props.placeholder ?? t('placeholder.all')}</p>
                  )}
                </>
              )
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Box {...getTagProps({ index })}>
              {tagValue && tagValue.length > 1 ? topicDisplayName(option) + ',' : topicDisplayName(option)}
            </Box>
          ));
        }}
        limitTags={1}
        popupIcon={<ChevronDown />}
        clearIcon={false}
        onChange={(event, value) => {
          props.setdatabyname(topicDisplayName(props), value);
          dataLayerPushEvent(SEARCH_FILTER_EVENT, {
            action: 'Search parameter',
            parameter_name: topicDisplayName(props),
            parameter_value: value.map(v => topicDisplayName(v)).join(',')
          });
        }}
        {...data}
      />
    </Box>
  );
};

export default MultiSelectDropdownDark;
