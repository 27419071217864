import React, { useState } from 'react';
import { LogoContinental, LogoMain } from './branding-logo';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { CheckboxFilled, CheckboxTransparentDark, CheckboxTransparentLight, CheckLabelFilled, CheckLabelTransparentDark, CheckLabelTransparentLight } from './Checkbox';
import RadioBox from './Radio';
// import SwitchToggle from './switch';
import IconLabelButtons from './Buttons';
import {
    RightArrow, LeftArrow, CrossIcon, UserIcon, AppIcon, StackIcon, SearchIconPro,
    LearningIcon, CheckIcon, HelpIcon, PlatformsIcon, CollectionsIcon, TopArrow,
    BottomArrow, UserFolderIcon, ChevronDown, AlertIcon, ChevronUp,
    ChevronLeft, ChevronRight, OpenExternalIcon, WidgetWidthIcon,
    WidgetWidthActiveIcon, EditorIcon, RevertIcon, ToolIcon, ListIcon, DownloadIcon, TouchIcon, HideIcon, ThreeDotsIcon, DeleteIcon, DuplicateIcon, EditIcon, PlusIcon, ShowIcon, FilterIcon, MobileViewIcon, DesktopViewIcon, CropIcon, TextEditIcon, UploadIcon, AddIcon, CloseSmallIcon, MessageIcon, PinIcon, CallIcon, CloudUpload1, CloudUpload2, ArrowDiagonalTopRight, ChevronDuoRight, ZoomIn, ZoomOut, PreviewIcon, ToolImgLeft, ToolImgRight, ToolMediaUp, ToolMediaDown, ToolVideoLeft, ToolVideoRight, TextBold, TextItalic, TextUnderline, MenuText, MenuBullets, LinkIcon, BulletArrow, BulletNumber, MenuVideo, MenuImage, BulletsIcon, RefreshIcon, GlobeIcon, TextH1, TextH2, TextT1, TextT2, ChevronDuoRightLgSm, EyeIcon, CheckCircle, PlayButton, PauseButton, MuteButton, VolumeButton, MaximizeButton, MinimizeButton, LogoutButton, SubmitButton
} from './Icons';
import { Pagination, Stack } from '@mui/material';
import { AutoCompleteSelectDark } from './Dropdowns/DarkDropdown';
import { AutoCompleteSelectLight } from './Dropdowns/LightDropdown';
import OutlinedInputFieldsDark from './Inputs/OutlinedInputDark';
import FilledInputFieldDark from './Inputs/FilledInputDark';
import OutlineInputWithIconsDark from './Inputs/OutlinedInputWithIconDark';
import OutlinedInputFieldsLight from './Inputs/OutlinedInputLight';
import OutlineInputWithIconsLight from './Inputs/OutlinedInputWithIconLight';
import FilledInputFieldLight from './Inputs/FilledInputLight';
import { Box } from '@mui/system';
import { Container } from '@mui/material';
import TabNav from './Tabs';
import { TagBasic, ChipBasic, ChipClearable } from './Tags';
import SearchField from './Search';
import CloseButton from './Buttons/closeButton';
import MultiSelectDropdownDark from './MultiSelectDropdowns/MultiSelectDropdownDark';
import MultiSelectDropdownLight from './MultiSelectDropdowns/MultiSelectDropdownLight';
import SwitchLabel from './switch/SwitchLabel';

const ShowComponents = () => {

    const [checkbox, setCheckbox] = useState(true);
    // const [arrowColor, setArrowColor] = useState(false);
    const [searchData, setSearchData] = useState();
    const [searchData1, setSearchData1] = useState();

    return (
        <Box sx={{ overflow: 'auto', background: '#f0f0f0' }}>
            <Container sx={{ padding: '20px', height: '100vh' }}>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <LogoMain />
                    <LogoContinental />
                </Box>

                <h3 style={{ textAlign: "center" }} >
                    <Box component="span" sx={{ color: '#FFA500', textShadow: '0px 4px 64px rgb(0 0 0 / 50%)' }}>Components Library</Box>
                </h3>

                <h4 style={{ color: 'green', marginTop: '20px' }}>Switches</h4>
                <Grid container alignItems="center">
                    {/* <SwitchToggle />
                    <SwitchToggle defaultChecked />
                    <SwitchToggle /><span className='switchLabel'>label</span>
                    <SwitchToggle defaultChecked /><span className='switchLabel'>label</span>
                    <SwitchToggle disabled /><span className='switchLabel'>disabled switch</span> */}

                    <SwitchLabel label="label new" />
                    <SwitchLabel label="label new" defaultChecked />
                    <SwitchLabel label="label new" disabled />
                    <SwitchLabel />
                </Grid>

                <h4 style={{ color: 'green', marginTop: '20px' }}>Checkboxes</h4>
                <Stack>
                    <Box sx={{ display: 'flex' }}>
                        <CheckboxFilled />
                        <CheckboxFilled defaultChecked={true} />
                        <CheckLabelFilled label="label" />
                        <CheckLabelFilled label="label" defaultChecked={true} onChange={() => setCheckbox(!checkbox)} />
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                        <CheckboxTransparentLight />
                        <CheckboxTransparentLight defaultChecked={true} />
                        <CheckLabelTransparentLight label="label" />
                        <CheckLabelTransparentLight label="label" defaultChecked={true} />
                    </Box>

                    <Box sx={{ display: 'flex', background: 'black', color: 'white', p: 3 }}>
                        <CheckboxTransparentDark />
                        <CheckboxTransparentDark defaultChecked={true} />
                        <CheckLabelTransparentDark label="label" />
                        <CheckLabelTransparentDark label="label" defaultChecked={true} />
                    </Box>

                </Stack>

                <h4 style={{ color: 'green', marginTop: '20px' }}>Radio Button</h4>
                <Grid container>
                    <RadioBox />
                    <RadioBox checked={true} />
                    <RadioBox label="label" />
                    <RadioBox label="label" checked={true} />
                </Grid>

                <h4 style={{ color: 'green', marginTop: '20px' }}>Buttons</h4>
                <Grid container >
                    <Grid container direction="column" rowGap={3} columnGap={5} alignItems="flex-start" item xs={6} sx={{ background: 'white', padding: '20px' }}>
                        <IconLabelButtons endIcon={<RightArrow />} type="button" label="Label" className="customButton dark" />
                        <IconLabelButtons endIcon={<RightArrow />} type="button" label="Label" className="customButton dark" disabled />

                        <IconLabelButtons startIcon={<LeftArrow />} type="button" label="Label" className="customButton dark" />
                        <IconLabelButtons startIcon={<LeftArrow />} type="button" label="Label" className="customButton dark" disabled />

                        <IconLabelButtons type="button" label="Label" className="customButton dark" />
                        <IconLabelButtons type="button" label="Label" className="customButton dark" disabled />

                        <IconLabelButtons startIcon={<LeftArrow />} type="button" label="Label" className="customButton transLabel dark startTrans" />
                        <IconLabelButtons startIcon={<LeftArrow />} type="button" label="Label" className="customButton transLabel dark startTrans" disabled />

                        <IconLabelButtons endIcon={<RightArrow />} type="button" label="Label" className="customButton transLabel dark endTrans" />
                        <IconLabelButtons endIcon={<RightArrow />} type="button" label="Label" className="customButton transLabel dark endTrans" disabled />

                        <IconLabelButtons endIcon={<LeftArrow />} type="button" className="customButton noLabel dark" />
                        <IconLabelButtons endIcon={<LeftArrow />} type="button" className="customButton noLabel dark" disabled />

                        <IconLabelButtons endIcon={<ChevronLeft />} type="button" className="customButton noLabel dark small-tailless" />
                        <IconLabelButtons endIcon={<ChevronLeft />} type="button" className="customButton noLabel dark small-tailless" disabled />

                        <IconLabelButtons endIcon={<LeftArrow />} type="button" className="customButton noLabel dark borderless" />
                        <IconLabelButtons endIcon={<LeftArrow />} type="button" className="customButton noLabel dark borderless" disabled />

                        <CloseButton label="Close" className="close-button dark" startclose={+true} />
                        <CloseButton label="Close" className="close-button dark" startclose={+true} disabled />
                        <CloseButton label="Close" className="close-button dark" endclose={+true} />
                        <CloseButton label="Close" className="close-button dark" endclose={+true} disabled />
                        <CloseButton label="Close" className="close-button dark" />
                        <CloseButton label="Close" className="close-button dark" disabled />

                        <IconLabelButtons type="button" variant="outlined" label="Outlined only" className="outlined-button" />
                    </Grid>
                    <Grid container direction="column" rowGap={3} columnGap={5} alignItems="flex-start" item xs={6} sx={{ background: 'black', padding: '20px' }}>
                        {/* <IconLabelButtons
                            onMouseEnter={() => setArrowColor(true)}
                            onMouseLeave={() => setArrowColor(false)}
                            endIcon={arrowColor ? <RightArrow /> : <RightArrow color="#000000" />}
                            type="button" label="Label" className="customButton light"
                        /> */}
                        <IconLabelButtons endIcon={<RightArrow color="#000000" />} type="button" label="Label" className="customButton light" />
                        <IconLabelButtons endIcon={<RightArrow color="#000000" />} type="button" label="Label" className="customButton light" disabled />

                        <IconLabelButtons startIcon={<LeftArrow color="#000000" />} type="button" label="Label" className="customButton light" />
                        <IconLabelButtons startIcon={<LeftArrow color="#000000" />} type="button" label="Label" className="customButton light" disabled />

                        <IconLabelButtons type="button" label="Label" className="customButton light" />
                        <IconLabelButtons type="button" label="Label" className="customButton light" disabled />

                        <IconLabelButtons startIcon={<LeftArrow color="#000000" />} type="button" label="Label" className="customButton transLabel light startTrans" />
                        <IconLabelButtons startIcon={<LeftArrow color="#000000" />} type="button" label="Label" className="customButton transLabel light startTrans" disabled />

                        <IconLabelButtons endIcon={<RightArrow color="#000000" />} type="button" label="Label" className="customButton transLabel light endTrans" />
                        <IconLabelButtons endIcon={<RightArrow color="#000000" />} type="button" label="Label" className="customButton transLabel light endTrans" disabled />

                        <IconLabelButtons endIcon={<LeftArrow color="#000000" />} type="button" className="customButton noLabel light" />
                        <IconLabelButtons endIcon={<LeftArrow color="#000000" />} type="button" className="customButton noLabel light" disabled />

                        <IconLabelButtons endIcon={<ChevronLeft color="#000000" />} type="button" className="customButton noLabel light small-tailless" />
                        <IconLabelButtons endIcon={<ChevronLeft color="#000000" />} type="button" className="customButton noLabel light small-tailless" disabled />

                        <IconLabelButtons endIcon={<LeftArrow color="#000000" />} type="button" className="customButton noLabel light borderless" />
                        <IconLabelButtons endIcon={<LeftArrow color="#000000" />} type="button" className="customButton noLabel light borderless" disabled />

                        <CloseButton label="Close" className="close-button light" startclose={+true} />
                        <CloseButton label="Close" className="close-button light" startclose={+true} disabled />
                        <CloseButton label="Close" className="close-button light" endclose={+true} />
                        <CloseButton label="Close" className="close-button light" endclose={+true} disabled />
                        <CloseButton label="Close" className="close-button light" />
                        <CloseButton label="Close" className="close-button light" disabled />

                        <IconLabelButtons type="button" label="Variable BG" className="customButton" bgcolor="rgba(255, 255, 255, 0.15) !important" />
                    </Grid>
                </Grid>

                <h4 style={{ color: 'green', marginTop: '20px' }}>Links</h4>
                <Grid>
                    <Link href='#' className='link-text' underline='always'>This is a Link (default)</Link>
                </Grid>

                <h4 style={{ color: 'green', marginTop: '20px' }}>Pagination for Dark / Light Backgrounds</h4>
                <Grid container>
                    <Grid item md={3} xs={6} style={{ backgroundColor: 'white' }}>
                        <Pagination count={5} hidePrevButton hideNextButton className='customPagination-dark' />
                        <br />
                        <Pagination count={5} hidePrevButton hideNextButton className='customPagination-dark' defaultPage={2} />
                        <br />
                        <Pagination count={5} hidePrevButton hideNextButton className='customPagination-dark' defaultPage={3} />
                        <br />
                        <Pagination count={5} hidePrevButton hideNextButton className='customPagination-dark' defaultPage={4} />
                        <br />
                        <Pagination count={5} hidePrevButton hideNextButton className='customPagination-dark' defaultPage={5} />
                    </Grid>

                    <Grid item md={3} xs={6} style={{ backgroundColor: 'black' }}>
                        <Pagination count={5} hidePrevButton hideNextButton className='customPagination-light' />
                        <br />
                        <Pagination count={5} hidePrevButton hideNextButton className='customPagination-light' defaultPage={2} />
                        <br />
                        <Pagination count={5} hidePrevButton hideNextButton className='customPagination-light' defaultPage={3} />
                        <br />
                        <Pagination count={5} hidePrevButton hideNextButton className='customPagination-light' defaultPage={4} />
                        <br />
                        <Pagination count={5} hidePrevButton hideNextButton className='customPagination-light' defaultPage={5} />
                    </Grid>

                </Grid>

                <h4 style={{ color: 'green', marginTop: '20px' }}>Typography</h4>
                <h5 style={{ color: 'lightcoral', marginTop: '10px' }}>Desktop (For Mobile, decrease the screen size below 992px)</h5>
                <Grid>
                    <h1>Heading 1 Desktop / Mobile - 60px regular</h1>
                    <h2>Heading 2 Desktop / Mobile - 45px medium</h2>
                    <h3>Heading 3 Desktop / Mobile - 36px regular</h3>
                    <h4>Heading 4 Desktop / Mobile - 24px medium</h4>
                    <h5>Heading 5 Desktop / Mobile - 16px bold</h5>
                    <h6>Heading 6 Desktop / Mobile - 16px medium</h6>
                    <p className='caption'>CAPTION DESKTOP / Mobile - 10px BOLD CAPS</p>

                    <br /><br />

                    <p>Body Large - 16px regular</p>
                    <p className='p-small'>Body Small - 13 px regular</p>
                    <p className='p-large-medium'>Body Large - 16px medium</p>
                    <p className='p-small-medium'>Body Small - 14 px medium</p>
                    <p className='p-large-bold'>Body Large - 16px bold</p>
                    <p className='p-small-bold'>Body Small - 14 px bold</p>
                    <p className='caption-large'>Caption Large - 13px medium</p>
                    <p className='caption-small'>Caption Small- 12px medium</p>
                </Grid>

                <h4 style={{ color: 'green', marginTop: '20px' }}>Icons</h4>
                <Grid columnGap={2} rowGap={2} container style={{ marginTop: '10px' }} alignItems="center">
                    <PreviewIcon color="rgb(255,100,1)" />
                    <ToolImgLeft color="rgb(255,100,10)" />
                    <ToolImgRight color="rgb(255,100,100)" />
                    <ToolMediaDown color="rgb(255,100,200)" />
                    <ToolMediaUp color="rgb(255,1,100)" />
                    <ToolVideoLeft color="rgb(255,200,100)" />
                    <ToolVideoRight color="rgb(15,100,1)" />
                    <TextBold color="rgb(255,10,100)" />
                    <TextItalic color="rgb(25,100,1)" />
                    <TextUnderline color="rgb(55,10,1)" />
                    <MenuText color="rgb(255,100,1)" />
                    <MenuBullets color="rgb(255,100,1)" />
                    <LinkIcon color="rgb(255,100,1)" />
                    <BulletArrow color="rgb(255,100,1)" />
                    <BulletNumber color="rgb(255,100,1)" />
                    <MenuVideo color="rgb(255,100,1)" />
                    <MenuImage color="rgb(255,100,1)" />
                    <BulletsIcon color="rgb(255,100,1)" />
                    <RefreshIcon color="rgb(255,100,1)" />
                    <GlobeIcon color="rgb(255,100,1)" />
                    <TextH1 color="rgb(255,100,1)" />
                    <TextH2 color="rgb(255,100,1)" />
                    <TextT1 color="rgb(255,100,1)" />
                    <TextT2 color="rgb(255,100,1)" />

                    <TopArrow color="rgb(1,1,1)" />
                    <BottomArrow color="rgb(1,200,10)" />
                    <RightArrow color="rgb(1,100,1)" />
                    <LeftArrow color="rgb(100,1,1)" />
                    <CrossIcon color="rgb(50,50,50)" />
                    <UserIcon color="rgb(50,50,100)" />
                    <AppIcon color="rgb(50,1,50)" />
                    <StackIcon color="rgb(100,50,50)" />
                    <SearchIconPro color="rgb(1,255,90)" />
                    <LearningIcon color="rgb(50,0,50)" />
                    <CheckIcon color="rgb(50,50,256)" />
                    <HelpIcon color="rgb(50,0,200)" />
                    <PlatformsIcon color="rgb(256,50,256)" />
                    <CollectionsIcon color="rgb(50,256,10)" />
                    <UserFolderIcon color="rgb(255,100,1)" />
                    <AlertIcon />
                    <ChevronDown color="red" />
                    <ChevronUp color="green" />
                    <ChevronLeft color="blue" />
                    <ChevronRight color="rgb(0,0,0)" />
                    <OpenExternalIcon color="rgb(1,255,1)" />
                    <WidgetWidthIcon color="rgb(200,0,100)" />
                    <WidgetWidthActiveIcon color="rgb(100,100,100)" />
                    <TouchIcon color="rgb(5,59,200)" />
                    <DownloadIcon color="rgb(200,50,10)" />
                    <ListIcon color="rgb(150,1,150)" />
                    <ToolIcon color="rgb(150,150,1)" />
                    <RevertIcon color="rgb(100,1,55)" />
                    <EditorIcon color="rgb(1,40,150)" />

                    <HideIcon color="rgb(150,1,150)" />
                    <ThreeDotsIcon color="rgb(150,1,150)" />
                    <DeleteIcon color="rgb(150,1,150)" />
                    <DuplicateIcon color="rgb(150,1,150)" />
                    <EditIcon color="rgb(150,1,150)" />
                    <PlusIcon color="rgb(150,1,150)" />
                    <ShowIcon color="rgb(150,1,150)" />
                    <FilterIcon color="rgb(150,1,150)" />
                    <MobileViewIcon color="rgb(150,1,150)" />
                    <DesktopViewIcon color="rgb(150,1,150)" />
                    <CropIcon color="rgb(150,1,150)" />
                    <TextEditIcon color="rgb(150,1,150)" />
                    <UploadIcon color="rgb(150,1,150)" />
                    <AddIcon color="rgb(150,1,150)" />
                    <CloseSmallIcon color="rgb(150,1,150)" />
                    <MessageIcon color="rgb(150,1,150)" />
                    <PinIcon color="rgb(150,1,150)" />
                    <CallIcon color="rgb(150,1,150)" />

                    <CloudUpload1 color="rgb(1,40,150)" />
                    <CloudUpload2 color="rgb(5,59,100)" />
                    <ArrowDiagonalTopRight color="rgb(255,59,200)" />
                    <ChevronDuoRight color="rgb(155,159,200)" />
                    <ZoomIn color="rgb(5,159,200)" />
                    <ZoomOut color="rgb(50,59,200)" />

                    <EyeIcon color="rgb(50,59,200)" />
                    <CheckCircle color="rgb(50,59,200)" />
                    <PlayButton color="rgb(50,59,200)" />
                    <PauseButton color="rgb(255,59,200)" />
                    <MuteButton color="rgb(255,59,200)" />
                    <VolumeButton color="rgb(255,59,200)" />
                    <MaximizeButton color="rgb(255,59,200)" />
                    <MinimizeButton color="rgb(50,59,200)" />
                    <LogoutButton color="rgb(255,59,200)" />
                    <SubmitButton />
                </Grid>
                <br />
                <ChevronDuoRightLgSm color="red" />

                <h4 style={{ color: 'green', marginTop: '20px', marginBottom: '20px' }}>DropDowns</h4>
                <Grid container>
                    <Grid style={{ background: '#1F1F1F', padding: '20px' }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '25px' }}>
                            <h5 style={{ color: 'white' }}>AutoComplete Without Label</h5>
                            <AutoCompleteSelectDark options={selectOption} />
                            <AutoCompleteSelectDark options={selectOption} error helperText="Please complete this field" />
                            <AutoCompleteSelectDark options={selectOption} disabled />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '25px', mt: 3 }}>
                            <h5 style={{ color: 'white' }}>AutoComplete With Label</h5>
                            <AutoCompleteSelectDark label="Movie" options={selectOption} />
                            <AutoCompleteSelectDark label="Movie" options={selectOption} error helperText="Please complete this field" />
                            <AutoCompleteSelectDark label="Movie" options={selectOption} disabled />
                        </Box>

                    </Grid>

                    <Grid style={{ background: 'white', padding: '20px' }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '25px' }}>
                            <h5 style={{ color: 'black' }}>AutoComplete Without Label</h5>
                            <AutoCompleteSelectLight options={selectOption} />
                            <AutoCompleteSelectLight options={selectOption} error helperText="Please complete this field" />
                            <AutoCompleteSelectLight options={selectOption} disabled />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '25px', mt: 3 }}>
                            <h5 style={{ color: 'black' }}>AutoComplete With Label</h5>
                            <AutoCompleteSelectLight label="Movie" options={selectOption} />
                            <AutoCompleteSelectLight label="Movie" options={selectOption} error helperText="Please complete this field" />
                            <AutoCompleteSelectLight label="Movie" options={selectOption} disabled />
                        </Box>
                    </Grid>
                </Grid>

                <h4 style={{ color: 'green', marginTop: '20px', marginBottom: '20px' }}>MultiSelect</h4>
                <Grid container>
                    <Grid style={{ background: '#1F1F1F', padding: '20px' }} item sm={6}>
                        <MultiSelectDropdownDark listdata={contentType} />
                    </Grid>
                    <Grid style={{ background: '#fff', padding: '20px' }} item sm={6}>
                        <MultiSelectDropdownLight listdata={contentType} />
                    </Grid>
                </Grid>

                <h4 style={{ color: 'green', marginTop: '20px', marginBottom: '20px' }}>Inputs</h4>
                <Grid container>
                    <Grid style={{ background: '#1F1F1F', padding: '20px' }} item sm={6}>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 2, columnGap: 2 }}>
                            <OutlinedInputFieldsDark />
                            <OutlinedInputFieldsDark defaultValue="Label" disabled />
                            <OutlinedInputFieldsDark defaultValue="Label" />
                            <OutlinedInputFieldsDark defaultValue="Label" label="Label" />
                            <OutlinedInputFieldsDark defaultValue="Label" label="Label" error />
                            <OutlinedInputFieldsDark defaultValue="Label" label="Label" helperText="supporting text" />
                            <OutlinedInputFieldsDark defaultValue="Label" label="Label" error helperText="supporting text" />

                            <FilledInputFieldDark defaultValue="Label" />
                            <FilledInputFieldDark defaultValue="Label" disabled />
                            <FilledInputFieldDark error />

                            <Box sx={{ width: '100%', display: 'flex' }}>
                                <OutlineInputWithIconsDark />
                                <OutlineInputWithIconsDark error />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid style={{ background: '#fff', padding: '20px' }} item sm={6}>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 2, columnGap: 2 }}>
                            <OutlinedInputFieldsLight error/>
                            <OutlinedInputFieldsLight defaultValue="Label" disabled />
                            <OutlinedInputFieldsLight defaultValue="Label" />
                            <OutlinedInputFieldsLight defaultValue="Label" label="Label" />
                            <OutlinedInputFieldsLight defaultValue="Label" label="Label" error />
                            <OutlinedInputFieldsLight defaultValue="Label" label="Label" helperText="supporting text" />
                            <OutlinedInputFieldsLight defaultValue="Label" label="Label" error helperText="supporting text" />

                            <FilledInputFieldLight defaultValue="Label" />
                            <FilledInputFieldLight defaultValue="Label" disabled />
                            <FilledInputFieldLight error />

                            <Box sx={{ width: '100%', display: 'flex' }}>
                                <OutlineInputWithIconsLight />
                                <OutlineInputWithIconsLight error />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{ mt: 3 }}>
                    <h4 style={{ color: 'green', marginBottom: '20px' }}>Tabs</h4>
                    <TabNav data={contentTab} />
                    <TabNav data={contentTab} defaultValue={1} />
                    <TabNav data={contentTab} defaultValue={2} />
                </Box>

                {/* <Box sx={{ mt: 3, background: 'black' }}>
                    <h4 style={{ color: 'green', marginBottom: '20px' }}>Navigation Items</h4>
                    <Box sx={{ display: 'flex', columnGap: '20px' }}>
                        <NavItem />
                        <NavItem className="active" />
                    </Box>
                </Box> */}

                <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
                    <h4 style={{ color: 'green', marginTop: '20px' }}>Tags</h4>
                    <TagBasic content="In Bearbeitung" bgcolor="#F9F2E5" />
                    <TagBasic content="In Bearbeitung" bgcolor="rgba(0, 0, 0, 0.08)" />

                    <Box sx={{ background: '#000', p: 2, display: 'flex', flexDirection: 'column', rowGap: 2 }}>
                        <Box sx={{ display: 'flex', columnGap: 2 }}>
                            <ChipBasic label="info type" background="#F4CF9B" color="rgba(0, 0, 0, 0.4)" />
                            <ChipBasic label="filter" background="rgba(255, 255, 255, 0.1)" color="#E38702" iconColor="#E38702" />
                        </Box>

                        <Box sx={{ display: 'flex', columnGap: 2 }}>
                            <ChipClearable label="info type" background="#F4CF9B" color="#000" />
                            <ChipClearable label="filter" background="rgba(255, 255, 255, 0.1)" color="#E38702" iconColor="#E38702" />
                        </Box>
                    </Box>

                    <Box sx={{ background: '#FFFDFA', p: 2, display: 'flex', flexDirection: 'column', rowGap: 2 }}>
                        <Box sx={{ display: 'flex', columnGap: 2 }}>
                            <ChipBasic label="info type" background="#F4E7D4" color="rgba(0, 0, 0, 0.4)" />
                            <ChipBasic label="filter" background="rgba(0, 0, 0, 0.05)" color="#E38702" iconColor="#E38702" />
                        </Box>

                        <Box sx={{ display: 'flex', columnGap: 2 }}>
                            <ChipClearable label="info type" background="#F4E7D4" color="#000" />
                            <ChipClearable label="filter" background="rgba(0, 0, 0, 0.05)" color="#E38702" iconColor="#E38702" />
                        </Box>
                    </Box>

                </Box>

                <Box>
                    <br /><br /><br /><br />
                    <SearchField placeholder="Search" onChangeSearch={(e) => setSearchData(e.target.value)} searchData={searchData} classes="dark" />
                    <Box sx={{ background: 'black', p: 2, my: 2 }}>
                        <SearchField placeholder="Search" onChangeSearch={(e) => setSearchData1(e.target.value)} searchData={searchData1} classes="light" />
                    </Box>
                </Box>

                <Grid>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                </Grid>

            </Container>
        </Box>
    )
};

const contentTab = [
    { heading: 'Report', content: 'Report Conent' },
    { heading: 'Record', content: 'Record Content' },
    { heading: 'Admin', content: 'Admin Content' }
];

const selectOption = [
    { label: 'The Shawshank Redemption' },
    { label: 'The Godfather' },
    { label: 'The Godfather: Part II' },
    { label: 'The Dark Knight' },
    { label: '12 Angry Men' },
    { label: "Schindler's List" },
    { label: 'Pulp Fiction' },
    { label: 'The Lord of the Rings: The Return of the King' },
    { label: 'The Good, the Bad and the Ugly' },
    { label: 'Fight Club' },
    { label: 'The Lord of the Rings: The Fellowship of the Ring' },
    { label: 'Star Wars: Episode V - The Empire Strikes Back' },
    { label: 'Forrest Gump' },
    { label: 'Inception' }
]

const contentType = ['PDF', 'PPT', 'CSV', 'DOC', 'VIDEO'];

export default ShowComponents;