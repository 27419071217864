/** @format */

import { Box } from '@mui/system';
import React from 'react';
import { TagBasic } from 'src/components/Tags';
import { format } from 'date-fns';

import { useDispatch, useSelector } from 'react-redux';
import { getContentDetailById, getNewDetailById } from 'src/store/action/topic.Action';
import useAuth from 'src/hooks/useAuth';
import { postDetailLayerAction, postDetailLayerWidth, postDetailLayerData } from 'src/store/action/detailsLayerAction';
import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';
import { CLICK_CONTENT, dataLayerPushEvent } from 'src/utils/dataLayer';

const ContentList = React.forwardRef(({ item }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useAuth();

  const postDetailOpenState = useSelector(state => state.detailsLayerReducer.postDetailOpenState);
  const selectedBrand = useSelector(state => state.topicReducer.selectedBrand);

  const onClickTopicDetail = (type, topicDetailsId, locale) => {
    let payload;

    if (user && user.locale !== locale) {
      payload = {
        lang: user ? user.locale : 'en',
        id: topicDetailsId,
        contentLang: locale
      };
    } else {
      payload = {
        lang: user ? user.locale : 'en',
        id: topicDetailsId
      };
    }

    if (type === 'content') {
      dispatch(getContentDetailById(payload)).then(data => {
        if (data.payload) {
          const head_payload = {
            title: data.payload.contentPost.title
          };
          dispatch(postDetailLayerAction(!postDetailOpenState));
          dispatch(postDetailLayerWidth(91));
          dispatch(postDetailLayerData(head_payload, data.payload.contentPost));
        }
      });
    }
    if (type === 'news') {
      dispatch(getNewDetailById(payload)).then(data => {
        const head_payload = {
          title: data.payload.newsPost.title
        };
        dispatch(postDetailLayerAction(!postDetailOpenState));
        dispatch(postDetailLayerWidth(91));
        dispatch(postDetailLayerData(head_payload, data.payload.newsPost));
      });
    }
  };

  const handleClickContent = () => {
    onClickTopicDetail(item.contentType, item.id, item.locale);
    dataLayerPushEvent(CLICK_CONTENT, {
      language: item.locale || 'en',
      infoType: item.infoType?.name || '',
      brand: selectedBrand || '',
      title: item.title || ''
    });
  };

  const itemContent = item.teaser || item.title;

  const content = ref ? (
    <Box className="topics-content-wrapper" ref={ref}>
      <h4 onClick={handleClickContent}>{item.title}</h4>
      <Box className="topics-content" dangerouslySetInnerHTML={{ __html: sanitize(itemContent) }}></Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '24px' }}>
        <Box sx={{ display: 'flex', columnGap: '4px', flexWrap: 'wrap', rowGap: '4px', width: '100%' }}>
          {/* {item.tags && item.tags.map((tagItem, index)=>{
                        return (
                        <TagBasic key={index} content={tagItem.name}
                        bgcolor={
                            index === 0 ? "#F4E7D4" :
                            index === 1 ? "rgba(0, 0, 0, 0.1)" :
                            index === 2 ? "rgba(0, 0, 0, 0.05)" :
                            index === 3 ? "#E8EAE8" :
                            index === 4 ? "#F4E7D4" :
                            index === 5 ? "rgba(0, 0, 0, 0.1)" :
                            index === 6 ? "rgba(0, 0, 0, 0.05)" :
                            index === 7 ? "#E8EAE8" :
                            index === 8 ? "#F4E7D4" :
                            index === 9 ? "rgba(0, 0, 0, 0.1)" :
                            index === 10 ? "rgba(0, 0, 0, 0.05)" :
                            index === 11 ? "#E8EAE8" :
                            index === 12 ? "#F4E7D4" : "#F4E7D4"
                        } />)
                    })}     */}
          <TagBasic sx={{ textTransform: 'capitalize' }} content={item.contentType} bgcolor="#F4E7D4" />
          {item && item.infoType && item.infoType.length
            ? item.infoType.map((item, index) => {
                return <TagBasic key={index} content={t(`infoTypes.${item.name}`)} bgcolor="rgba(0, 0, 0, 0.1)" />;
              })
            : null}
          <TagBasic content={item.locale.toUpperCase()} bgcolor="rgba(0, 0, 0, 0.05)" />
          {/* <TagBasic content={'PDF'} bgcolor="#E8EAE8" /> */}
          {}
        </Box>
        <Box className="topic-date">{format(new Date(item.releaseDate), 'PPP')}</Box>
      </Box>
    </Box>
  ) : (
    <Box className="topics-content-wrapper">
      <h4 onClick={handleClickContent}>{item.title}</h4>
      <Box className="topics-content" dangerouslySetInnerHTML={{ __html: sanitize(itemContent) }}></Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '24px' }}>
        <Box sx={{ display: 'flex', columnGap: '4px', flexWrap: 'wrap', rowGap: '4px', width: '100%' }}>
          <TagBasic sx={{ textTransform: 'capitalize' }} content={item.contentType} bgcolor="#F4E7D4" />
          {item && item.infoType && item.infoType.length
            ? item.infoType.map((item, index) => {
                return <TagBasic key={index} content={t(`infoTypes.${item.name}`)} bgcolor="rgba(0, 0, 0, 0.1)" />;
              })
            : null}
          <TagBasic content={item.locale.toUpperCase()} bgcolor="rgba(0, 0, 0, 0.05)" />
          {/* <TagBasic content={'PDF'} bgcolor="#E8EAE8" /> */}
          {}
        </Box>
        <Box className="topic-date">{format(new Date(item.releaseDate), 'PPP')}</Box>
      </Box>
    </Box>
  );
  return content;
});

export default ContentList;
