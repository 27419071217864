/** @format */

import { useEffect, useState } from 'react';

const useDetectBrowser = () => {
  const [isJavaFX, setIsJavaFX] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('JavaFX') > -1 && !isJavaFX) {
      setIsJavaFX(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isJavaFX };
};

export default useDetectBrowser;
