/** @format */
import { createReducer } from '@reduxjs/toolkit';
import {
  filterMarket,
  getCandidatesList,
  getUserRoleByLang,
  nextCandidatesList,
  updateCandidate
} from 'src/store/action/admin.Action';

const initialState = {
  candidatesList: [],
  updatedUser: undefined,
  nextCursor: undefined,
  roles: {},
  filter: []
};

const adminReducer = createReducer(initialState, builder => {
  builder
    .addCase(getCandidatesList.fulfilled, (state, action) => {
      state.candidatesList = action.payload.candidates;
      state.nextCursor = action.payload.nextCursor;
    })
    .addCase(nextCandidatesList.fulfilled, (state, action) => {
      state.candidatesList = state.candidatesList.concat(action.payload.candidates);
      state.nextCursor = action.payload.nextCursor;
    })
    .addCase(updateCandidate.fulfilled, (state, action) => {
      state.updatedUser = action.payload;
    })
    .addCase(getUserRoleByLang.fulfilled, (state, action) => {
      state.roles = { ...state.roles, [action.payload.market]: action.payload.roles };
    })
    .addCase(filterMarket, (state, action) => {
      state.filter = [...action.payload];
    })
    .addDefaultCase((state, action) => {});
});

export default adminReducer;
