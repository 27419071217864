import {createAsyncThunk} from '@reduxjs/toolkit';
import {GET_DASHBOARDS} from './type.actions'
import axiosInstance from '../../utils/axios'

/**
 * @param {String} lang
 */

export const getDashboards = createAsyncThunk(GET_DASHBOARDS, async (data) => {
    const headers = {
        'Accept-Language': data.lang,
    }
    const response = await axiosInstance.get(`/dashboards`, {headers});
    return response.data
});


