import { Link } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { cardLayerAction, cardLayerWidth, cardLayerData } from 'src/store/action/cardLayerAction';
import useAuth from 'src/hooks/useAuth';
import i18n from 'src/i18n';
import { getfooterLinks, getPageData } from "src/store/action/publicPage.Action";

// const footerLinks = [{
//     title: "Imprint"
// }, {
//     title: "Privacy Policy"
// }, {
//     title: "Terms of use"
// }]

const FooterLinks = props => {
    const dispatch = useDispatch();
    const cardLayerOpen = useSelector(state => state.cardLayerReducer.cardLayerOpen);
    const footerLinks = useSelector(state => state.publicPageReducer.footerLinks)
    const { user } = useAuth();

    useEffect(() => {
        if(user && user.locale){
            dispatch(getfooterLinks(user.locale))
        }
        // eslint-disable-next-line
    }, [user])

    const handleOpenCard = (id) => {
        const payload = {
            lang: user ? user.locale : i18n.language,
            id: id
        }
        dispatch(getPageData(payload)).then((response) => {
            const { title, text } = response.payload
            const head_payload = {
                title: title,
            }
            dispatch(cardLayerAction(!cardLayerOpen))
            dispatch(cardLayerWidth(95))
            dispatch(cardLayerData(head_payload, text))
        })

    }
    return (
        <Box className="landing-footer">
            {footerLinks && footerLinks.length ?
                footerLinks.map((item, i) => {
                    return (
                        <Link key={i} onClick={() => handleOpenCard(item.contentId)} className='link-text' underline='always'>
                            {item.linkTitle}
                        </Link>
                    );
                }) : null}
            {/* {footerLinks &&
                footerLinks.map((item, i) => {
                    return (
                        <Link key={i} className='link-text' underline='always'>
                            {item.title}
                        </Link>
                    );
                })} */}
        </Box>
    );
}
export default FooterLinks;