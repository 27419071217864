/** @format */

import { Box, Link } from '@mui/material';
import React from 'react';
import CloseButton from 'src/components/Buttons/closeButton';
import link1 from 'src/assets/imgs/link1.png';
import link2 from 'src/assets/imgs/link2.png';
import link3 from 'src/assets/imgs/link3.png';
import link4 from 'src/assets/imgs/link4.png';
import link5 from 'src/assets/imgs/link5.png';
import link6 from 'src/assets/imgs/link6.jpg';
import { ArrowDiagonalTopRight } from 'src/components/Icons';
import { css, cx } from '@emotion/css';
import { useDispatch } from 'react-redux';
import { helpButtonAction } from 'src/store/action/bottomNavActions';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const Portals = () => {
  const customCSS = {
    closeButton: css`
      position: absolute;
      top: 40px;
      right: 40px;
    `,
    linksWrapper: css`
      text-align: center;
      @media screen and (max-width: 1200px) {
        padding: 116px 20px 50px;
      }
      h1 {
        @media screen and (max-width: 575px) {
          font-size: 32px;
          line-height: 36px;
        }
      }
    `,
    portalInfo: css`
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.03em;
      margin-top: 8px;
      @media screen and (max-width: 575px) {
        font: 500 16px/18px ContinentalStagSansW-M;
      }
    `,
    portalLinks: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 24px;
      row-gap: 24px;
      margin-top: 120px;
      @media screen and (max-width: 575px) {
        margin-top: 40px;
        .link-text {
          min-width: 350px;
        }
      }
      img {
        width: 250px;
        height: 170px;
        border-radius: 10px;
        @media screen and (max-width: 575px) {
          width: 96px;
          height: 64px;
          border-radius: 3px;
        }
      }
    `,
    imagesWrap: css`
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 575px) {
        flex-direction: row;
        align-items: center;
        flex: 100%;
        justify-content: left;
      }
    `,
    linkText: css`
      font-size: 18px;
      line-height: 29px;
      margin-top: 8px;
      @media screen and (max-width: 575px) {
        margin-top: 0;
        margin-left: 24px;
      }
      svg {
        margin-left: 10.7px;
        vertical-align: middle;
      }
    `
  };
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClose = () => {
    dispatch(helpButtonAction(false));
    navigate(-1);
  };
  const portalLinks = [
    {
      src: link1,
      altText: t('portal.link1.altText'),
      linkText: t('portal.link1.linkText'),
      url: t('portal.link1.link')
    },
    {
      src: link2,
      altText: t('portal.link2.altText'),
      linkText: t('portal.link2.linkText'),
      url: t('portal.link2.link')
    },
    {
      src: link3,
      altText: t('portal.link3.altText'),
      linkText: t('portal.link3.linkText'),
      url: t('portal.link3.link')
    },
    {
      src: link4,
      altText: t('portal.link4.altText'),
      linkText: t('portal.link4.linkText'),
      url: t('portal.link4.link')
    },
    {
      src: link5,
      altText: t('portal.link5.altText'),
      linkText: t('portal.link5.linkText'),
      url: t('portal.link5.link')
    },
    {
      src: link6,
      altText: t('portal.link6.altText'),
      linkText: t('portal.link6.linkText'),
      url: t('portal.link6.link')
    }
  ];

  return (
    <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
      <Box className={cx(customCSS.closeButton)}>
        <CloseButton label={t('close')} className="close-button dark" endclose={+true} onClick={handleClose} />
      </Box>

      <Box className={cx(customCSS.linksWrapper)}>
        <h1>{t('portal.title')}</h1>
        <Box className={cx(customCSS.portalInfo)}>{t('portal.subTitle')}</Box>
        <Box className={cx(customCSS.portalLinks)}>
          {portalLinks.map((link, index) => {
            return (
              <Link
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="link-text"
                underline="always">
                <Box className={cx(customCSS.imagesWrap)}>
                  <img src={link.src} alt={link.altText} />
                  <Box component="span" className={cx(customCSS.linkText)}>
                    {link.linkText}
                    <ArrowDiagonalTopRight width="15.5" height="11.5" />
                  </Box>
                </Box>
              </Link>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Portals;
