/** @format */

import { Box } from '@mui/system';
import { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { getContentDetailById, getNewDetailById } from 'src/store/action/topic.Action';
import useAuth from 'src/hooks/useAuth';
// import { postDetailLayerAction, postDetailLayerWidth, postDetailLayerData } from "src/store/action/detailsLayerAction";
import SearchContent from './searchContent';
import { nextPageResult } from 'src/store/action/search.actions';
// import usePosts from "src/hooks/usePosts";

const RelevantTabContent = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  // const postDetailOpenState = useSelector(state => state.detailsLayerReducer.postDetailOpenState);
  const nextCursor = useSelector(state => state.searchReducer.nextCursor);
  const searchResults = useSelector(state => state.searchReducer.searchResults);
  // const onClickTopicDetail = (type, topicDetailsId)=>{
  //     if(type === 'content'){
  //         let payload = {
  //             lang: user ? user.locale:'en',
  //             id:topicDetailsId
  //         }
  //         dispatch(getContentDetailById(payload)).then((data) => {
  //             if (data.payload) {
  //                 const head_payload = {
  //                     title: data.payload.contentPost.title ,
  //                 }
  //                 dispatch(postDetailLayerAction(!postDetailOpenState))
  //                 dispatch(postDetailLayerWidth(91))
  //                 dispatch(postDetailLayerData(head_payload, data.payload.contentPost))
  //               }
  //           })
  //     }
  //     if(type === 'news'){
  //         let payload = {
  //             lang: user.locale,
  //             id:topicDetailsId
  //         }
  //         dispatch(getNewDetailById(payload)).then((data) => {
  //             const head_payload = {
  //                 title: data.payload.newsPost.title ,
  //             }
  //             dispatch(postDetailLayerAction(!postDetailOpenState))
  //             dispatch(postDetailLayerWidth(91))
  //             dispatch(postDetailLayerData(head_payload, data.payload.newsPost))

  //           })
  //     }

  // }

  // Pagination Start
  const intObserver = useRef();
  const lastPostRef = useCallback(
    post => {
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver(posts => {
        if (posts[0].isIntersecting && nextCursor) {
          const payload = {
            keywords: '',
            language: [{ name: user ? user.locale : 'en' }],
            lang: user ? user.locale : 'en',
            cursor: nextCursor
          };
          dispatch(nextPageResult(payload));
        }
      });

      if (post) intObserver.current.observe(post);
      // eslint-disable-next-line
    },
    [nextCursor]
  );
  // Pagination End

  let lastPostRefSet = false;

  return (
    <Box>
      {/* {searchResultData && searchResultData.map((item, i) => { */}
      {searchResults &&
        searchResults.map((item, i) => {
          const resultCount = searchResults.length;

          if (!lastPostRefSet && i > resultCount - 9) {
            lastPostRefSet = true;

            return <SearchContent key={i} searchData={item} ref={lastPostRef} />;
          }

          return <SearchContent key={i} searchData={item} />;
        })}
    </Box>
  );
};
export default RelevantTabContent;
