import {createAsyncThunk} from '@reduxjs/toolkit';
import {GET_MARKETING_POST_BY_ID} from './type.actions'
import axiosInstance from '../../utils/axios'




/**
 * @param {id, Language}
 */

 export const marketingPostById = createAsyncThunk(GET_MARKETING_POST_BY_ID, async payload => {
    const headers ={
        'Accept-Language' : payload.lang,
    }
    const response = await axiosInstance.get(`/marketing-posts/${payload.id}/view`, {headers});
    
    return response.data;
})

