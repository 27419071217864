/** @format */

import { Box } from '@mui/system';
import React from 'react';
import { TagBasic } from 'src/components/Tags';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getContentDetailById, getNewDetailById } from 'src/store/action/topic.Action';
import useAuth from 'src/hooks/useAuth';
import { postDetailLayerAction, postDetailLayerData, postDetailLayerWidth } from 'src/store/action/detailsLayerAction';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

const SearchContent = React.forwardRef(({ searchData }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const postDetailOpenState = useSelector(state => state.detailsLayerReducer.postDetailOpenState);

  const onClickTopicDetail = (type, topicDetailsId, locale) => {
    let payload;

    if (user && user.locale !== locale) {
      payload = {
        lang: user ? user.locale : 'en',
        id: topicDetailsId,
        contentLang: locale
      };
    } else {
      payload = {
        lang: user ? user.locale : 'en',
        id: topicDetailsId
      };
    }

    if (type === 'content') {
      dispatch(getContentDetailById(payload)).then(data => {
        if (data.payload) {
          dispatch(postDetailLayerAction(!postDetailOpenState));
          dispatch(postDetailLayerWidth(91));
          dispatch(postDetailLayerData({ title: data.payload.contentPost.title }, data.payload.contentPost));
        }
      });
    } else if (type === 'news') {
      dispatch(getNewDetailById(payload)).then(data => {
        dispatch(postDetailLayerAction(!postDetailOpenState));
        dispatch(postDetailLayerWidth(91));
        dispatch(postDetailLayerData({ title: data.payload.newsPost.title }, data.payload.newsPost));
      });
    }
  };

  return (
    <div className={`search-content-wrapper`} ref={ref ?? ref}>
      <Box
        className="search-content-head"
        onClick={() => onClickTopicDetail(searchData.contentType, searchData.id, searchData.language)}>
        {searchData && searchData.title ? searchData.title : ''}
      </Box>
      <Box
        className="search-content"
        dangerouslySetInnerHTML={{ __html: sanitize(searchData && searchData.teaser ? searchData.teaser : '') }}></Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '24px' }}>
        <Box
          sx={{
            display: 'flex',
            columnGap: '4px',
            flexWrap: 'wrap',
            flexGrow: 1,
            rowGap: '4px',
            flexBasis: 'min-content'
          }}>
          {searchData && searchData.contentType ? (
            <TagBasic
              sx={{ textTransform: 'capitalize' }}
              content={t('searchContent.chip.' + searchData.contentType)}
              bgcolor="#F4E7D4"
            />
          ) : null}
          {searchData && searchData.language ? (
            <TagBasic content={searchData.language.toUpperCase()} bgcolor="rgba(0, 0, 0, 0.1)" />
          ) : null}
          {searchData && searchData.topics && searchData.topics.length
            ? searchData.topics.map((item, index) => {
                return <TagBasic key={index} content={item} bgcolor={'rgba(0, 0, 0, 0.05)'} />;
              })
            : null}
        </Box>
        <Box className="search-date">{searchData ? format(new Date(searchData.creationDate), 'PPP') : ''}</Box>
      </Box>
    </div>
  );
});

export default SearchContent;
