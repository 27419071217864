import React, { Fragment, useState } from 'react';
import map from 'lodash/map';
import { useDispatch } from 'react-redux';
import { helpButtonAction } from 'src/store/action/bottomNavActions';
import { useNavigate } from 'react-router';
import CloseButton from 'src/components/Buttons/closeButton';
import { Box } from '@mui/system';
import IconLabelButtons from 'src/components/Buttons';
import { RightArrow } from 'src/components/Icons';
import Slider from 'react-slick';
import ImageStep1 from 'src/assets/imgs/onboarding/step1.png';
import ImageStep2 from 'src/assets/imgs/onboarding/step2.png';
import ImageStep3 from 'src/assets/imgs/onboarding/step3.png';
import ImageStep4 from 'src/assets/imgs/onboarding/step4.png';
import { onBoardingComplete } from 'src/store/action/profile.Action';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';

const Onboarding = props => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [slide1, setSlider1] = useState(null);
  const [slide2, setSlider2] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const {user} = useAuth();
  const {t} = useTranslation()

  const handleClose = () => {
    dispatch(helpButtonAction(false));
    const payload= {
      ...user,
    }
    dispatch(onBoardingComplete(payload)).then(res=>{
      if(res){
        navigate('/dashboard');
      }
    })

  };
  const handleGoDashboard = () =>{
    dispatch(helpButtonAction(false));
    navigate('/dashboard');
  }
  const Next = () => {
    slide2?.slickNext();
  };
  const Prev = () => {
    slide2?.slickPrev();
  };
  const settingsimage = {
    asNavFor: slide2,
    fade: true,
    dots: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    draggable: false,
    beforeChange: (current, next) => setActiveStep(next)
  };
  const settingcontent = {
    asNavFor: slide1,
    centerMode: true,
    accessibility: false,
    fade: true,
    slidesToShow: 1,
    dots: false,
    arrows: false,
    swipeToSlide: false,
    focusOnSelect: false,
    infinite: false,
    autoplay: false,
    draggable: false
  };
  const CONSTANT = [
    {
      step: 1,
      image: ImageStep1,
      title: t('onBoarding.slide_1.title'),
      content:t('onBoarding.slide_1.content')
    },
    {
      step: 2,
      image: ImageStep2,
      title: t('onBoarding.slide_2.title'),
      content:t('onBoarding.slide_2.content')
    },
    {
      step: 3,
      image: ImageStep3,
      title: t('onBoarding.slide_3.title'),
      content:t('onBoarding.slide_3.content')
    },
    {
      step: 4,
      image: ImageStep4,
      title: t('onBoarding.slide_4.title'),
      content:t('onBoarding.slide_4.content')
    }
    // {
    //   step: 5,
    //   image: ImageStep5,
    //   title: t('onBoarding.slide_5.title'),
    //   content:t('onBoarding.slide_5.content')
    // }
  ];
  const TotolStep = Object.keys(CONSTANT).length -1; // count item key start with 0 why - 1
  return (
    <Fragment>
      <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
        <Box className="close-onboard">
          <CloseButton label={t("close")} className="close-button dark" endclose={+true} onClick={user && user.onboardingComplete?handleGoDashboard:handleClose} />
        </Box>
        <Box className="onboarding-wrapper">
          <Slider {...settingsimage} className="slide1" ref={slider => setSlider1(slider)}>
            {map(CONSTANT, item => {
              return (
                <Box key={item.step} className="onboard-image">
                  <img src={item.image} alt={'Onboarding'} />
                </Box>
              );
            })}
          </Slider>
          <Slider {...settingcontent} className="slide2" ref={slider => setSlider2(slider)}>
            {map(CONSTANT, item => {
              return (
                <Box key={item.step} className="onboard-content">
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </Box>
              );
            })}
          </Slider>
          <Box className="footer-btn">
            {activeStep > 0 && (
              <IconLabelButtons type="button" label={t('onBoarding.back')} className="customButton trans-light" onClick={() => Prev()} />
            )}
            <IconLabelButtons
              endIcon={<RightArrow />}
              type="button"
              label={activeStep === TotolStep ? t('onBoarding.finish') : t('onBoarding.next')}
              className="customButton dark"
              onClick={() => activeStep === TotolStep?handleClose():Next()}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Onboarding;
