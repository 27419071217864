import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cardLayerAction } from 'src/store/action/cardLayerAction';
import { cx, css } from '@emotion/css';
import { ScrollAble } from 'src/components/ScrollAble';
import CloseButton from 'src/components/Buttons/closeButton';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

const CardLayer = () => {
  const cardLayerOpen = useSelector(state => state.cardLayerReducer.cardLayerOpen);
  const cardLayerWidth = useSelector(state => state.cardLayerReducer.cardLayerWidth);
  const cardLayerHead = useSelector(state => state.cardLayerReducer.cardLayerHead);
  const cardLayerData = useSelector(state => state.cardLayerReducer.cardLayerData);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const styled = {
    cardBox: css`
        transform: translateX(${cardLayerOpen ? `` : `120%`});
        width: ${cardLayerWidth}%;
        `,
    layerCloseButton: css`
        &.close-button {
          position:absolute;
          top:36px;
          left:5rem;
        }
      `,
    publicPages: css`
      h1 {
        font-size:36px;
        line-height:40px;
        margin:2rem 0;
      };
      h2 {
        font-size:21px;
        margin:2rem 0 0;
      };
    `
  };

  const handleCloseCardLayer = () => {
    dispatch(cardLayerAction(!cardLayerOpen));
  };

  return (
    <div className={cx(styled.cardBox, 'cardBox')}>
      <div className={`cardBoxInner`}>
        <ScrollAble>
          <Box sx={{ margin: 'auto', maxWidth: '600px' }}>
            <h2>{cardLayerHead && cardLayerHead.title}</h2>
            <Box className={cx(styled.publicPages)} dangerouslySetInnerHTML={{ __html: cardLayerData && cardLayerData }}></Box>
          </Box>
        </ScrollAble>
      </div>

      <CloseButton
        label={t('close')}
        startclose={+true}
        onClick={() => handleCloseCardLayer()}
        className={`close-button dark ${cx(styled.layerCloseButton)}`}
      />
    </div>
  );
}

export default CardLayer;
