/** @format */
import { createReducer } from '@reduxjs/toolkit';
import { getDashboardConfig, createDashboardConfig, deleteDashboardConfig, updateDashboardConfig } from '../action/dashboardConfig.action';

const initialState = {
    status: undefined,
};

const dashboardConfigReducer = createReducer(initialState, builder => {
    builder
        .addCase(createDashboardConfig.pending, (state, action) => {
            state.loading = true
            state.dashboardConfig = action.meta.requestId;
        })
        .addCase(createDashboardConfig.fulfilled, (state, action) => {
            if (state.dashboardConfig === action.meta.dashboardConfig) {
                state.loading = false;
                state.dashboardConfig = action.payload;
            }
        })
        .addCase(createDashboardConfig.rejected, (state, action) => {
            if (state.dashboardConfig === action.meta.dashboardConfig) {
                state.loading = false;
                state.dashboardConfig = initialState.dashboardConfig
            }
        })
        .addCase(getDashboardConfig.pending, (state, action) => {
            state.loading = true
            state.dashboardConfig = action.meta.requestId;
        })
        .addCase(getDashboardConfig.fulfilled, (state, action) => {
            if (state.dashboardConfig === action.meta.dashboardConfig) {
                state.loading = false;
                state.dashboardConfig = action.payload;
            }
        })
        .addCase(getDashboardConfig.rejected, (state, action) => {
            if (state.dashboardConfig === action.meta.dashboardConfig) {
                state.loading = false;
                state.dashboardConfig = initialState.dashboardConfig
            }
        })
        .addCase(updateDashboardConfig.pending, (state, action) => {
            state.loading = true
            state.dashboardConfig = action.meta.requestId;
        })
        .addCase(updateDashboardConfig.fulfilled, (state, action) => {
            if (state.dashboardConfig === action.meta.dashboardConfig) {
                state.loading = false;
                state.dashboardConfig = action.payload;
            }
        })
        .addCase(updateDashboardConfig.rejected, (state, action) => {
            if (state.dashboardConfig === action.meta.dashboardConfig) {
                state.loading = false;
                state.dashboardConfig = initialState.dashboardConfig
            }
        })
        .addCase(deleteDashboardConfig.pending, (state, action) => {
            state.loading = true
            state.dashboardConfig = action.meta.requestId;
        })
        .addCase(deleteDashboardConfig.fulfilled, (state, action) => {
            if (state.dashboardConfig === action.meta.dashboardConfig) {
                state.loading = false;
                state.dashboardConfig = action.payload;
            }
        })
        .addCase(deleteDashboardConfig.rejected, (state, action) => {
            if (state.dashboardConfig === action.meta.dashboardConfig) {
                state.loading = false;
                state.dashboardConfig = initialState.dashboardConfig
            }
        })

        .addDefaultCase((state, action) => { });
});

export default dashboardConfigReducer;
