/** @format */
import { createReducer } from '@reduxjs/toolkit';
// import { getMarketingPostById} from 'src/store/action/news.Action';
import { marketingPostById } from '../action/marketing.action';

const initialState = {
  marketingData:""
};

const marketingReducer = createReducer(initialState, builder => {
  builder
    .addCase(marketingPostById.fulfilled, (state, action) => {
        state.marketingData = action.payload.marketingPost;
      })
    .addDefaultCase((state, action) => {});
});

export default marketingReducer;
