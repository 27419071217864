/** @format */

import React, { Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import { ChevronDown } from '../Icons';
import { useTranslation } from 'react-i18next';
import { CheckLabelTransparentLight } from '../Checkbox';

const MultiSelectDropdownLight = props => {
  const { setdatabyname, listdata, ...data } = props;
  const { t } = useTranslation();

  return (
    <Box className="multiselect-dropdown-wrapper light">
      <Autocomplete
        className="multiselect-dropdown light"
        multiple
        id="checkboxes-tags-demo"
        options={props.listdata}
        disableCloseOnSelect
        getOptionLabel={option => option.name}
        renderOption={(data, option, { selected }) => (
          <li {...data}>
            <CheckLabelTransparentLight label={option.name} checked={selected} />
          </li>
        )}
        ListboxProps={{ className: 'custom-light-autocomp-popup' }}
        disablePortal={true}
        noOptionsText={t('placeholder.noOptions')}
        isOptionEqualToValue={(option, value) => {
          if (option.id && value.id) {
            return option.id === value.id;
          } else {
            return option === value;
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {props.defaultValue && props.defaultValue.length > 0 ? (
                    <Fragment>
                      <InputAdornment position="start">
                        <Box className="selection-count">{props.defaultValue ? props.defaultValue.length : 0}</Box>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </Fragment>
                  ) : (
                    <p>{props.placeholder ?? t('Select')}</p>
                  )}
                </>
              )
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => {
            const { onDelete, ...props } = { ...getTagProps({ index }) };
            return <Box {...props}>{tagValue && tagValue.length > 1 ? option.name + ',' : option.name}</Box>;
          });
        }}
        limitTags={1}
        popupIcon={<ChevronDown />}
        clearIcon={false}
        onChange={(event, newValue) => {
          props.setdatabyname(props.name, newValue);
        }}
        {...data}
      />
    </Box>
  );
};

export default MultiSelectDropdownLight;
