/** @format */
import { createReducer } from '@reduxjs/toolkit';
import { dashBoardWidgetAction, getMarketingWidget } from 'src/store/action/dashboardWidgetAction';

const initialState = {
  dashboardWidget: {},
  marketingWidget: {},
  dashboardConfig: {}
};

const dashboardWidgetReducer = createReducer(initialState, builder => {
  builder
    .addCase(dashBoardWidgetAction, (state, action) => {
      state.dashboardWidget = action.payload;
    })
    // .addCase(getMarketingWidget, (state, action) => {
    //   state.marketingWidget = action.payload;
    // })
    .addDefaultCase((state, action) => {});
});

export default dashboardWidgetReducer;
