/** @format */

import React from 'react';
import styled from '@emotion/styled';

export const ScrollAble = ({ children, ...rest }) => {
  const ScrollBox = styled.div`
    position: relative;
    width: 100%;
    height: ${props => (props.height ? props.height : `inherit`)};
    margin-top: ${props => (props.marginTop ? props.marginTop : `inherit`)};
    scroll-behavior: smooth;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 5rem;
    &::-webkit-scrollbar {
      display: none;
    }
    @media screen and (max-width: 899px) {
      height: inherit;
    }
  `;
  return <ScrollBox {...rest}>{children}</ScrollBox>;
};
