/** @format */

import { createAction } from '@reduxjs/toolkit';
import { CARD_LAYER_OPEN, CARD_LAYER_WIDTH, CARD_LAYER_DATA } from './type.actions';

export const cardLayerAction = createAction(CARD_LAYER_OPEN);

export const cardLayerWidth = createAction(CARD_LAYER_WIDTH)

export const cardLayerData = createAction(CARD_LAYER_DATA, (head, data) => {
    return { payload: { head, data } }
})