/** @format */

import { Button, Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TopicsHeader } from 'src/components/PageHeader';
import { ScrollAble } from 'src/components/ScrollAble';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTopicFavorite,
  deleteTopicFavorite,
  getTopicContentListByInfoType,
  getTopicList,
  setSelectedInfoTypeTab,
  setTopicContentList
} from 'src/store/action/topic.Action';
import AllTabContent from './allTabContent';
import { Box } from '@mui/system';
import { css, cx } from '@emotion/css';
import { ChevronRight } from '@mui/icons-material';
import useAuth from 'src/hooks/useAuth';
import PostDetailLayer from 'src/widgets/cardLayer/postDetailLayer';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import SubTabContent from './marketingTabContent';
import IconLabelButtons from 'src/components/Buttons';
import { AddIcon, DeleteIcon } from 'src/components/Icons';
import { getDashboards } from 'src/store/action/dashboards.action';
import { searchKeyword } from 'src/store/action/search.actions';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '0 !important'
  },
  subheading: {
    marginTop: '4px',
    font: '500 10px/10px ContinentalStagSansW-M',
    letterSpacing: '0.115em'
  },
  tabWrap: {
    height: 'calc(100% - 36px - 24px)'
  },
  innerWrapper: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 24,
    right: 24,
    top: 'calc(24px + 36px + 24px + 27px)',
    bottom: 24
  },
  innerWrapperDSR: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 24,
    right: 24,
    top: 'calc(24px + 36px + 24px + 27px + 14px)',
    bottom: 24
  },
  stat: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchField: {
    width: '100%',
    '& .search-field.MuiPaper-root.dark': {
      maxWidth: '100%'
    }
  },
  radioBtn: {
    '& > .MuiFormControlLabel-label': {
      font: '500 17px/25px ContinentalStagSansW-M'
    },
    '& > .Mui-checked': {
      color: '#000000'
    }
  },
  TextField: {
    marginTop: '1.5rem'
  },
  recordBtn: {
    position: 'absolute',
    bottom: 24,
    right: 24
  },
  m2: {
    marginTop: '1rem'
  },
  center: {
    textAlign: 'center'
  },
  recordTab: {
    marginTop: '32px',
    '& .MuiFormControlLabel-root:last-child': {
      marginRight: 0
    },
    '& .MuiFormControlLabel-root .MuiRadio-root': {
      padding: '8px'
    }
  },
  cur_dis: 'cursor: not-allowed;'
}));

const tabProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export const flatternTopics = topicList => {
  return topicList.reduce((prev, curr) => {
    const newCurr = { ...curr, rootItem: curr };
    if (newCurr.children) {
      newCurr.children.forEach(lv2 => {
        const newCurrLv2 = { ...lv2, rootItem: newCurr };
        if (newCurrLv2.children) {
          newCurrLv2.children.forEach(lv3 => {
            const newCurrLv3 = { ...lv3, rootItem: newCurr };
            // delete newCurrLv3.children;
            prev.push(newCurrLv3);
          });
        }
        // delete newCurrLv2.children;
        prev.push(newCurrLv2);
      });
    }
    // delete newCurr.children;
    prev.push(newCurr);
    return prev;
  }, []);
};

const Topics = () => {
  const dispatch = useDispatch();
  const topicList = useSelector(state => state.topicReducer.topicList);
  const { t } = useTranslation();
  const { user, uuid } = useAuth();
  const classes = useStyles();
  const selectedInfoTypeTab = useSelector(state => state.topicReducer.selectedInfoTypeTab);
  const [isScrolled, setIsScrolled] = useState(false);
  const listContentRef = useRef();
  const scrollRef = useRef();
  const customCSS = {
    h3Wrap: css`
      margin-bottom: 24px;
      margin-top: 40px;

      h3 {
        font: 500 16px/18px ContinentalStagSansW-M;
      }
    `
  };
  const navOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const topicInfoType = useSelector(state => state.topicReducer.topicInfoType);
  const topicContentList = useSelector(state => state.topicReducer.topicContentList);
  const subTopicContentList = useSelector(state => state.topicReducer.subTopicContentList);
  const selectedTopicItem = useSelector(state => state.topicReducer.selectedTopicItem);
  const topicId = useSelector(state => state.topicReducer.topicId);
  const dashboards = useSelector(state => state.dashboardsReducer.dashboards);
  const searchLatestResults = useSelector(state => state.searchReducer.searchLatestResults);

  useEffect(() => {
    if (topicList.length === 0) {
      dispatch(
        getTopicList({
          lang: user ? user.locale : 'en'
        })
      );
    }
    if (user.activeDashboard && Object.keys(dashboards).length === 0) {
      dispatch(getDashboards({ lang: user.locale }));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Object.keys(dashboards).length > 0 && topicContentList.length === 0) {
      const searchWidget = dashboards.widgets?.findIndex(widget => widget.id === 'search');
      if (searchWidget > -1 && dashboards.widgets[searchWidget].lastSearches.length > 0) {
        const lastSearch = dashboards.widgets[searchWidget].lastSearches[0];
        dispatch(
          searchKeyword({
            keywords: lastSearch['keywords'],
            dateFrom: lastSearch['date-from'],
            dateTo: lastSearch['date-to'],
            order: 'latest'
          })
        );
      }
    }
  }, [topicContentList, dashboards, dispatch]);
  useEffect(() => {
    if (searchLatestResults?.length > 0) {
      const topicContentList = searchLatestResults.map(item => ({
        id: item.id,
        title: item.title,
        teaser: item.teaser,
        releaseDate: item.creationDate,
        tags: item.tags,
        infoType: {
          name: item.infoType[0],
          id: null
        },
        topics: item.topics.map(topic => ({
          id: null,
          name: topic,
          displayName: null
        })),
        locale: item.language,
        contentType: item.contentType
      }));
      dispatch(setTopicContentList(topicContentList));
    }
  }, [dispatch, searchLatestResults]);

  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
    setIsScrolled(false);
  }, [selectedTopicItem]);

  const getContentById = id => {
    dispatch(
      getTopicContentListByInfoType({
        infoType: id,
        id: topicId,
        limit: 10,
        lang: user ? user.locale : 'en'
      })
    );
  };
  const handleChangeTap = (event, newValue) => {
    dispatch(setSelectedInfoTypeTab(newValue));
  };
  const handleScroll = e => {
    const offset = listContentRef.current.getBoundingClientRect();
    setIsScrolled(offset.top < 160);
  };

  const currentTopic = flatternTopics(topicList).find(topic => topicId === topic.id);
  return (
    <Grid
      container
      justifyContent="center"
      className={`scroll-handle-element ${isScrolled ? `is-scrolled` : ``}`}
      ref={scrollRef}
      onScroll={handleScroll}>
      <Grid item xs={12} xl={navOpen ? 12 : 6}>
        <Box className={`top_heading`}>
          {selectedTopicItem ? (
            <>
              <Box
                className="breadcrumb"
                sx={{ fontSize: '24px', lineHeight: '30px', letterSpacing: '0.03em', mb: '9px', display: 'flex' }}>
                {selectedTopicItem.topicName !== selectedTopicItem.breadcrum.rootTopic
                  ? selectedTopicItem.breadcrum.rootTopic
                  : null}
                {selectedTopicItem.breadcrum.subTopic && (
                  <Box sx={{ alignItems: 'center', display: 'flex', ml: '10px' }}>
                    <ChevronRight />
                    {selectedTopicItem.breadcrum.subTopic}
                  </Box>
                )}
              </Box>
              <Grid container alignItems={'center'} gap={2}>
                <Box sx={{ flex: 1 }}>
                  <TopicsHeader heading={selectedTopicItem.topicName} />
                </Box>
                {currentTopic && currentTopic?.favorite ? (
                  <IconLabelButtons
                    txtcolor="#000"
                    bgcolor="#EEE8DF"
                    type="button"
                    label={t('topics.removeFavorite')}
                    className="customButton"
                    onClick={async () => {
                      await dispatch(deleteTopicFavorite({ uuid, id: currentTopic.id }));
                      await dispatch(
                        getTopicList({
                          lang: user ? user.locale : 'en'
                        })
                      );
                    }}
                  />
                ) : (
                  currentTopic &&
                  !currentTopic.children && (
                    <IconLabelButtons
                      endIcon={<AddIcon color="#ffffff" />}
                      type="button"
                      label={t('topics.addToFavorite')}
                      className="customButton dark addicon"
                      onClick={async () => {
                        await dispatch(addTopicFavorite({ uuid, id: currentTopic.id }));
                        await dispatch(
                          getTopicList({
                            lang: user ? user.locale : 'en'
                          })
                        );
                      }}
                    />
                  )
                )}
              </Grid>
            </>
          ) : (
            <TopicsHeader heading={t('topics.topicsHeader')} subHeading={t('topics.topicsSubHeader')} />
          )}
          {topicContentList && topicContentList.length ? (
            topicInfoType && topicInfoType.length ? (
              <>
                <Box className={cx(customCSS.h3Wrap)}>
                  <h3>{t('topics.pleaseSelectInfoType')} </h3>
                </Box>
                <Box className={`tabbed-navigation tabbed-navigation-type-topics ${classes.tabWrap}`}>
                  <Tabs value={selectedInfoTypeTab} onChange={handleChangeTap} aria-label="basic tabs example">
                    <Tab label={t('editorContent.tabNav.all')} {...tabProps(0)} />
                    {topicInfoType &&
                      topicInfoType.length &&
                      topicInfoType.map((item, index) => {
                        return (
                          <Tab
                            key={index}
                            label={`${t(`infoTypes.${item.name}`)} (${item.count})`}
                            {...tabProps(1)}
                            onClick={() => getContentById(item.id)}
                          />
                        );
                      })}
                  </Tabs>
                </Box>
              </>
            ) : null
          ) : null}
        </Box>
        <Box ref={listContentRef}>
          {topicContentList && topicContentList.length ? (
            topicInfoType && topicInfoType.length ? (
              <Box className={`tabbed-navigation tabbed-navigation-type-topics ${classes.tabWrap}`}>
                <TabPanel value={selectedInfoTypeTab} index={0}>
                  <AllTabContent topicContentList={topicContentList} />
                </TabPanel>
                {topicInfoType &&
                  topicInfoType.length &&
                  topicInfoType.map((item, index) => {
                    return (
                      <TabPanel value={selectedInfoTypeTab} key={index} index={index + 1}>
                        <SubTabContent topicContentList={subTopicContentList} />
                      </TabPanel>
                    );
                  })}
              </Box>
            ) : (
              <AllTabContent topicContentList={topicContentList} />
            )
          ) : (
            <h4>{t('noContent')}</h4>
          )}
          <PostDetailLayer />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Topics;
