import React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const SwitchWithLabel = styled((props) => (<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />))
    (({ theme }) => ({
        width: 39,
        height: 24,
        padding: 0,
        "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            color: "#000000",
            transitionDuration: "300ms",
            "&.Mui-checked": {
                transform: "translateX(15px)",
                color: "#000000",
                "& + .MuiSwitch-track": {
                    backgroundColor: theme.palette.mode === "dark" ? "#FFA500" : "#FFA500",
                    opacity: 1,
                    border: 0
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5
                }
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff"
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                color:
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600]
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: theme.palette.mode === "light" ? 0.7 : 0.3
            }
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 20.5,
            height: 20.5,
            boxShadow: 'none'
        },
        "& .MuiSwitch-track": {
            borderRadius: 24 / 2,
            backgroundColor: theme.palette.mode === "light" ? "#E5E7EB" : "#E5E7EB",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500
            })
        }
    }));

const SwitchLabel = props => {
    return (
        <FormGroup>
            <FormControlLabel
                control={<SwitchWithLabel sx={{ mr: '10px' }} {...props} />}
                label={props.label}
                className="switchLabel"
            />
        </FormGroup>
    );
}

export default SwitchLabel;