/** @format */
import { createReducer } from '@reduxjs/toolkit';
import {
  nortificationLayerAction,
  nortificationLayerWidth,
  getNortifications,
  getNortifications_Next
} from '../action/nortification.Action';

const initialState = {
  nortificationLayerOpen: false,
  nortificationLayerWidth: 100,
  // nortificationLayerHead:undefined,
  nortificationList: undefined,
  nextCursor: undefined
};

const nortificationLayerReducer = createReducer(initialState, builder => {
  builder
    .addCase(nortificationLayerAction, (state, action) => {
      state.nortificationLayerOpen = action.payload;
    })
    .addCase(nortificationLayerWidth, (state, action) => {
      state.nortificationLayerWidth = action.payload;
    })
    // .addCase(nortificationLayerData, (state, action)=>{
    //   state.nortificationLayerHead = action.payload.head;
    //   state.nortificationLayerData = action.payload.data
    // })
    .addCase(getNortifications.fulfilled, (state, action) => {
      state.nextCursor = action.payload.nextCursor;
      state.nortificationList = action.payload.notifications;
    })
    .addCase(getNortifications_Next.fulfilled, (state, action) => {
      state.nextCursor = action.payload.nextCursor;
      state.nortificationList = state.nortificationList.concat(action.payload.notifications);
    })
    .addDefaultCase((state, action) => {});
});

export default nortificationLayerReducer;
