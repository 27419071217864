/** @format */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import {
  ADMIN_TOPICS_EDITOR_OPEN,
  ADMIN_TOPICS_EDITOR_TOPIC,
  ADMIN_TOPICS_DELETE_TOPIC,
  SEARCH_TOPIC_CLAUSE,
  DELETE_TOPIC_CLAUSE
} from './type.actions';

export const adminTopicsEditorOpen = createAction(ADMIN_TOPICS_EDITOR_OPEN);
export const adminTopicsEdit = createAction(ADMIN_TOPICS_EDITOR_TOPIC);
export const adminTopicsDeleteTopic = createAction(ADMIN_TOPICS_DELETE_TOPIC);
export const searchTopicClause = createAction(SEARCH_TOPIC_CLAUSE);
export const deleteTopicClause = createAction(DELETE_TOPIC_CLAUSE);

// const getInfoTypeList = createAsyncThunk(GET_INFO_LIST, async payload => {
//   try {
//     const headers = {
//       'Accept-Language': payload.lang
//     };
//     const response = await axiosInstance.get(`/info-types`, { headers });

//     return response.data;
//   } catch (error) {
//     return error;
//   }
// });
