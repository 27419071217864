/** @format */

import React from 'react';
import { ProfileImagePlaceholder } from './Icons';
import useAuth from 'src/hooks/useAuth';

const Spinner = () => {
  return (
    <svg width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#85a2b6"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138">
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  );
};

export const Avatar = props => {
  const { isPictureLoading } = useAuth();
  return (
    <>
      <div className="avatar-box">
        {isPictureLoading ? (
          <Spinner />
        ) : (
          <>{props.srcImg !== '' ? <img src={props.srcImg} alt="Avatar" /> : <ProfileImagePlaceholder />}</>
        )}
      </div>
    </>
  );
};
