/** @format */

import { css, cx } from '@emotion/css';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import MultiSelectDropdownDark from 'src/components/MultiSelectDropdowns/MultiSelectDropdownDark';
import UserSearchChip from 'src/components/SearchChips/userSearch';
import Subheader from 'src/components/Subheader';
import useAuth from 'src/hooks/useAuth';
import { updateUserListPayload } from 'src/store/action/user.Action';
import { marketMap } from '../../utils/market';

const customCSS = {
  filterRow: css`
    margin-top: 22px;
    align-items: center;
  `,
  filterLabel: css`
    font: 500 17px/25px ContinentalStagSansW-M;
    @media screen and (max-width: 899px) {
      margin-bottom: 16px !important;
    }
  `
};

const UserBaseLayer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const roles = useSelector(state => state.adminReducer.roles);
  const [roleOptions, setRoleOptions] = useState([]);
  const userListPayload = useSelector(state => state.userReducer.userListPayload);

  useEffect(() => {
    const locale = user ? user.locale : 'en';
    if (roles[locale] && roleOptions.length === 0) setRoleOptions(roles[locale]);
  }, [roleOptions, user, roles]);

  const resetFilters = () => {
    dispatch(
      updateUserListPayload({
        ...userListPayload,
        userClauses: [],
        userGroup: [],
        market: []
      })
    );
  };

  const handleFilter = (name, data) => {
    if (name === 'userGroup') {
      dispatch(updateUserListPayload({ ...userListPayload, userGroup: data }));
    }
    if (name === 'market') {
      dispatch(updateUserListPayload({ ...userListPayload, market: data }));
    }
  };

  return (
    <>
      <Subheader title={t('editorBase.title')} />
      <Grid height="100%" marginTop="32px">
        <Box className="editorBaseWrapper">
          <Box className="search-chip dark">
            <Grid className={cx(customCSS.filterLabel)} sx={{ marginBottom: 4, opacity: '.6' }}>
              Enter a specific or multiple keywords
            </Grid>
            <UserSearchChip />
          </Box>
          <Grid container className={cx(customCSS.filterRow)} sx={{ mt: '40px' }}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('user.usergroup')}
            </Grid>
            <Grid item xs={12} lg={7}>
              <MultiSelectDropdownDark
                listdata={roleOptions}
                name="userGroup"
                setdatabyname={(name, data) => handleFilter(name, data)}
                defaultValue={userListPayload.userGroup}
                value={userListPayload.userGroup}
              />
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)} sx={{ mt: '40px' }}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('admin.locale')}
            </Grid>
            <Grid item xs={12} lg={7}>
              <MultiSelectDropdownDark
                listdata={Array.from(marketMap, ([id, name]) => ({ id, name: t(`market.${name}`) }))}
                name="market"
                setdatabyname={(name, data) => handleFilter(name, data)}
                defaultValue={userListPayload.market}
                value={userListPayload.market}
              />
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)} sx={{ mt: '32px' }}>
            <IconLabelButtons
              type="button"
              label={t('editorBase.cta')}
              className="customButton dark"
              onClick={resetFilters}
            />
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default UserBaseLayer;
