import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from '../Icons';

export const AutoCompleteSelectDark = props => {
  const { t } = useTranslation();
  const { setdatabyname, options, ...data } = props;

  return (
    <div className="autoComplete-wrapper dark">
      <Autocomplete className="autoComplete dark"
                    disablePortal
                    id="combo-box-demo"
                    options={props.options}
                    sx={{ width: 300 }}
                    autoComplete={true}
                    autoSelect={true}
                    includeInputInList={true}
                    disableClearable={true}
                    open={props.open}
                    disabled={props.disabled}
                    popupIcon={< ChevronDown/>}
                    noOptionsText={t('placeholder.noOptions')}
                    onChange={(event, newValue) => {
                      props.setdatabyname(props.name, newValue);
                    }}
                    renderInput={(params) =>
                      <TextField
                        placeholder={props.placeholder ? props.placeholder : t('Select')} {...params} {...data} />}
                    multiple={props.multiple}
      />
    </div>
  );
};
