/** @format */
import { createReducer } from '@reduxjs/toolkit';
import { getAttributeList, getUserReportingStatus } from '../action/userReporting.Action';

const initialState = {
  attributeList: [],
  status: null
};

const userReportingReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAttributeList.fulfilled, (state, action) => {
      state.attributeList = action.payload;
    })
    .addCase(getUserReportingStatus.fulfilled, (state, action) => {
      state.status = action.payload;
    })
    .addDefaultCase((state, action) => {});
});

export default userReportingReducer;
