/** @format */

import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import { ChevronDown, ChevronUp, DeleteIcon, EditIcon, ThreeDotsIcon } from 'src/components/Icons';
import { adminTopicsEditorOpen, adminTopicsEdit, adminTopicsDeleteTopic } from 'src/store/action/adminTopics.Action';

export default function TopicItem({ topic, level, actionMenu, setActionMenu }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const checkNavOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const [openChildren, setOpenChildren] = useState(false);

  const handleClickEdit = () => {
    dispatch(adminTopicsEditorOpen(true));
    dispatch(adminTopicsEdit(topic.id));
    setActionMenu(null);
  };

  return (
    <Box className={`topic-item ${openChildren ? 'sticky' : ''}`} key={topic.id}>
      <Box className="list-item">
        <Box className="name">
          <label>{t('adminTopics.name')}</label>
          <span>{topic.name}</span>
        </Box>
        {level > 0 && (
          <>
            {level === 1 && <Box></Box>}
            {level < 3 && <Box></Box>}
          </>
        )}
        <Box className="displayName">
          <label>{t('adminTopics.displayName')}</label>
          <span>{topic.displayName}</span>
        </Box>
        {!checkNavOpen && (
          <>
            <Box className="brand">
              <label>{t('adminTopics.brand')}</label>
              <span>{t('adminTopics.brand')}</span>
            </Box>
            <Box className="user-group">
              <label>{t('adminTopics.userGroup')}</label>
              <span>{t('adminTopics.userGroup')}</span>
            </Box>
          </>
        )}
        <Box className="action">
          <Box className="content-action-menu-wrap">
            <button
              onClick={() => (!actionMenu || actionMenu !== topic.id ? setActionMenu(topic.id) : setActionMenu(null))}
              className={actionMenu === topic.id ? 'active' : ''}>
              <ThreeDotsIcon color="#000000" />
            </button>
            {actionMenu === topic.id && (
              <Box className="action-menu">
                <button className="action-menu-item" onClick={handleClickEdit}>
                  <span>
                    <EditIcon />
                  </span>{' '}
                  {t('editorContent.postAction.edit')}
                </button>
                <button
                  className="action-menu-item"
                  onClick={() => {
                    dispatch(adminTopicsDeleteTopic(topic.id));
                    setActionMenu(null);
                  }}>
                  <span>
                    <DeleteIcon />
                  </span>{' '}
                  {t('editorContent.postAction.delete')}
                </button>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="action">
          {topic.children?.length > 0 && (
            <IconLabelButtons
              endIcon={openChildren ? <ChevronUp color="#000" /> : <ChevronDown color="#000" />}
              type="button"
              className="customButton noLabel small-tailless"
              onClick={() => setOpenChildren(!openChildren)}
            />
          )}
        </Box>
      </Box>
      {openChildren && topic.children?.length > 0 && (
        <div className={`topic-item-level topic-item-level${level + 1}`}>
          {topic.children.map(topicL2 => {
            return (
              <TopicItem
                key={topicL2.id}
                topic={topicL2}
                level={level + 1}
                actionMenu={actionMenu}
                setActionMenu={setActionMenu}
              />
            );
          })}
        </div>
      )}
    </Box>
  );
}
