/** @format */

import { Box } from '@mui/system';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { contentPostById } from 'src/store/action/content.action';
import { postDetailLayerAction, postDetailLayerData, postDetailLayerWidth } from 'src/store/action/detailsLayerAction';
import { newsById } from 'src/store/action/news.Action';
import { getNortifications, markRead } from 'src/store/action/nortification.Action';
import { getContentDetailById } from 'src/store/action/topic.Action';
import { sanitize } from 'dompurify';

export const ContentCardWide = props => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { data } = props;
  const postDetailOpenState = useSelector(state => state.detailsLayerReducer.postDetailLayerAction);

  const onCardClick = (contentType, id) => {
    const payload = {
      lang: user ? user.locale : 'en',
      id: id
    };

    if (contentType === 'content') {
      dispatch(getContentDetailById(payload)).then(data => {
        if (data.payload) {
          const { title } = data.payload.contentPost;
          const head_payload = {
            title: title
          };
          dispatch(postDetailLayerAction(!postDetailOpenState));
          dispatch(postDetailLayerWidth(91));
          dispatch(postDetailLayerData(head_payload, data.payload.contentPost));
        }
      });
    } else {
      dispatch(newsById(payload)).then(data => {
        if (data.payload) {
          dispatch(postDetailLayerAction(!postDetailOpenState));
          dispatch(postDetailLayerWidth(91));
          dispatch(postDetailLayerData({ title: data.payload.newsPost.title }, data.payload.newsPost));
        }
      });
    }
  };

  return (
    <Box className={props.contentClass} onClick={() => onCardClick(data.contentType, data.id)}>
      <Box>
        <Box className="content-release p-small">
          {format(new Date(data && data.releaseDate && data.releaseDate), 'PPP')}
        </Box>
        <h3 className="content-title">{data && data.title && data.title}</h3>
        <p
          className="content-text"
          dangerouslySetInnerHTML={{ __html: sanitize(data && data.teaser && data.teaser) }}></p>
      </Box>
      <Box className="content-read p-small-bold">
        {data && data.readTime && data.readTime} {t('news.readTime')}
      </Box>
    </Box>
  );
};

export const ContentCardNormal = props => {
  const dispatch = useDispatch();
  const postDetailOpenState = useSelector(state => state.detailsLayerReducer.postDetailLayerAction);
  const { t } = useTranslation();
  const { data } = props;

  const onCardClick = () => {
    dispatch(postDetailLayerAction(!postDetailOpenState));
    dispatch(postDetailLayerWidth(91));
  };

  return (
    <Box className={props.contentClass} onClick={onCardClick}>
      <Box>
        <Box className="content-release p-small">
          {format(new Date(data && data.releaseDate && data.releaseDate), 'PPP')}
        </Box>
        <h3 className="content-title">{data && data.title && data.title}</h3>
        <p
          className="content-text"
          dangerouslySetInnerHTML={{ __html: sanitize(data && data.teaser && data.teaser) }}></p>
      </Box>
      <Box className="content-read p-small-bold">
        {data && data.readTime && data.readTime} {t('news.readTime')}
      </Box>
    </Box>
  );
};

export const NotificationCard = React.forwardRef(({ data }, ref) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { t } = useTranslation();
  const postDetailOpenState = useSelector(state => state.detailsLayerReducer.postDetailLayerAction);

  const onCardClick = (id, type, nId, langcode) => {
    const payload = {
      lang: user ? user.locale : 'en',
      id: id
    };

    if (payload.lang !== langcode) {
      payload.contentLang = langcode;
    }

    if (type === 'news') {
      dispatch(newsById(payload)).then(data => {
        if (data.payload) {
          dispatch(markRead(nId)).then(() => {
            dispatch(getNortifications({ limit: 6 }));
          });
          dispatch(postDetailLayerAction(!postDetailOpenState));
          dispatch(postDetailLayerWidth(91));
          dispatch(postDetailLayerData({ title: data.payload.newsPost.title }, data.payload.newsPost));
        }
      });
    } else if (type === 'content') {
      dispatch(contentPostById(payload)).then(data => {
        if (data.payload) {
          dispatch(markRead(nId)).then(() => {
            dispatch(getNortifications({ limit: 6 }));
          });
          dispatch(postDetailLayerAction(!postDetailOpenState));
          dispatch(postDetailLayerWidth(91));
          dispatch(postDetailLayerData({ title: data.payload.contentPost.title }, data.payload.contentPost));
        }
      });
    }
  };

  return (
    <Box
      ref={ref ?? ref}
      className={data.read ? 'notification-box' : `notification-box unread`}
      onClick={() => onCardClick(data.internal.id, data.internal.contentType, data.id, data.langcode)}>
      <Box>
        <h3 className={data.read ? 'notification-head' : 'notification-head notification-present'}>
          {data && data.title && data.title}
        </h3>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} className="notification-detail">
          {data.action === 'update' && t('Notifications_Action.new_update')}
          {data.action === 'create' && t('Notifications_Action.new_content')}
        </Box>
      </Box>
      <Box className="notification_date p-small-bold">{format(new Date(data.date * 1000), 'PPP')}</Box>
    </Box>
  );
});
