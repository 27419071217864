/** @format */
// import { ConstructionOutlined } from '@mui/icons-material';
import { createReducer } from '@reduxjs/toolkit';
import { getDashboards } from '../action/dashboards.action';


const initialState = {
    status: undefined,
    loading: false,
    requestStatus: undefined,
    dashboards: [],
}


const dashboardsReducer = createReducer(initialState, builder => {

  builder
    .addCase(getDashboards.pending, (state, action) => {
      state.loading = true;
      state.dashboards = [];
    })
    .addCase(getDashboards.fulfilled, (state, action) => {
      if (state.serialNumber === action.meta.serialNumber) {
        state.loading = false;
        state.dashboards = action.payload;
      }
    })
    .addCase(getDashboards.rejected, (state, action) => {
      if (state.serialNumber === action.meta.serialNumber) {
        state.loading = false;
        state.requestStatus = action.meta.requestStatus
        state.dashboards = initialState.dashboards;
      }
    })
    .addDefaultCase((state, action) => {});
});

export default dashboardsReducer;

