import { Box } from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IconLabelButtons from '../../components/Buttons';



const Errorspage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const goBack = ()=>{
        navigate("/")
    }

      

    return (
        <Fragment>
            <Box className={`error_page`}>
                <div className="error_wrapper">
                    <h2 dangerouslySetInnerHTML={{ __html: t('errorPage.title') }}></h2>
                    <p dangerouslySetInnerHTML={{ __html: t('errorPage.text') }}></p>
                    <IconLabelButtons type="button" label={t('errorPage.cta_2')} className="customButton dark" onClick={goBack}/>
                </div>
            </Box>
        </Fragment>
    )
}

export default Errorspage