/** @format */
import { createReducer } from '@reduxjs/toolkit';
import { repairItCheck, repairItExport, repairItRecord, clear } from '../action/repairIt.actions';

const initialState = {
  status: undefined,
  reportId:undefined,
  exportFile:undefined
};

const repairItReducer = createReducer(initialState, builder => {
  builder
    .addCase(repairItCheck.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload;
    })
    .addCase(repairItCheck.rejected, (state, action) => {
      if (state.status === action.status) {
        state.loading = false;
        state.status = initialState.sn;
      }
    })
    .addCase(repairItRecord.pending, (state, action) => {
      state.loading = true;
      state.reportId = action.payload;
    })
    .addCase(repairItRecord.fulfilled, (state, action) => {
        state.loading = false;
        state.reportId = action.payload;
    })
    .addCase(repairItRecord.rejected, (state, action) => {
        state.loading = false;
        state.reportId = initialState.reportId;
    })
    .addCase(repairItExport.fulfilled, (state, action) => {
        state.loading = false;
        state.exportFile = action.payload;
    })
    .addCase(clear, (state, action) => {
      state.exportFile = initialState.exportFile;
    })
    .addDefaultCase((state, action) => {});
});

export default repairItReducer;
