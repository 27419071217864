/** @format */

import { Box } from '@mui/system';
import { useRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { getContentDetailById, getNewDetailById } from 'src/store/action/topic.Action';
import useAuth from 'src/hooks/useAuth';
// import { postDetailLayerAction, postDetailLayerWidth, postDetailLayerData } from "src/store/action/detailsLayerAction";
import SearchContent from './searchContent';
import { nextPageResult, searchKeyword } from 'src/store/action/search.actions';
// import usePosts from "src/hooks/usePosts";
import Loader from 'src/components/Loader';

const LatestTabContent = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const nextLatestCursor = useSelector(state => state.searchReducer.nextLatestCursor);
  const searchLatestResults = useSelector(state => state.searchReducer.searchLatestResults);

  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (searchLatestResults && searchLatestResults.length === 0) {
      (async () => {
        const payload = {
          keywords: '',
          lang: user ? user.locale : 'en',
          order: 'latest'
        };
        await dispatch(searchKeyword(payload));
        setLoad(true);
      })();
    } else {
      setLoad(true);
    }
    return () => {};
    // eslint-disable-next-line
  }, []);

  // Pagination Start
  const intObserver = useRef();
  const lastPostRef = useCallback(
    post => {
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver(posts => {
        if (posts[0].isIntersecting && nextLatestCursor) {
          const payload = {
            keywords: '',
            language: [{ name: user ? user.locale : 'en' }],
            lang: user ? user.locale : 'en',
            cursor: nextLatestCursor,
            order: 'latest'
          };
          dispatch(nextPageResult(payload));
        }
      });

      if (post) intObserver.current.observe(post);
      // eslint-disable-next-line
    },
    [nextLatestCursor]
  );
  // Pagination End

  let lastPostRefSet = false;

  const searchLatestResultsSorted = searchLatestResults.slice().sort((a, b) => {
    return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
  });

  return (
    <Box>
      {!load ? (
        <Loader />
      ) : (
        <>
          {searchLatestResultsSorted &&
            searchLatestResultsSorted.map((item, i) => {
              const resultCount = searchLatestResultsSorted.length;

              if (!lastPostRefSet && i > resultCount - 9) {
                lastPostRefSet = true;

                return <SearchContent key={i} searchData={item} ref={lastPostRef} />;
              }

              return <SearchContent key={i} searchData={item} />;
            })}
        </>
      )}
    </Box>
  );
};
export default LatestTabContent;
