/** @format */

import { css, cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { Link, SwipeableDrawer } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollAble } from 'src/components/ScrollAble';
import TabNav from 'src/components/Tabs';
// import { detailsLayerAction } from "src/store/action/detailsLayerAction";
import { nortificationLayerAction, markAllRead, getNortifications } from 'src/store/action/nortification.Action';
// import { ContentCardWide } from "./contentCard";
import TabContent from './nortification_tab_content';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { Box } from "@mui/system";
// import IconLabelButtons from 'src/components/Buttons';
// import { RightArrow } from 'src/components/Icons';
import CloseButton from 'src/components/Buttons/closeButton';

// import { ContentCardNormal } from "./contentCard";
// import ContentPreview from "./contentPreview";

const NortificationLayer = props => {
  const { t } = useTranslation();
  // let location = useLocation();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [unread, setUnread] = useState()
  const detailsOpenState = useSelector(state => state.nortificationLayerReducer.nortificationLayerOpen);
  // const detailsLayerHead = useSelector(state => state.nortificationLayerReducer.nortificationLayerHead);
  const nortificationList = useSelector(state => state.nortificationLayerReducer.nortificationList);
  const detailsLayerWidth = useSelector(state => state.nortificationLayerReducer.nortificationLayerWidth);
  const tabData = [
    {
      tabName: t('Notifications_Action.all')
    },
    {
      tabName: t('Notifications_Action.unread')
    }
  ];

  let nortificationArray = [];
  const groupBy = (list, keyGetter) => {
    const map = new Map();
    let collection = [];
    list.forEach(item => {
      const key = keyGetter(item);
      collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    return map;
  };

  if (nortificationList) {
    const grouped = groupBy(nortificationList, item => item.read);
    for (const [key, value] of grouped.entries()) {
      if (value && value.length) {
        if (key === false) {
          let finalObject = Object.assign({ name: key, list: value });
          nortificationArray.push(finalObject);
        }
      }
    }

    // setUnread(nortificationArray && nortificationArray.length && nortificationArray[0].list.length)
  }

  useEffect(() => {
    if (nortificationList) {
      const grouped = groupBy(nortificationList, item => item.read);
      for (const [key, value] of grouped.entries()) {
        if (value && value.length) {
          if (key === false) {
            let finalObject = Object.assign({ name: key, list: value });
            nortificationArray.push(finalObject);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [nortificationList]);

  // if(nortificationArray && nortificationArray.length){
  //     setUnread(nortificationArray[0].list.length)
  // }

  const contentTabData = tabData.map((item, index) => {
    return {
      heading: item.tabName,
      content: (
        <TabContent
          dataGrid={
            item.tabName === 'Unread'
              ? nortificationArray && nortificationArray.length && nortificationArray[0].list
              : nortificationList
          }
          wrapperClass="notification-layer-wrapper"
          contentClass="notification-box"
        />
      )
    };
  });

  const toggleDetails = openEvent => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(nortificationLayerAction(openEvent));
  };
  const customCSS = {
    previewLayer: css`
      &.MuiPaper-root {
        background: #fffdfa;
        padding: 40px 60px;
        border-radius: 40px 0 0 40px;
        width: ${detailsLayerWidth}%;
        @media screen and (max-width: 768px) {
          padding: 20px 16px;
        }
      }
    `,
    layerCloseButton: css`
      &.close-button {
        justify-content: flex-start;
        @media screen and (max-width: 768px) {
          justify-content: flex-end;
        }
      }
    `,
    heading: css`
      margin-top: 40px;
    `,
    subHead: css`
      margin-top: 10px;
      margin-bottom: 40px;
      font-size: 2.2rem;
    `,
    cardsWrap: css`
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    `
  };

  const handleCloseDetailsLayer = () => {
    dispatch(nortificationLayerAction(!detailsOpenState));
  };
  const markReadAll = () => {
    dispatch(markAllRead()).then(() => {
      let payload = {
        limit: 25
      };
      dispatch(getNortifications(payload));
    });
  };
  return (
    <SwipeableDrawer
      anchor={'right'}
      onClose={toggleDetails(false)}
      onOpen={toggleDetails(true)}
      PaperProps={{ className: `${cx(customCSS.previewLayer)}` }}
      ModalProps={{ sx: { zIndex: 5000 } }}
      open={detailsOpenState}>
      {/* <ContentPreview postType={props.postType} postData={props.postData} /> */}
      <CloseButton
        label={t('close')}
        startclose={+true}
        onClick={() => handleCloseDetailsLayer()}
        className={`close-button dark ${cx(customCSS.layerCloseButton)}`}
      />
      <div>
        <h1 className={cx(customCSS.heading)}>{t('Notifications')}</h1>
        <p className={cx(customCSS.subHead)}>
          {nortificationArray && nortificationArray.length && nortificationArray[0].list
            ? nortificationArray && nortificationArray.length && nortificationArray[0].list.length
            : 0}{' '}
          {t('Notifications_Action.unread')}
        </p>
        <Link href="#" className="link-text allRead" underline="always" onClick={markReadAll}>
          {t('Notifications_Action.cta')}
        </Link>
        <TabNav data={contentTabData} />
      </div>
    </SwipeableDrawer>
  );
};

export default NortificationLayer;
