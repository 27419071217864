import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { SearchIconPro } from '../Icons';

const SearchField = props => {
    const handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            //props.onChangeSearch()
            
            props.onClick(evt.target.value)
            
        }
    };
    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: 400 }}
            className={props.classes ? `search-field ${props.classes}` : 'search-field'}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={props.placeholder}
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={props.onChangeSearch}
                onKeyDown={handleKeyDown}
            />
            <IconButton sx={{ p: '10px' }} aria-label="search" className='no-search' onClick={props.onClick}>
                <SearchIconPro />
                {/* {props ? <SearchIconPro /> : <CrossIcon />} */}
            </IconButton>
            {/* {props.searchData ?
                <IconButton sx={{ p: '10px' }} aria-label="search">
                    <CrossIcon color="#000000" />
                </IconButton>
                :
                <IconButton sx={{ p: '10px' }} aria-label="search" className='no-search'>
                    <SearchIconPro />
                </IconButton>
            } */}
        </Paper>
    );
}

export default SearchField;