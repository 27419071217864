/** @format */

import { createAction } from '@reduxjs/toolkit';
import { NAVIGATION_OPEN, IS_MOBILE } from './type.actions';

const navigationOpenAction = createAction(NAVIGATION_OPEN);

const setMobileDevice = createAction(IS_MOBILE);

export { navigationOpenAction, setMobileDevice };
