/** @format */

import React, { Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import { ChevronDown } from '../Icons';
import { CheckLabelTransparentDark } from '../Checkbox';
import { useTranslation } from 'react-i18next';

const MultiSelectDropdownDarkSecond = props => {
  const { name } = props;
  const { t } = useTranslation();
  const { setdatabyname, listdata, ...data } = props;

  return (
    <Box className="multiselect-dropdown-wrapper dark">
      <Autocomplete
        className="multiselect-dropdown dark"
        multiple
        id="checkboxes-tags-demo"
        options={props.listdata}
        disableCloseOnSelect
        getOptionLabel={option =>
          name === 'language'
            ? t(`language.${option.language}`) + ' ' + option.locale
            : name === 'market'
            ? t(`market.${option.market}`)
            : option.market
        }
        renderOption={(data, option, { selected }) => (
          <li {...data}>
            {name === 'language' ? (
              <CheckLabelTransparentDark
                label={t(`language.${option.language}`) + ' - ' + option.locale}
                checked={selected}
              />
            ) : name === 'market' ? (
              <CheckLabelTransparentDark label={t(`market.${option.market}`)} checked={selected} />
            ) : (
              <CheckLabelTransparentDark label={option.market} checked={selected} />
            )}
          </li>
        )}
        ListboxProps={{ className: 'custom-dark-autocomp-popup' }}
        disablePortal={true}
        noOptionsText={t('placeholder.noOptions')}
        isOptionEqualToValue={(option, value) => {
          if (option.id && value.id) {
            return option.id === value.id;
          } else {
            return option === value;
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {props.defaultValue && props.defaultValue.length > 0 ? (
                    <Fragment>
                      <InputAdornment position="start">
                        <Box className="selection-count">{props.defaultValue ? props.defaultValue.length : 0}</Box>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </Fragment>
                  ) : (
                    <p style={{ color: 'grey' }}>{props.placeholder ?? t('placeholder.all')}</p>
                  )}
                </>
              )
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) =>
            name === 'language' ? (
              <Box {...getTagProps({ index })}>
                {tagValue && tagValue.length > 1
                  ? t(`language.${option.language}`) + ' - ' + option.locale + ','
                  : t(`language.${option.language}`) + ' - ' + option.locale}
              </Box>
            ) : name === 'market' ? (
              <Box {...getTagProps({ index })}>
                {tagValue && tagValue.length > 1 ? t(`market.${option.market}`) + ',' : t(`market.${option.market}`)}
              </Box>
            ) : (
              <Box {...getTagProps({ index })}>
                {tagValue && tagValue.length > 1 ? option.market + ',' : option.market}
              </Box>
            )
          );
        }}
        limitTags={1}
        popupIcon={<ChevronDown />}
        clearIcon={false}
        onChange={(event, value) => {
          props.setdatabyname(props.name, value);
        }}
        {...data}
      />
    </Box>
  );
};

export default MultiSelectDropdownDarkSecond;
