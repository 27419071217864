/** @format */

export const USER_INFORMATION_EVENT = 'userInformation';
export const SEARCH_EVENT = 'search';
export const SEARCH_FILTER_EVENT = 'search_filter';
export const REPAIR_REPORT_EVENT = 'repair_report';
export const REPAIR_RECORD_EVENT = 'repair_record';
export const REPAIR_DOWNLOAD_EVENT = 'repair_download';
export const CLICK_BUTTON_EVENT = 'click_button';
export const CLICK_TAB_EVENT = 'click_tab';
export const CLICK_NAVIGATION_EVENT = 'click_navigation';
export const CONTENT_LAYER_EVENT = 'content_layer';
export const CLICK_SLIDER_EVENT = 'click_slider';
export const CLICK_NOTIFICATION_EVENT = 'click_notification';
export const CLICK_CONTENT = 'click_content';
export const CLICK_TOPIC = 'click_topic';
export const CLICK_DOWNLOAD = 'click_download';

window.dataLayer = window.dataLayer || [];
export const dataLayerPushEvent = (event, props) => {
  window.dataLayer.push({
    event,
    ...props
  });
};
