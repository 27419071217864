/** @format */

import { css, cx } from '@emotion/css';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { formatISO9075 } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CheckLabelTransparentDark } from 'src/components/Checkbox';
import OutlinedInputFieldsDark from 'src/components/Inputs/OutlinedInputDark';
import MultiSelectDropdownDark from 'src/components/MultiSelectDropdowns/MultiSelectDropdownDark';
import MultiSelectDropdownDarkSecond from 'src/components/MultiSelectDropdowns/MultiSelectDropdownDark_2';
import { ScrollAble } from 'src/components/ScrollAble';
import FilterSearchChip from 'src/components/SearchChips/filterSearch';
import Subheader from 'src/components/Subheader';
import { filterclearFilters } from 'src/store/action/editor.Action';
import { getContentList, setFilterOptions } from 'src/store/action/topic.Action';
import IconLabelButtons from '../../components/Buttons';

const customCSS = {
  filterRow: css`
    margin-top: 22px;
    align-items: center;
  `,
  filterLabel: css`
    font: 500 17px/25px ContinentalStagSansW-M;
    @media screen and (max-width: 899px) {
      margin-bottom: 16px !important;
    }
  `,
  checkboxWrapper: css`
    display: flex;
    @media screen and (max-width: 899px) {
      margin-top: -9px !important;
    }
  `,
  checkBox: css`
    .MuiFormControlLabel-label {
      font-size: 17px;
      line-height: 25px;
    }
    @media screen and (max-width: 899px) {
      &:first-of-type {
        margin-left: 1px;
      }
    }
  `
};

const EditorBaseLayer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toDate, setToDate] = useState(undefined);
  const [fromDate, setFromDate] = useState(undefined);
  const marketList = useSelector(state => state.editorReducer.marketList);
  const editorsList = useSelector(state => state.editorReducer.editorsList);
  const rolesList = useSelector(state => state.editorReducer.rolesList);
  const [filterOption, setFilterOption] = useState({
    order: 'created',
    sort: 'DESC'
  });
  const [selectedLang, setSelectedLang] = useState();
  const filterClauses = useSelector(state => state.editorReducer.filterClauses);
  const filterOptionsStore = useSelector(state => state.topicReducer.filterOptions);
  const [isChecked, setChecked] = useState([false, false]);
  const [marketListFinal, setMarketList] = useState([]);
  const [filterVersion, setFilterVersion] = useState(0);
  const contentType = [
    { id: 'content', name: t('editorContent.type.content') },
    { id: 'marketing', name: t('editorContent.type.marketing') },
    { id: 'news', name: t('editorContent.type.news') }
  ];

  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, []);

  useEffect(() => {
    if (!filterClauses.length && filterVersion === 0) return;
    setFilterOption(stats => ({
      ...stats,
      keywords: filterClauses
    }));
    setFilterVersion(prev => prev + 1);
    // eslint-disable-next-line
  }, [filterClauses]);

  useEffect(() => {
    if (marketList) {
      let finalMarketList = [];
      for (let i = 0; i < marketList.length; i++) {
        finalMarketList.push({
          market: marketList[i].market,
          name: marketList[i].locale,
          language: marketList[i].language
        });
      }
      setMarketList(finalMarketList);
    }
  }, [marketList]);

  const setDataByName = (name, data) => {
    if (name === 'language') {
      let selectedLang = [];
      for (let i = 0; i < data.length; i++) {
        selectedLang.push({ name: data[i].locale });
      }
      setSelectedLang(data);
      setFilterOption(state => ({
        ...state,
        [name]: selectedLang
      }));
    } else {
      setFilterOption(state => ({
        ...state,
        [name]: data
      }));
    }
  };

  const setStatus = event => {
    const { name, checked, value } = event.target;

    if (value === 'true') {
      isChecked[0] = checked;
      setChecked([checked, isChecked[1]]);
    } else if (value === 'false') {
      isChecked[1] = checked;
      setChecked([isChecked[0], checked]);
    }

    let data;

    if (isChecked[0] && !isChecked[1]) {
      data = 'true';
    } else if (!isChecked[0] && isChecked[1]) {
      data = 'false';
    }

    setFilterOption(state => ({
      ...state,
      [name]: data
    }));
  };

  useEffect(() => {
    if (filterOptionsStore.order !== filterOption.order || filterOptionsStore.sort !== filterOption.sort) {
      setFilterOption(state => ({ ...state, order: filterOptionsStore.order, sort: filterOptionsStore.sort }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptionsStore]);

  useEffect(() => {
    dispatch(setFilterOptions(filterOption));
    dispatch(getContentList(filterOption));
    dispatch(getContentList({ ...filterOption, onlyOwn: true }));
    // eslint-disable-next-line
  }, [filterOption]);

  const resetFilters = () => {
    dispatch(filterclearFilters());
    setFilterOption({});
    setFromDate(undefined);
    setToDate(undefined);
    setSelectedLang(undefined);
    setChecked([false, false]);
  };

  return (
    <Fragment>
      <Subheader title={t('editorBase.title')} subtitle={t('editorBase.subTitle')} />
      <ScrollAble height="100%" marginTop="32px">
        <Box className="editorBaseWrapper">
          <FilterSearchChip />
          <Grid container className={cx(customCSS.filterRow)} sx={{ mt: '40px' }}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('editorBase.contentType')}
            </Grid>
            <Grid item xs={12} lg={7}>
              <MultiSelectDropdownDark
                listdata={contentType}
                name="contentTypes"
                setdatabyname={setDataByName}
                defaultValue={filterOption.contentTypes}
              />
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('editorBase.market')}
            </Grid>
            <Grid item xs={12} lg={7}>
              <MultiSelectDropdownDarkSecond
                listdata={marketListFinal}
                name="market"
                setdatabyname={setDataByName}
                defaultValue={filterOption.market}
              />
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('editorBase.language')}
            </Grid>
            <Grid item xs={12} lg={7}>
              <MultiSelectDropdownDarkSecond
                listdata={marketList}
                name="language"
                setdatabyname={setDataByName}
                defaultValue={selectedLang}
              />
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('editorBase.targetGroups')}
            </Grid>
            <Grid item xs={12} lg={7}>
              <MultiSelectDropdownDark
                listdata={rolesList}
                name="roles"
                setdatabyname={setDataByName}
                defaultValue={filterOption.roles}
              />
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('editorBase.editor')}
            </Grid>
            <Grid item xs={12} lg={7}>
              <MultiSelectDropdownDark
                listdata={editorsList}
                name="editor"
                setdatabyname={setDataByName}
                defaultValue={filterOption.editor}
              />
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('editorBase.contentStatus')}
            </Grid>
            <Grid item xs={12} lg={7} className={cx(customCSS.checkboxWrapper)}>
              <Box className={cx(customCSS.checkBox)}>
                <CheckLabelTransparentDark
                  label={t('editorBase.active')}
                  name="status"
                  value="true"
                  onChange={setStatus}
                  checked={isChecked[0]}
                />
              </Box>
              <Box className={cx(customCSS.checkBox)} sx={{ ml: '45px' }}>
                <CheckLabelTransparentDark
                  label={t('editorBase.inactive')}
                  name="status"
                  value="false"
                  onChange={setStatus}
                  checked={isChecked[1]}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('editorBase.Timeframe')}
            </Grid>
            <Grid item xs={12} lg={7}>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} className="datePickerContainer">
                  <MobileDatePicker
                    toolbarTitle={t('editorBase.labels.title')}
                    value={fromDate === undefined ? t('editorBase.dateFormat') : fromDate}
                    maxDate={toDate ? toDate : undefined}
                    onChange={newValue => {
                      setFromDate(newValue);
                      setDataByName('dateFrom', formatISO9075(new Date(newValue), { representation: 'date' }));
                    }}
                    renderInput={params => (
                      <OutlinedInputFieldsDark
                        {...params}
                        value="dd.mm.yyyy"
                        placeholder={t('editorBase.dateFormat')}
                        name="dateTo"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6} className="datePickerContainer">
                  <MobileDatePicker
                    toolbarTitle={t('editorBase.labels.title')}
                    value={toDate === undefined ? t('editorBase.dateFormat') : toDate}
                    minDate={fromDate ? fromDate : undefined}
                    onChange={newValue => {
                      setToDate(newValue);
                      setDataByName('dateTo', formatISO9075(new Date(newValue), { representation: 'date' }));
                    }}
                    renderInput={params => (
                      <OutlinedInputFieldsDark
                        {...params}
                        value="dd.mm.yyyy"
                        placeholder={t('editorBase.dateFormat')}
                        name="dateFrom"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)} sx={{ mt: '32px' }}>
            <IconLabelButtons
              type="button"
              label={t('editorBase.cta')}
              className="customButton dark"
              onClick={resetFilters}
            />
          </Grid>
        </Box>
      </ScrollAble>
    </Fragment>
  );
};

export default EditorBaseLayer;
