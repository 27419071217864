/** @format */

import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
  SEARCH_KEYWORD,
  SEARCH_PARAMS_ACTION,
  SEARCH_OPTIONS,
  DELETE_SEARCH_OPTIONS,
  CLEAR_FILTERS,
  CLEAR_CURSOR,
  NEXT_PAGE_RESULT
} from './type.actions';
import axiosInstance from '../../utils/axios';

const queryBuilder = (type, tags) => {
  tags = tags.map(tag => {
    return encodeURIComponent(tag);
  });

  return type + '[]=' + tags.join('&' + type + '[]=');
};

const subQueryBuilder = (type, tags) => {
  return '&' + queryBuilder(type, tags);
};

const defaultLimit = 100;

export const searchKeyword = createAsyncThunk(SEARCH_KEYWORD, async data => {
  const { keywords, infoType, contentTypes, topicId, language, dateFrom, dateTo, lang, limit, order } = data;
  let filters = '';

  filters += `&order=${order || `relevant`}`;
  if (keywords && keywords.length) {
    filters += subQueryBuilder('keywords', keywords);
  }
  if (infoType && infoType.length) {
    let infoArray = [];
    for (let i = 0; i < infoType.length; i++) {
      infoArray.push(infoType[i].id);
    }
    filters += subQueryBuilder('info-types', infoArray);
  }
  if (contentTypes && contentTypes.length) {
    let contentArray = [];
    for (let i = 0; i < contentTypes.length; i++) {
      contentArray.push(contentTypes[i].id);
    }
    filters += subQueryBuilder('contentTypes', contentArray);
  }
  if (topicId && topicId.length) {
    let topicArray = [];
    for (let i = 0; i < topicId.length; i++) {
      topicArray.push(topicId[i].value);
    }
    filters += subQueryBuilder('topicIds', topicArray);
  }
  if (language && language.length) {
    let languageArray = [];
    for (let i = 0; i < language.length; i++) {
      languageArray.push(language[i].id);
    }
    filters += subQueryBuilder('languages', languageArray);
  }
  if (dateFrom) {
    filters += `&date-from=${dateFrom}`;
  }
  if (dateTo) {
    filters += `&date-to=${dateTo}`;
  }
  const headers = {
    'accept-language': lang
  };
  const searchLimit = limit ?? defaultLimit;
  const response = await axiosInstance.get(`/search?limit=${searchLimit}${filters}`, { headers });
  return response.data;
});

export const nextPageResult = createAsyncThunk(NEXT_PAGE_RESULT, async data => {
  const { keywords, infoType, contentTypes, topicId, language, dateFrom, dateTo, lang, cursor, limit, order } = data;
  let filters = '';

  filters += `&order=${order || `relevant`}`;
  if (keywords && keywords.length) {
    filters += subQueryBuilder('keywords', keywords);
  }
  if (infoType && infoType.length) {
    let infoArray = [];
    for (let i = 0; i < infoType.length; i++) {
      infoArray.push(infoType[i].id);
    }
    filters += subQueryBuilder('info-types', infoArray);
  }
  if (contentTypes && contentTypes.length) {
    let contentArray = [];
    for (let i = 0; i < contentTypes.length; i++) {
      contentArray.push(contentTypes[i].id);
    }
    filters += subQueryBuilder('contentTypes', contentArray);
  }
  if (topicId && topicId.length) {
    let topicArray = [];
    for (let i = 0; i < topicId.length; i++) {
      topicArray.push(topicId[i].name);
    }
    filters += subQueryBuilder('topics', topicArray);
  }
  if (language && language.length) {
    let languageArray = [];
    for (let i = 0; i < language.length; i++) {
      languageArray.push(language[i].name);
    }
    filters += subQueryBuilder('languages', languageArray);
  }
  if (dateFrom) {
    filters += `&date-from=${dateFrom}`;
  }
  if (dateTo) {
    filters += `&date-to=${dateTo}`;
  }
  if (cursor) {
    filters += `&cursor=${cursor}`;
  }
  const headers = {
    'accept-language': lang
  };
  const searchLimit = limit ?? defaultLimit;
  const response = await axiosInstance.get(`/search?limit=${searchLimit}${filters}`, { headers });
  return response.data;
});

const searchParamsAction = createAction(SEARCH_PARAMS_ACTION);
const searchOption = createAction(SEARCH_OPTIONS);
const deleteSearchOption = createAction(DELETE_SEARCH_OPTIONS);
const clearFilters = createAction(CLEAR_FILTERS);
const clearCursor = createAction(CLEAR_CURSOR);

export { searchParamsAction, searchOption, deleteSearchOption, clearFilters, clearCursor };
