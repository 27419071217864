/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GetWidget } from '../../components/widgets';
import { Responsive, WidthProvider } from 'react-grid-layout';
import Loader from 'src/components/Loader';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function WidgetGrid() {
  const getWidgetData = useSelector(state => state.dashboardWidgetDataReducer.dashboardWidgetData || []);
  const getLayout = useSelector(state => state.dashboardWidgetReducer.dashboardWidget || []);
  const [layouts, setLayouts] = useState({});
  const checkNavOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const compactType = 'vertical';
  const defaultProps = {
    className: `widget_layout`,
    cols: { xl: 4, lg: 4, md: 2, sm: 1, xs: 1, xxs: 1 },
    rowHeight: 24,
    breakpoints: { xl: 1600, lg: 1070, md: 996, sm: 768, xs: 480, xxs: 0 },
    margin: [24, 24],
    isDraggable: false,
    isResizable: false
  };

  useEffect(() => {
    if (Object.keys(getLayout).length !== 0 || Object.keys(getWidgetData).length !== 0) {
      setLayouts(JSON.parse(getLayout));
    }
  }, [getWidgetData, getLayout]);

  const handleShowWidget = widget => {
    return widget.map(item => {
      return (
        <div key={item.i}>
          <GetWidget data={item} />
        </div>
      );
    });
  };

  return (
    <>
      {getWidgetData.length === 0 ? (
        <Loader />
      ) : (
        <ResponsiveReactGridLayout
          {...defaultProps}
          layouts={layouts}
          compactType={compactType}
          key={checkNavOpen ? 1 : 2}>
          {handleShowWidget(getWidgetData)}
        </ResponsiveReactGridLayout>
      )}
    </>
  );
}
