import {createAsyncThunk} from '@reduxjs/toolkit';
import {GET_LINK_WIDGET} from './type.actions'
import axiosInstance from '../../utils/axios'


/**
 * @param {Number} Id - id of article to delete from cart
 */

export const getlinkWidegt = createAsyncThunk(GET_LINK_WIDGET, async id => {
    const response = await axiosInstance.get(`/link-widgets/${id}`);

    return response.data;
})
