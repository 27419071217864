import React from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { AlertIcon, CrossIcon } from '../Icons';

const OutlineInputWithIconsDark = props => {
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl
            // sx={{ m: 1 }}
            variant="outlined"
            className='text-fields outlined-input-icon dark'>
            <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? 'password' : 'test'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {values.showPassword ? <AlertIcon /> : <CrossIcon width="14.84" height="14.83" color="#000000" />}
                            {/* Use the above alert icon to show in case of an error */}
                        </IconButton>
                    </InputAdornment>
                }
                {...props}
            />
        </FormControl>
    );
}

export default OutlineInputWithIconsDark;