/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IconLabelButtons from 'src/components/Buttons';
import { RightArrow } from 'src/components/Icons';
import { SearchHeader } from 'src/components/PageHeader';

export default function Admin() {
  const { t } = useTranslation();
  return (
    <div className="admin-user-nav-open">
      <SearchHeader heading="Admin" />
      <h4 className="page_header_topicsSubHeading">{t('admin.subHeading')}</h4>
      <div className="admin-lists">
        <Link to="/admin/candidates" className="admin-item">
          <div>
            <h2 className="admin-item-title">{t('admin.adminMenu1')}</h2>
            <p className="admin-item-update-time">6 neue Kandidaten • updated 02.08.2022</p>
            <IconLabelButtons
              type="button"
              onClick={() => {}}
              className="admin-item-btn noLabel dark"
              endIcon={<RightArrow />}
            />
          </div>
        </Link>
        <Link to="/admin/user" className="admin-item">
          <h2 className="admin-item-title">{t('admin.adminMenu2')}</h2>
          <p className="admin-item-update-time">3.567 Benutzer • updated 25.07.2022</p>
          <IconLabelButtons
            type="button"
            onClick={() => {}}
            className="admin-item-btn noLabel dark"
            endIcon={<RightArrow />}
          />
        </Link>
        <Link to="/admin/topics" className="admin-item">
          <h2 className="admin-item-title">{t('admin.adminMenu3')}</h2>
          <p className="admin-item-update-time">29 Themen • updated 02.08.2022</p>
          <IconLabelButtons
            type="button"
            onClick={() => {}}
            className="admin-item-btn noLabel dark"
            endIcon={<RightArrow />}
          />
        </Link>
      </div>
    </div>
  );
}
