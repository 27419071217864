/** @format */

import { Box, TextareaAutosize } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import { AutoCompleteSelectLight } from 'src/components/Dropdowns/LightDropdown';
import { CheckIcon, DeleteIcon } from 'src/components/Icons';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
import MultiSelectDropdownLight from 'src/components/MultiSelectDropdowns/MultiSelectDropdownLight';
import SwitchLabel from 'src/components/switch/SwitchLabel';
import { BRANDS } from 'src/constants';
import { adminTopicsDeleteTopic, adminTopicsEdit, adminTopicsEditorOpen } from 'src/store/action/adminTopics.Action';
import { flatternTopics } from '../topics';

export default function AdminTopicsEditor() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [postData, setPostData] = useState({});
  const [nameError, setNameError] = useState(false);
  const rolesList = useSelector(state => state.editorReducer.rolesList);
  const topicList = useSelector(state => state.topicReducer.topicList);
  const idTopicEdit = useSelector(state => state.adminTopicsReducer.idTopicEdit);

  useEffect(() => {
    const topic = idTopicEdit ? flatternTopics(topicList).find(t => t.id === idTopicEdit) : null;
    if (topic) {
      setPostData({
        ...topic,
        parent: topic.rootItem ? { value: topic.rootItem.id, name: topic.rootItem.name } : null,
        status: true,
        role: [],
        brand: []
      });
    } else {
      setPostData({});
    }
  }, [idTopicEdit, topicList]);

  const fieldValidate = e => {
    const { name } = e.target;

    if (name === 'name') {
      if (e.target.value === '') {
        setNameError(true);
      } else {
        setNameError(false);
      }
    }
  };
  const onChange = e => {
    if (e.currentTarget.name) {
      setPostData({
        ...postData,
        [e.currentTarget.name]: e.currentTarget.value
      });
    }
  };
  const setDataByName = (name, value) => {
    setPostData({
      ...postData,
      [name]: value
    });
  };
  const toggleStatus = e => {
    setPostData({
      ...postData,
      status: !postData.status
    });
  };

  const topicListSelection = topicList.reduce((prev, cur) => {
    const newArr = [...prev, { id: cur.id, value: cur.id, name: cur.name, level: 1 }];
    if (cur.children?.length) {
      cur.children.forEach(c => {
        newArr.push({ id: c.id, value: c.id, name: c.name, level: 2 });
      });
    }
    return newArr;
  }, []);

  return (
    <Box className="adminTopics-editor">
      <h1>{t('adminTopics.createTopic')}</h1>
      <Box className="adminTopics-editor-main-content">
        <h4 className="adminTopics-editor-title">{t('adminTopics.generalInformation')}</h4>
        <Box className="adminTopics-editor-form">
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.name')}*</Box>
            <Box className="field-input">
              <OutlinedInputFieldsLight
                placeholder="Name*"
                name="name"
                onChange={onChange}
                value={postData?.name || ''}
                onBlur={fieldValidate}
                error={nameError || false}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.displayName')}</Box>
            <Box className="field-input">
              <OutlinedInputFieldsLight
                placeholder="Display name"
                name="displayName"
                onChange={onChange}
                value={postData?.displayName || ''}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.description')}</Box>
            <Box className="field-input">
              <TextareaAutosize
                minRows={3}
                name="description"
                placeholder="Beschreibung"
                className="text-area"
                onChange={onChange}
                value={postData?.description || ''}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.status')}</Box>
            <Box className="field-input">
              <SwitchLabel
                label={t('adminTopics.published')}
                name="status"
                onChange={toggleStatus}
                checked={postData.status || false}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.brand')}</Box>
            <Box className="field-input">
              <AutoCompleteSelectLight
                options={Object.keys(BRANDS).map(b => ({ id: BRANDS[b], name: b }))}
                setdatabyname={(name, value) => setDataByName(name, value)}
                name="brand"
                value={postData.brand || null}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <Box key={option.value} component="li" {...optionProps}>
                      {option.name}
                    </Box>
                  );
                }}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.role')}</Box>
            <Box className="field-input">
              <MultiSelectDropdownLight
                className="multiselect-dropdown light field-input-select"
                listdata={rolesList}
                setdatabyname={setDataByName}
                name="role"
                defaultValue={postData.role || []}
                value={postData.role || []}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.parentTopic')}</Box>
            <Box className="field-input">
              <AutoCompleteSelectLight
                options={topicListSelection}
                setdatabyname={(name, value) => setDataByName(name, value)}
                name="parent"
                value={postData.parent || null}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <Box
                      key={option.value}
                      component="li"
                      {...optionProps}
                      sx={{ marginLeft: option.level === 2 ? 3 : 0 }}>
                      {option.name}
                    </Box>
                  );
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="adminTopics-editor-buttons">
        <IconLabelButtons
          type="button"
          label={t('adminTopics.cancel')}
          className={`customButton customButton-cancel`}
          bgcolor="rgba(0, 0, 0, 0.05) !important"
          onClick={() => {
            dispatch(adminTopicsEditorOpen(false));
            dispatch(adminTopicsEdit(null));
          }}
        />
        {idTopicEdit && (
          <IconLabelButtons
            endIcon={<DeleteIcon />}
            type="button"
            label={t('adminTopics.delete')}
            className="customButton dark"
            onClick={() => {
              dispatch(adminTopicsDeleteTopic(idTopicEdit));
            }}
          />
        )}
        <IconLabelButtons
          endIcon={<CheckIcon />}
          type="button"
          label={t('adminTopics.saveChanges')}
          className="customButton dark"
          onClick={() => {
            dispatch(adminTopicsEditorOpen(false));
            dispatch(adminTopicsEdit(null));
          }}
        />
      </Box>
    </Box>
  );
}
