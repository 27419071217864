/** @format */

import { Autocomplete, Grid, TextField } from '@mui/material';
import React, { Fragment, useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
// import { Avatar } from 'src/components/avatar';
import { AddIcon, CheckIcon, EditIcon } from 'src/components/Icons';
import IconLabelButtons from 'src/components/Buttons';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
// import { CheckLabelTransparentLight } from 'src/components/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile, clear } from 'src/store/action/profile.Action';
import SwitchLabel from 'src/components/switch/SwitchLabel';
import { Box } from '@mui/system';
import { Avatar } from 'src/components/avatar';
import prettyBytes from 'pretty-bytes';
// import LocationMap from './map';
// import Map from './map';
// import PickLocation from './newMaps';

const salutationValues = [
  { id: 'mr', label: 'Mr.' },
  { id: 'mrs', label: 'Mrs.' },
  { id: 'other', label: 'Other' }
];

const Personal = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const { user, getUserInfo, picture, updateUserProfilePicture, deleteUserProfilePicture, isPictureLoading } =
    useAuth();
  const [userData, setUserData] = useState(null);
  const [successMsg, setSuccessMsg] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState(undefined);
  const dispatch = useDispatch();
  const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
  const inputFileRef = useRef();
  const [errorMessage, setErrorMessage] = useState();
  // const updatedProfileSuccess = useSelector(state => state.profileReducer.updatedProfileSuccess)
  // const goBack = () => {
  //     navigate('/profile')
  // }
  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  const setData = e => {
    const { name, value } = e.target;
    // console.log(':::name', name, value);
    setUserData(stats => ({
      ...stats,
      [name]: value
    }));
  };
  const setCheck = e => {
    const { name, checked } = e.target;
    setUserData(stats => ({
      ...stats,
      [name]: checked
    }));
  };
  const setAddress = e => {
    const { name, value } = e.target;
    let address = { ...userData.address, ...{ [name]: value } };
    setUserData(stats => ({
      ...stats,
      address
    }));
  };
  // const setDataByName=(name, data)=>{
  //     setUserData(stats=>({
  //         ...stats,
  //         [name]:data
  //     }))
  // }

  // const setLatLong = (lat, long) => {
  //     // debugger
  //     let newLocation = userData
  //     newLocation.location.lat = lat
  //     newLocation.location.long = long

  //     setUserData(newLocation)
  // }
  const saveProfileData = () => {
    const { firstName, lastName, company, address } = userData;
    if (firstName && lastName && company && address.street && address.postalCode && address.city && address.country) {
      dispatch(updateProfile(userData)).then(response => {
        if (response) {
          setSuccessMsg(t('profile.profileSuccessMsg'));
          dispatch(clear());
          setTimeout(() => {
            reset();
          }, 3000);
          getUserInfo(userData.roles, userData.uuid);
        }
      });
    } else {
      setErrorMsg(t('profile.profileErrorMsg'));
    }
  };

  const onChangeProFilePicture = async e => {
    setErrorMessage(null);
    try {
      const [file] = e.target.files;
      if (file) {
        const { size } = file;
        if (size / (1024 * 1024) > 2) {
          const sizePretty = prettyBytes(size);
          setErrorMessage(t('profile.personalData.profile_errors.maxSize', { fileSize: sizePretty, limit: '2 MB' }));
          return;
        }

        // const picture = URL.createObjectURL(file);
        // setUserProfilePicture(picture);
        // e.target.value = null;

        if (picture) await deleteUserProfilePicture();

        const data = new FormData();
        data.append('file', file);

        await updateUserProfilePicture(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //     if (updatedProfileSuccess) {
  //         setSuccessMsg(t('profile.profileSuccessMsg'))
  //         dispatch(clear())
  //         setTimeout(() => {
  //             reset()
  //         }, 3000);
  //     }
  //     // eslint-disable-next-line
  // }, [updatedProfileSuccess])

  const reset = () => {
    setSuccessMsg(undefined);
  };
  // const selectOption = [
  //     t('profile.personalData.gender.man'),
  //     t('profile.personalData.gender.woman'),
  //     t('profile.personalData.gender.Others')
  // ];
  // console.log(':::userData', userData.salutation);
  return (
    <Fragment>
      <h2 className="page_title">{t('profile.title')}</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h4 className="sub_head">{t('profile.subTitle')}</h4>
          <Grid container spacing={2} sx={{ paddingBottom: 2 }}>
            <Grid item xs={12}>
              {picture || isPictureLoading ? (
                <Grid container sx={{ alignItems: 'center' }} columnSpacing={10}>
                  <Grid item xs={3} lg={2} className="profile_pic">
                    <Avatar srcImg={picture || ''} name={`${user?.firstName} ${user?.lastName}`} />
                  </Grid>
                  <Grid item xs={8} lg={9} sx={{ textAlign: 'right' }}>
                    <div className="onlyPC">
                      <div
                        className="upload_guide"
                        dangerouslySetInnerHTML={{ __html: t('profile.personalData.pictext') }}
                      />
                      {errorMessage && <div className="upload_error">{errorMessage}</div>}
                    </div>
                    <div className="btnWrapper">
                      <IconLabelButtons
                        disabled={isPictureLoading}
                        type="button"
                        label={
                          isMobile ? t('profile.personalData.deleteBtn_mobile') : t('profile.personalData.deleteBtn')
                        }
                        className="customButton light"
                        onClick={e => {
                          e.preventDefault();
                          if (window.confirm('Are you sure to delete your avatar?')) {
                            deleteUserProfilePicture();
                          }
                        }}
                      />{' '}
                      <label
                        htmlFor="profilePictureUploadBtn"
                        className={`MuiButton-contained customButton dark label-button ${
                          isPictureLoading ? 'disabled' : ''
                        }`}
                        style={{ marginLeft: '10px' }}>
                        <span>
                          {isMobile ? t('profile.personalData.editBtn') : t('profile.personalData.uploadBtn')}
                          <EditIcon color="rgb(255,255,255)" />
                        </span>
                        {/* <IconLabelButtons
                      sx={{ marginLeft: '10px' }}
                      endIcon={<EditIcon color="rgb(255,255,255)" />}
                      type="button"
                      label={isMobile ? t('profile.personalData.editBtn') : t('profile.personalData.uploadBtn')}
                      className="customButton dark"
                    /> */}
                      </label>
                    </div>
                    <input
                      ref={inputFileRef}
                      type="file"
                      disabled={isPictureLoading}
                      accept=".jpg, .jpeg, .png"
                      onChange={e => onChangeProFilePicture(e)}
                      id="profilePictureUploadBtn"
                      style={{ display: 'none' }}
                    />
                  </Grid>
                  <div className="onlySP">
                    <div
                      className="upload_guide"
                      dangerouslySetInnerHTML={{ __html: t('profile.personalData.pictext') }}
                    />
                    {errorMessage && <div className="upload_error">{errorMessage}</div>}
                  </div>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12} xl={11}>
                    <div className="profile_upload">
                      <input
                        ref={inputFileRef}
                        type="file"
                        className="file_uploader"
                        accept=".jpg, .jpeg, .png"
                        onChange={e => onChangeProFilePicture(e)}
                      />
                      <div className="upload">
                        <AddIcon color="rgba(0,0,0,0.4)" />
                        <br />
                        {t('Upload_Photo')}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <h4 className="sub_head">{t('profile.personalData.title')}</h4>
              <Grid container sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                <Grid item xs={11} lg={3} className="lbl_txt">
                  {t('profile.personalData.salutation')}
                </Grid>
                <Grid item xs={11} lg={8}>
                  <div className="autoComplete-wrapper light">
                    <Autocomplete
                      className="autoComplete light"
                      disablePortal
                      id="combo-box-salutation"
                      options={salutationValues}
                      sx={{ width: 300 }}
                      renderInput={params => {
                        return <TextField {...params} placeholder={t('Select')} />;
                      }}
                      style={{ marginTop: 16, marginBottom: 8 }}
                      name="salutation"
                      value={
                        userData && userData.salutation
                          ? salutationValues.find(e => e.id === userData.salutation).label
                          : null
                      }
                      onChange={(event, newValue) => {
                        if (newValue && newValue.id) setData({ target: { name: 'salutation', value: newValue.id } });
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.firstName')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.firstName')}
                    value={userData?.firstName}
                    onChange={setData}
                    name="firstName"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={!userData?.firstName}
                  />
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.Surname')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.Surname')}
                    fullWidth
                    value={userData?.lastName}
                    onChange={setData}
                    name="lastName"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={!userData?.lastName}
                  />
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.position')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.position')}
                    fullWidth
                    disabled
                    value={(user && user.roles && user)?.roles[0] || 'Fleet Manager'}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ readOnly: true }}
                    error={!(user && user.roles && user)?.roles[0]}
                  />
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.company')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.company')}
                    value={userData?.company}
                    onChange={setData}
                    name="company"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={!userData?.company}
                  />
                </Grid>
                {/* <Grid item xs={11}>
                                <span style={{fontSize:"14px", color:"#999"}}>* {t('profile.personalData.Requiredfield')}</span>
                        </Grid>
                        <Grid item xs={11}>
                            <div style={{marginTop:15}}>
                            <IconLabelButtons type="button" label={t('profile.personalData.Abortbtn')} className="customButton light" onClick={goBack} />
                            {' '}
                            <IconLabelButtons type="button" label={t('profile.personalData.saveBtn')} className="customButton dark" />
                            </div>
                        </Grid> */}
              </Grid>

              <h4 className="sub_head">{t('profile.personalData.address')}</h4>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.street')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.street')}
                    value={(userData && userData.address)?.street}
                    onChange={setAddress}
                    name="street"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={!(userData && userData.address)?.street}
                  />
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.Zipcode')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.Zipcode')}
                    fullWidth
                    value={(userData && userData.address)?.postalCode}
                    onChange={setAddress}
                    name="postalCode"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={!(userData && userData.address)?.postalCode}
                  />
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.city')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.city')}
                    fullWidth
                    value={(userData && userData.address)?.city}
                    onChange={setAddress}
                    name="city"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={!(userData && userData.address)?.city}
                  />
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.country')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.country')}
                    fullWidth
                    margin="normal"
                    value={(userData && userData.address)?.country}
                    onChange={setAddress}
                    name="country"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ readOnly: true }}
                    error={!(userData && userData.address)?.country}
                  />
                </Grid>
              </Grid>
              <h4 className="sub_head">{t('profile.personalData.contact_information')}</h4>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.phone')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.phone')}
                    value={(userData && userData.address)?.phoneNumber}
                    onChange={setAddress}
                    name="phoneNumber"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.fax')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.fax')}
                    fullWidth
                    value={(userData && userData.address)?.faxNumber}
                    onChange={setAddress}
                    name="faxNumber"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
              <h4 className="sub_head">{t('profile.personalData.Additional_Information')}</h4>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.VAT_Number')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.VAT_Number')}
                    value={userData?.vatNumber}
                    onChange={setData}
                    name="vatNumber"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.TCO_Number')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.TCO_Number')}
                    fullWidth
                    value={userData?.sealNumber}
                    onChange={setData}
                    name="sealNumber"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.Diesel_repair_service_id')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.Diesel_repair_service_id')}
                    fullWidth
                    value={userData?.drsId}
                    onChange={setData}
                    name="drsId"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.ATE_Break_Center_ID')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.ATE_Break_Center_ID')}
                    fullWidth
                    value={userData?.brakeCenterId}
                    onChange={setData}
                    name="brakeCenterId"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3} className="lbl_txt">
                  {t('profile.personalData.VDO_Fleet_Dealer_ID')}
                </Grid>
                <Grid item xs={12} lg={8}>
                  <OutlinedInputFieldsLight
                    id="standard-full-width"
                    placeholder={t('profile.personalData.VDO_Fleet_Dealer_ID')}
                    fullWidth
                    value={userData?.dealerId}
                    onChange={setData}
                    name="dealerId"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} lg={3} className="lbl_txt">{t('profile.personalData.SAP_number')}</Grid>
                    <Grid item xs={12} lg={8}>
                        <OutlinedInputFieldsLight
                            id="standard-full-width"
                            placeholder={t('profile.personalData.SAP_number')}
                            fullWidth
                            value={userData?.sapNumber}
                            onChange={setData}
                            name="sapNumber"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }} />
                    </Grid> */}
              </Grid>
              <Grid container>
                <Grid item xs={12} xl={11} sx={{ margin: '25px 0' }}>
                  <span style={{ fontSize: '14px', color: '#999' }}>* {t('profile.personalData.Requiredfield')}</span>
                </Grid>
                {/* <Grid item xs={12} xl={11}>
                        <CheckLabelTransparentLight label={t('profile.personalData.newsletter')} name='newsletter' onChange={setCheck} />
                        <Box sx={{display:'flex'}}>
                            <Box>
                                <SwitchLabel name='newsletter' onChange={setCheck} checked={userData && userData.newsletter}/>
                            </Box>
                            <Box className="field-label">{t('profile.personalData.newsletter')}</Box>
                        </Box>
                    </Grid> */}
                {errorMsg && (
                  <Grid className="validationResult" item xs={12} lg={11}>
                    <span className="errorMsg">{errorMsg}</span>
                  </Grid>
                )}
                {successMsg && (
                  <Grid className="validationResult" item xs={12} lg={11}>
                    <span className="successMsg">{successMsg}</span>
                  </Grid>
                )}
                {!errorMsg && !successMsg && (
                  <Grid className="validationResult" item xs={12} lg={11} sx={{ visibility: 'hidden' }}>
                    <span className="placeholderMsg">&nbsp;</span>
                  </Grid>
                )}
                <Grid item xs={12} xl={11}>
                  <div style={{ marginTop: 15, textAlign: 'right' }}>
                    {/* <IconLabelButtons type="button" label={t('profile.personalData.Abortbtn')} className="customButton" onClick={goBack} bgcolor="rgba(0, 0, 0, 0.15) !important" /> */}
                    <IconLabelButtons
                      endIcon={<CheckIcon />}
                      type="button"
                      label={t('profile.personalData.saveBtn')}
                      className="customButton dark"
                      onClick={saveProfileData}
                      sx={{ marginLeft: 2 }}
                    />
                  </div>
                </Grid>
              </Grid>
              {/* <h4 className='sub_head'>{t('profile.personalData.mapHeading')}</h4>
                <p>{t('profile.personalData.Maptext')}</p>
                <Grid container sx={{ marginTop: 2 }}>
                    <Grid item xs={12} xl={11}>
                        <Map lat={(userData && userData.location)?.lat} long={(userData && userData.location)?.long} setLatLong={setLatLong}  />
                    </Grid>
                    <Grid item xs={12} xl={11}>
                        <div style={{ marginTop: 32, textAlign: "right" }}>
                            <IconLabelButtons  type="button" label={t('profile.personalData.Abortbtn')} className="customButton black" onClick={goBack} bgcolor="rgba(0, 0, 0, 0.15) !important" />

                            <IconLabelButtons endIcon={<CheckIcon />} type="button" label={t('profile.personalData.saveLocation')} className="customButton dark" onClick={saveProfileData} sx={{ marginLeft: 2 }} />
                        </div>
                    </Grid>
                </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Personal;
