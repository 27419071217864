import React, { Fragment, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContentCardWide } from './contentCard';

const TabContent = props => {
    const [openIndex, setOpenIndex] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const { t } = useTranslation();
    const onMenuOpen = (index) => {
        setOpenIndex(index)
        setMenuOpen(true)
    }
    const onMenuClose = () => {
        setOpenIndex(null)
        setMenuOpen(false)
    }

    return (
        <Fragment>
            {
                props.dataGrid && props.dataGrid ?
                    <Box className={props.wrapperClass && props.wrapperClass}>
                        {props.dataGrid && props.dataGrid.map((item, i) => {
                            return <ContentCardWide key={i} data={item} index={i}
                                menuOpen={menuOpen} openIndex={openIndex} onMenuOpen={onMenuOpen}
                                contentClass={props.contentClass} />
                        })}
                        {menuOpen && <Box sx={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', zIndex: '1' }} onClick={onMenuClose}></Box>}
                    </Box > :
                    <Box sx={{ width: '100%', mt: "20px" }}>{t('noContent')}</Box>
            }
        </Fragment>
    );
}

export default TabContent;