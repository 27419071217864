/** @format */

import React, { Fragment, memo, useEffect, useState } from 'react';
import Container from './treeWrapper';
import { useTranslation } from 'react-i18next';

const MultiSelectTree = props => {
  const { data } = props;
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState();
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [editDataList, setEditData] = useState();

  const onChange = (currentNode, selectedNodes) => {
    const selectedValue = !props.singleSeelct ? selectedNodes : { ...selectedNodes, _children: null };
    setSelectedItem(selectedValue);
    props.setdatabyname(props.name, selectedValue);
  };

  const finalData = JSON.parse(JSON.stringify(data).replace(/"name":/g, '"label":'));

  const setEditDataHandler = (dataInput, valueInput) => {
    let finalList = [...dataInput];
    if (!props.singleSeelct) {
      finalList.forEach(lv1 => {
        let equalLv1 = false;
        valueInput.forEach(value => {
          if (lv1.id === value.id) {
            equalLv1 = true;
            if (lv1.children) {
              lv1.expanded = true;
              lv1.children.forEach(lv2 => {
                lv2.isDefaultValue = true;
              });
            } else {
              lv1.isDefaultValue = true;
            }
          }
        });
        if (!equalLv1 && lv1.children) {
          let isLv1Expanded = false;
          lv1.children.forEach(lv2 => {
            let equalLv2 = false;
            valueInput.forEach(value => {
              if (lv2.id === value.id) {
                equalLv2 = true;
                isLv1Expanded = true;
                if (lv2.children) {
                  lv2.expanded = true;
                  lv2.children.forEach(lv3 => {
                    lv3.isDefaultValue = true;
                  });
                } else {
                  lv2.isDefaultValue = true;
                }
              }
            });
            if (!equalLv2 && lv2.children) {
              let isLv2Expanded = false;
              lv2.children.forEach(lv3 => {
                valueInput.forEach(value => {
                  if (lv3.id === value.id) {
                    isLv2Expanded = true;
                    if (lv3.children) {
                      lv3.expanded = true;
                      lv3.children.forEach(lv4 => {
                        lv4.isDefaultValue = true;
                      });
                    } else {
                      lv3.isDefaultValue = true;
                    }
                  }
                });
              });
              lv2.expanded = isLv2Expanded;
            }
          });
          lv1.expanded = isLv1Expanded;
        }
      });
    }

    setEditData(finalList);
  };

  useEffect(() => {
    if (!selectedItem && props.defaultValue) {
      setEditDataHandler(finalData, props.defaultValue);
    }

    if (selectedItem) {
      setEditDataHandler(finalData, selectedItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue, selectedItem]);

  useEffect(() => {
    let total = 0;
    if (selectedItem) {
      for (let i = 0; i < selectedItem.length; i++) {
        if (selectedItem[i]._children) {
          total += selectedItem[i]._children.length;
        }
      }
      total += selectedItem.length;
    }
    setTotalItemCount(total);
    // eslint-disable-next-line
  }, [selectedItem]);

  const texts = {
    placeholder: props.placeholder || t('Select'),
    inlineSearchPlaceholder: t('placeholder.search'),
    noMatches: t('placeholder.noOptions')
  };

  return (
    <Fragment>
      {totalItemCount > 0 ? (
        <div className={`selectedCount ${props.dark ? `selectedCount-dark` : ``}`}>{totalItemCount}</div>
      ) : null}
      <Container
        data={editDataList ? editDataList : finalData}
        count={totalItemCount}
        texts={texts}
        onChange={onChange}
        selectedClass={`
          ${props.dark ? `multi-select-tree-dark` : `multi-select-tree-light`}
          ${selectedItem && selectedItem.length ? 'mdl-demo selected' : 'mdl-demo'}
        `}
        isDefaultValue={props.defaultValue ? true : false}
        value={selectedItem}
        onBlur={props.onBlur}
      />
    </Fragment>
  );
};

export default memo(MultiSelectTree);
