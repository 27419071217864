/** @format */

import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchHeader } from 'src/components/PageHeader';
import { ScrollAble } from 'src/components/ScrollAble';
import TabNav from 'src/components/Tabs';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import PostDetailLayer from 'src/widgets/cardLayer/postDetailLayer';
import { searchKeyword } from 'src/store/action/search.actions';
import LatestTabContent from './latestTabContent';
import { getInfoTypeList } from 'src/store/action/editor.Action';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/Loader';
import RelevantTabContent from './relevantTabContent';
import { SEARCH_FILTER_EVENT, dataLayerPushEvent } from 'src/utils/dataLayer';

const SearchPage = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchLatestResults = useSelector(state => state.searchReducer.searchLatestResults);
  const infoTypeList = useSelector(state => state.editorReducer.infoTypeList).map(o => ({
    ...o,
    name: t('infoTypes.' + o.name)
  }));
  const navOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const nextCursor = useSelector(state => state.searchReducer.nextCursor);

  useEffect(() => {
    // if (user && user.isWorkshop) {
    //   navigate('/topics');
    // }
    // eslint-disable-next-line
    if (searchLatestResults && searchLatestResults.length === 0) {
      const payload = {
        keywords: '',
        lang: user ? user.locale : 'en'
      };
      dispatch(searchKeyword(payload));
    }

    if (infoTypeList && infoTypeList.length === 0) {
      const payload = {
        lang: user ? user.locale : 'en'
      };
      dispatch(getInfoTypeList(payload));
    }

    // eslint-disable-next-line
  }, []);

  const contentTab = [
    {
      heading: t('searchContent.latest'),
      content: <LatestTabContent />
    },
    {
      heading: t('searchContent.relevant'),
      content: <RelevantTabContent />
    }
  ];

  const handleOnChangeTab = ({ filter }) => {
    dataLayerPushEvent(SEARCH_FILTER_EVENT, {
      action: 'Filter',
      filter
    });
  };

  return (
    <Fragment>
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Grid item xs={12} xl={navOpen ? 12 : 6}>
          <SearchHeader
            heading={
              searchLatestResults
                ? searchLatestResults.length
                  ? (nextCursor ? '>' : '') + searchLatestResults.length + ' ' + t('searchContent.results')
                  : t('searchContent.noResult')
                : t('searchContent.suggestedContent')
            }
          />

          <ScrollAble marginTop="40px">
            <TabNav data={contentTab} onChangeTab={handleOnChangeTab} />
            <PostDetailLayer />
          </ScrollAble>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SearchPage;
