/** @format */

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { LogoMain, LogoContinental } from '../../components/branding-logo';
import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { RightArrow } from '../../components/Icons';
import IconLabelButtons from '../../components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { cardLayerAction, cardLayerWidth, cardLayerData } from 'src/store/action/cardLayerAction';
import CardLayer from 'src/widgets/cardLayer';
import { getExtraNetAccess, getfooterLinks, getPageData, updatUserRoles } from 'src/store/action/publicPage.Action';
import i18n from 'src/i18n';
import landingPage from 'src/assets/imgs/login_bg.png';
import { css, cx } from '@emotion/css';
import { AutoCompleteSelectLight } from 'src/components/Dropdowns/LightDropdown';
import useAuth from 'src/hooks/useAuth';
import { getRolesList } from 'src/store/action/editor.Action';

const NoAccess = () => {
  const customCSS = {
    marketSelection: css`
      width: 100%;
      margin-top: 36px;
      margin-bottom: 41px;
    `
  };

  const rolesList = useSelector(state => state.editorReducer.rolesList);
  const cardLayerOpen = useSelector(state => state.cardLayerReducer.cardLayerOpen);
  const footerLinks = useSelector(state => state.publicPageReducer.footerLinks);
  const [selectedRole, setSelectedRole] = useState();
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [alreadyRequested, setAlreadyRequested] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [rolesLoading, setRolesLoading] = useState(false);
  const dispatch = useDispatch();
  const { uuid, logout, user, user_status } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user_status) {
      navigate('/dashboard');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (rolesList.length === 0) {
      setRolesLoading(true);
      dispatch(getRolesList({ lang: user ? user.locale : i18n.resolvedLanguage })).then(() => {
        setRolesLoading(false);
      });
    }

    if (footerLinks.length === 0) {
      dispatch(getfooterLinks(user ? user.locale : i18n.resolvedLanguage));
    }
    // eslint-disable-next-line
  }, []);

  const handleLogout = async () => {
    await logout();
  };
  const handleAskAccess = () => {
    const payload = {
      uuid,
      roles: [selectedRole.name]
    };
    dispatch(updatUserRoles(payload)).then(res => {
      if (res) {
        dispatch(getExtraNetAccess(payload)).then(response => {
          const { error, status } = response.payload;
          if (status === 204) {
            setRequestSuccess(true);
          } else if (error.status === 409) {
            setAlreadyRequested(true);
          } else if (error.status === 404) {
            setRejected(true);
          }
        });
      }
    });
  };
  const setRole = (name, data) => {
    setSelectedRole(data);
  };
  const handleOpenCard = id => {
    const payload = {
      lang: i18n.language,
      id: id
    };
    dispatch(getPageData(payload)).then(response => {
      const { title, text } = response.payload;
      const head_payload = {
        title: title
      };
      dispatch(cardLayerAction(!cardLayerOpen));
      dispatch(cardLayerWidth(95));
      dispatch(cardLayerData(head_payload, text));
    });
  };

  const rolesListFiltered = useMemo(
    () =>
      rolesList.filter(role => {
        if (!user?.email?.includes('@continental.com') && !user?.email.includes('@continental-corporation.com')) {
          return !role.name.includes('Intern');
        }
        return role;
      }),
    [rolesList, user?.email]
  );

  return (
    <Fragment>
      <Box className="landing-login">
        <Box className="bg-logo">
          <NavLink to="/" className="logo_desktop">
            <LogoMain />
          </NavLink>
          <NavLink to="/" className="logo_mobile">
            <LogoContinental />
          </NavLink>
        </Box>
        <Box className="landing-wrapper">
          <Box className="left-wrapper">
            <img src={landingPage} alt="Landing Page" className="landing-img" />
          </Box>
          <Box className="right-wrapper">
            {requestSuccess ? (
              <Box className="content-wrapper">
                <h1>{t('access_requested.title')}</h1>
                <p>{t('access_requested.text')}</p>
                <IconLabelButtons
                  endIcon={<RightArrow />}
                  type="button"
                  label={t('access_requested.cta')}
                  className="customButton dark"
                  onClick={handleLogout}
                />
              </Box>
            ) : alreadyRequested ? (
              <Box className="content-wrapper">
                <h1>{t('already_requested.title')}</h1>
                <p>{t('already_requested.text')}</p>
                <IconLabelButtons
                  endIcon={<RightArrow />}
                  type="button"
                  label={t('already_requested.cta')}
                  className="customButton dark"
                  onClick={handleLogout}
                />
              </Box>
            ) : rejected ? (
              <Box className="content-wrapper">
                <h1>This account has been rejected</h1>
                <p>This account has been rejected. Please contact us directly.</p>
                <IconLabelButtons
                  endIcon={<RightArrow />}
                  type="button"
                  label={t('already_requested.cta')}
                  className="customButton dark"
                  onClick={handleLogout}
                />
              </Box>
            ) : (
              <Box className="content-wrapper">
                <h1>{t('no_Access.title')}</h1>
                <p>{t('no_Access.text')}</p>
                <AutoCompleteSelectLight
                  options={rolesListFiltered}
                  name="roles"
                  customclass={cx(customCSS.marketSelection)}
                  placeholder={t('no_Access.rolePlaceholder')}
                  setdatabyname={setRole}
                  value={selectedRole}
                  loading={rolesLoading}
                  loadingText={t('placeholder.loading')}
                />
                <IconLabelButtons
                  endIcon={<RightArrow />}
                  type="button"
                  label={t('no_Access.cta')}
                  className="customButton dark"
                  onClick={handleAskAccess}
                />
              </Box>
            )}

            <Box className="landing-footer landing-page-footer">
              {footerLinks && footerLinks.length
                ? footerLinks.map((item, i) => {
                    return (
                      <Link
                        key={i}
                        onClick={() => handleOpenCard(item.contentId)}
                        className="link-text"
                        underline="always">
                        {item.linkTitle}
                      </Link>
                    );
                  })
                : null}
            </Box>
          </Box>
        </Box>
      </Box>
      <CardLayer />
    </Fragment>
  );
};

export default NoAccess;
