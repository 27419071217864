/** @format */

import { Box } from '@mui/material';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LabelSorting } from 'src/components/LabelSorting';
import { getContentList, setFilterOptions } from 'src/store/action/topic.Action';
import ContentCard from './contentCard';

const Tab1Content = () => {
  const checkNavOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
  const contentNextCursor = useSelector(state => state.topicReducer.contentNextCursor);
  const filterOptions = useSelector(state => state.topicReducer.filterOptions);
  const dataGrid = useSelector(state => state.topicReducer.editorContentList);
  const [openIndex, setOpenIndex] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [accordionId, setAccordionId] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState({ sortBy: 'created', sort: 'DESC' });

  const onMenuOpen = index => {
    setOpenIndex(index);
    setMenuOpen(true);
  };
  const onMenuClose = () => {
    setOpenIndex(null);
    setMenuOpen(false);
  };
  const onAccordionOpen = id => {
    setAccordionId(id);
  };
  const onAccordionClose = () => {
    setAccordionId(null);
  };

  // Pagination Start
  const intObserver = useRef();
  const lastPostRef = useCallback(
    post => {
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver(posts => {
        if (posts[0].isIntersecting && contentNextCursor) {
          const payload = {
            ...filterOptions,
            cursor: contentNextCursor
          };
          dispatch(getContentList(payload));
        }
      });

      if (post) intObserver.current.observe(post);
      // eslint-disable-next-line
    },
    [contentNextCursor]
  );
  // Pagination End

  // Ordering Start
  // const finalDataGrid = dataGrid.length ? [...dataGrid] : [];
  useEffect(() => {
    if (sortBy.sortBy !== filterOptions.order || sortBy.sort !== filterOptions.sort) {
      dispatch(setFilterOptions({ ...filterOptions, order: sortBy.sortBy, sort: sortBy.sort }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);
  // Ordering End

  return (
    <Fragment>
      {dataGrid ? (
        <Box sx={isMobile || checkNavOpen ? { overflow: 'auto' } : null}>
          <Box
            className={
              (isMobile || checkNavOpen ? 'content-tab-wrapper-mob' : 'content-tab-wrapper-resp') +
              ' content-tab-wrapper'
            }>
            <Box className="content-card-titles">
              <Box className="content-type">
                <LabelSorting
                  label={t('editorContent.tab_table_heading.type')}
                  name="contentType"
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </Box>
              <Box className="content-title">
                <LabelSorting
                  label={t('editorContent.tab_table_heading.title')}
                  name="title"
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </Box>
              <Box className="content-release">
                <LabelSorting
                  label={t('editorContent.tab_table_heading.release')}
                  name="releaseDate"
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </Box>
              {!isMobile && !checkNavOpen ? (
                <>
                  <Box className="content-created">
                    <LabelSorting
                      label={t('editorContent.tab_table_heading.created')}
                      name="created"
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                    />
                  </Box>
                  <Box className="content-removal">
                    <LabelSorting
                      label={t('editorContent.tab_table_heading.removal')}
                      name="expiryDate"
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                    />
                  </Box>
                  {/* Hide <Box className="content-usergroups">
                    <LabelSorting
                      label={t('editorContent.tab_table_heading.userGroups')}
                      name="roles"
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                    />
                  </Box> */}
                  <Box className="content-market">
                    <LabelSorting
                      label={t('editorContent.tab_table_heading.market')}
                      name="language"
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                    />
                  </Box>
                  <Box className="content-clicks">
                    <LabelSorting
                      label={t('editorContent.tab_table_heading.clicks')}
                      name="clicks"
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                    />
                  </Box>
                </>
              ) : null}

              <Box className="content-actions">{t('editorContent.tab_table_heading.actions')}</Box>
            </Box>

            {dataGrid &&
              dataGrid.map((item, i) => {
                let isPaginationItem = false;

                if (dataGrid.length === i + 1) {
                  isPaginationItem = true;
                }

                return (
                  <ContentCard
                    key={`${item.id}-${i}`}
                    data={item}
                    totalItem={dataGrid.length}
                    index={i}
                    menuOpen={menuOpen}
                    openIndex={openIndex}
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                    ref={isPaginationItem ? lastPostRef : undefined}
                    onAccordionOpen={onAccordionOpen}
                    accordionId={accordionId}
                    onAccordionClose={onAccordionClose}
                  />
                );
              })}

            {menuOpen && (
              <Box
                sx={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', zIndex: '1' }}
                onClick={onMenuClose}></Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={{ width: '100%', mt: '20px' }}>{t('noContent')}</Box>
      )}
    </Fragment>
  );
};

export default Tab1Content;
