/** @format */

import { Box } from '@mui/system';
import { ChevronDown, ChevronRight } from 'src/components/Icons';
import { CLICK_TOPIC, dataLayerPushEvent } from 'src/utils/dataLayer';
import { topicDisplayName } from 'src/utils/topicDisplayName';

export const TopicsFavoriteCard = ({ cardTopic, child, openLayer, ItemIndex, activeItem, cardTopicId, rootItem }) => {
  return (
    <Box className="topic_wrapper topic_wrapper-favorite">
      <button
        className={(activeItem === cardTopicId ? 'active ' : '') + 'topics-card-wrapper'}
        onClick={e => {
          const findSubItem = rootItem?.rootItem?.children?.find(item => {
            if (item.children) return item.children.find(item2 => item2.id === cardTopicId);
            return false;
          });
          openLayer({
            topicId: cardTopicId,
            rootItem: topicDisplayName(rootItem),
            index: undefined,
            subItem: findSubItem,
            index_2: undefined,
            index_3: undefined,
            favoriteItem: cardTopicId,
            cardTopicName: cardTopic
          });
        }}>
        <Box component="span" className="card-topic">
          {cardTopic}
        </Box>
      </button>
    </Box>
  );
};

const TopicsCard = ({
  cardTopic,
  child,
  openLayer,
  ItemIndex,
  activeItem,
  activeSubItem,
  cardTopicId,
  rootItem,
  active_3rd_Child
}) => {
  return (
    <Box className="topic_wrapper">
      <button
        className={(activeItem === ItemIndex ? 'active ' : '') + 'topics-card-wrapper'}
        onClick={e => {
          openLayer({
            topicId: cardTopicId,
            rootItem: topicDisplayName(rootItem),
            index: ItemIndex,
            subItem: undefined,
            index_2: undefined,
            index_3: undefined,
            favoriteItem: undefined,
            cardTopicName: undefined
          });
          dataLayerPushEvent(CLICK_TOPIC, {
            topic: cardTopic
          });
        }}>
        <Box component="span" className="card-topic">
          {cardTopic}
        </Box>
        {child && child.length > 0 && (
          <Box sx={{ ml: '10px' }}>
            <ChevronRight color="#ffffff" width="7.33" height="12.67" />
          </Box>
        )}
      </button>
      {activeItem === ItemIndex && child && child.length && (
        <Box className="sub_menu">
          <Box className="sub_menu_sticky">
            {child.slice().map((item, index) => {
              return (
                <Box key={index} className="sub-menu-inner">
                  <button
                    className={
                      (activeSubItem === index
                        ? item.children && item.children.length
                          ? 'active active-sub '
                          : 'active '
                        : '') + 'topics-card-wrapper'
                    }
                    onClick={e => {
                      openLayer({
                        topicId: item.id,
                        rootItem: topicDisplayName(rootItem),
                        index: ItemIndex,
                        subItem: item,
                        index_2: index,
                        index_3: undefined,
                        favoriteItem: undefined,
                        cardTopicName: undefined
                      });
                      dataLayerPushEvent(CLICK_TOPIC, {
                        topic: topicDisplayName(item)
                      });
                    }}>
                    <Box component="span" className="card-topic">
                      {topicDisplayName(item)}
                    </Box>
                    {item.children && item.children.length && (
                      <Box sx={{ ml: '10px' }}>
                        <ChevronDown color="#ffffff" height="7.33" width="12.67" />
                      </Box>
                    )}
                  </button>

                  {/* Third Level Menu Start */}
                  {activeSubItem === index && item.children && item.children.length ? (
                    <ul className="sub_item">
                      {item.children.slice().map((subitem, subindex) => {
                        return (
                          <li
                            key={subindex}
                            onClick={e => {
                              openLayer({
                                topicId: subitem.id,
                                rootItem: topicDisplayName(rootItem),
                                index: ItemIndex,
                                subItem: item,
                                index_2: index,
                                index_3: subindex,
                                favoriteItem: undefined,
                                cardTopicName: undefined
                              });
                              dataLayerPushEvent(CLICK_TOPIC, {
                                topic: topicDisplayName(subitem)
                              });
                            }}
                            className={active_3rd_Child === subindex ? 'active' : null}>
                            {topicDisplayName(subitem)}
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TopicsCard;
