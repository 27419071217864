import { Box } from "@mui/system";
import React from "react";
import Copyright from "./copyright";
import FooterLinks from "./footerLinks";

const Footer = props => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Copyright />
            <FooterLinks />
        </Box>
    );
}
export default Footer;