/** @format */

import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject((error.response) || 'Something went wrong');
  }
);

export default axiosInstance;
