import React from "react";
import { SwipeableDrawer } from '@mui/material';

import Portals from 'src/views/portals';
import { useSelector } from "react-redux";
import HelpPage from "src/views/help";
import Onboarding from "src/views/onboarding";
import { useLocation } from "react-router";



const BottomDrawer = () => {
    const openState = useSelector(state => state.bottomNavReducer.helpPageOpen);
    let location = useLocation();

    const toggleDrawer = (openEvent) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        
    };

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={openState}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            PaperProps={{ className: `custom-swipeable ${location.pathname === '/onboarding'?'onboarding-cover':''}`, sx: { width: '100% !important', borderRadius: '0 !important' } }}
            ModalProps={{ sx: { zIndex: 6000 } }}
        >
            {/* {BottomNavItems.map((item, i) => {
                    return (
                        (item.title === 'Help' && <HelpPage />) || (item.title === 'Portals' && <Portals />)
                    )
                })} */}
            {location.pathname === '/help' && <HelpPage />}
            {location.pathname === '/portals' && <Portals />}
            {location.pathname === '/onboarding' && <Onboarding />}
            {/* <Portals /> */}
        </SwipeableDrawer>
    )
}

export default BottomDrawer;