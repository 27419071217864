/** @format */

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export const LabelSorting = ({ label, name, sortBy, setSortBy }) => {
  return (
    <span
      className={`sort-label ${sortBy.sortBy === name ? `sort-label-active` : ``}`}
      onClick={() => {
        if (sortBy.sortBy !== name || sortBy.sort === 'DESC') {
          setSortBy({ sortBy: name, sort: 'ASC' });
        } else {
          setSortBy({ sortBy: name, sort: 'DESC' });
        }
      }}>
      {label}
      {sortBy.sortBy === name && (
        <>
          {sortBy.sort === 'ASC' && <ArrowDropUpIcon />}
          {sortBy.sort === 'DESC' && <ArrowDropDownIcon />}
        </>
      )}
    </span>
  );
};
