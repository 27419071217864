/** @format */

import React, { Fragment, useEffect, useState } from 'react';
import Subheader from 'src/components/Subheader';
import { ScrollAble } from 'src/components/ScrollAble';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import MenuList from './menuList';
import { LogoutButton } from 'src/components/Icons';
import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { profilePageAction } from 'src/store/action/profile.Action';
import { navigationOpenAction } from 'src/store/action/navigationOpenAction';

const BaseLayer = () => {
  const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
  const profilePage = useSelector(state => state.profileReducer.profilePage);
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const dispatch = useDispatch();
  const [active, setActive] = useState();

  useEffect(() => {
    if (!isMobile) setActive(0);
    else setActive(null);
  }, [isMobile]);

  useEffect(() => {
    if (!active) setActive(profilePage - 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePage]);

  const usermenu = [
    // { menu: t('profile.menu.collections'), id:1 },
    { menu: t('profile.menu.personalData'), id: 2 },
    { menu: t('profile.menu.account'), id: 3 },
    { menu: t('profile.menu.newsletter'), id: 4 }
  ];
  if (user.isAdmin) usermenu.push({ menu: t('profile.menu.userReporting'), id: 5 });

  const handleLogout = async () => {
    await logout();
  };
  const onChangePage = (index, pageId) => {
    dispatch(profilePageAction(pageId));
    setActive(index);
    if (isMobile) dispatch(navigationOpenAction(false));
  };

  return (
    <Fragment>
      <Subheader title={user?.firstName + ' ' + user?.lastName} subtitle={(user && user.roles && user)?.roles[0]} />
      <ScrollAble height="100%" marginTop="40px">
        <Grid container>
          <Grid item xs={12} lg={8} xl={6}>
            {usermenu.map((item, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    flexBasis: 'calc(50% - 40px)',
                    marginBottom: 1,
                    width: { md: '300px' },
                    maxWidth: { md: '100%' },
                    minWidth: { lg: '300px' }
                  }}>
                  <MenuList
                    index={i}
                    active={active}
                    id={item.id}
                    menu={item.menu}
                    onChangePage={onChangePage}
                    className={'profile-nav-button'}
                  />
                </Box>
              );
            })}
            <Box
              sx={{
                flexBasis: 'calc(50% - 40px)',
                marginBottom: 1,
                width: { md: '300px' },
                maxWidth: { md: '100%' },
                minWidth: { lg: '300px' }
              }}>
              <button className={'topics-card-wrapper profile-nav-button logout'} onClick={handleLogout}>
                <Box component="span" className="card-topic">
                  {t('profile.menu.Logout')}
                </Box>
                <LogoutButton />
              </button>
            </Box>
          </Grid>
        </Grid>
      </ScrollAble>
    </Fragment>
  );
};

export default BaseLayer;
