/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import { helpButtonAction } from 'src/store/action/bottomNavActions';
import { useNavigate } from 'react-router';
import CloseButton from 'src/components/Buttons/closeButton';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { ScrollAble } from 'src/components/ScrollAble';
import IconLabelButtons from 'src/components/Buttons';
import { RightArrow } from 'src/components/Icons';
// import useAuth from "src/hooks/useAuth";

const HelpPage = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // const {user} = useAuth
  // const navOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const { t } = useTranslation();
  const handleClose = () => {
    dispatch(helpButtonAction(false));
    navigate(-1);
  };
  const onBaording = () => {
    dispatch(helpButtonAction(true));
    navigate('/onboarding');
  };

  return (
    <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
      <Box sx={{ position: 'absolute', top: '40px', right: '40px', zIndex: 4 }}>
        <CloseButton label={t('close')} className="close-button dark" endclose={+true} onClick={handleClose} />
      </Box>

      <ScrollAble>
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={12} xl={5}>
            <Box sx={{ marginTop: '8rem', marginBottom: '5rem' }}>
              <h1>{t('Help')}</h1>
            </Box>
            <Box sx={{ position: 'relative' }}>
              <IconLabelButtons
                endIcon={<RightArrow />}
                type="button"
                label={t('onBoarding_btn')}
                className="customButton dark"
                onClick={onBaording}
              />
            </Box>
          </Grid>
        </Grid>
      </ScrollAble>
    </Box>
  );
};
export default HelpPage;
