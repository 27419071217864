/** @format */

import React, { Fragment } from 'react';
import { Box, Link, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import { RightArrow } from 'src/components/Icons';
import { detailsLayerAction, detailsLayerWidth, detailsLayerData } from 'src/store/action/detailsLayerAction';
import { useTranslation } from 'react-i18next';
import { getNewsList, newsById } from 'src/store/action/news.Action';
import { useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { postDetailLayerAction, postDetailLayerWidth, postDetailLayerData } from 'src/store/action/detailsLayerAction';
import { css, cx } from '@emotion/css';
import PlaceholderImage from '../../assets/imgs/preview-1.svg';
import { format } from 'date-fns';
import { CLICK_BUTTON_EVENT, dataLayerPushEvent } from 'src/utils/dataLayer';

const NewsWidget = props => {
  const postDetailOpenState = useSelector(state => state.detailsLayerReducer.postDetailOpenState);
  const detailsLayerOpen = useSelector(state => state.detailsLayerReducer.detailsOpenState);
  const [newsList] = useState(
    props.dashboards && props.dashboards.newsPosts && props.dashboards.newsPosts.length
      ? props.dashboards.newsPosts
      : []
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useAuth();

  const handleOpenDetailsCard = () => {
    const head_payload = {
      title: t('news.resultTitle')
    };
    dispatch(detailsLayerAction(!detailsLayerOpen));
    dispatch(detailsLayerWidth(91));
    dispatch(getNewsList({ lang: user ? user.locale : 'en', limit: 100 })).then(response => {
      const { newsPosts } = response.payload;
      dispatch(detailsLayerData(head_payload, newsPosts));
    });
    dataLayerPushEvent(CLICK_BUTTON_EVENT, {
      click_text: 'View all',
      headline_test: 'News',
      target_screen: 'News'
    });
  };

  const newsDetail = newsId => {
    const payload = {
      lang: user ? user.locale : 'en',
      id: newsId
    };
    dispatch(newsById(payload)).then(data => {
      if (data.payload && data.payload.newsPost) {
        const head_payload = {
          title: data.payload.newsPost.title
        };
        dispatch(postDetailLayerAction(!postDetailOpenState));
        dispatch(postDetailLayerWidth(91));
        dispatch(postDetailLayerData(head_payload, data.payload.newsPost));
        dataLayerPushEvent(CLICK_BUTTON_EVENT, {
          click_text: data.payload.newsPost.title,
          headline_test: data.payload.newsPost.title,
          target_screen: 'News'
        });
      }
    });
  };

  const customCSS = {
    newsImageWrap: css`
      position: relative;
      max-width: 100%;
      // max-width: max-content;
      overflow: hidden;
      cursor: pointer;
      border-radius: 8px;
      @media screen and (max-width: 599px) {
        max-width: 100%;
      }
    `
  };

  return (
    <Box className="card-container">
      <h3>{t('news.title')}</h3>
      <Box className="news-wrapper">
        <Grid container spacing={4}>
          {newsList && newsList.length ? (
            <Fragment>
              <Grid item xs={12} sm={6}>
                {newsList.slice(0, 1).map((item, index) => {
                  return (
                    <Box
                      className={cx(customCSS.newsImageWrap)}
                      onClick={() => newsDetail(item.id)}
                      key={index}
                      style={{
                        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 31.77%, rgba(0, 0, 0, 0.81) 100%), url(${
                          item.pictureUrl ? item.pictureUrl : PlaceholderImage
                        }) center no-repeat`,
                        backgroundSize: 'contain'
                      }}>
                      <img
                        src={item.pictureUrl ? item.pictureUrl : PlaceholderImage}
                        alt="News Detail"
                        className="news-img"
                        style={{ opacity: 0 }}
                      />
                      {/* <a href="javascript:;"> */}
                      <Box className="img-heading">
                        {/* <Box className='img-head'>{item.title}</Box> */}

                        <Link
                          className={(item.pictureUrl ? 'link-text-white' : 'link-text') + ' img-head'}
                          underline="none"
                          onClick={() => newsDetail(item.id)}>
                          {item.title}
                        </Link>

                        <Box
                          sx={{ display: 'flex', justifyContent: 'space-between' }}
                          className={(!item.pictureUrl ? 'dark' : '') + ' img-date-time'}>
                          <Box>{format(new Date(item.releaseDate), 'PPP')}</Box>
                          <Box>
                            {item.readTime} {t('news.readTime')}
                          </Box>
                        </Box>
                      </Box>
                      {/* </a> */}
                    </Box>
                  );
                })}
              </Grid>

              <Grid item xs={12} sm={6}>
                {/* <Box sx={{ flexBasis: 'calc(50% - 16px)' }}> */}
                {newsList &&
                  newsList.length &&
                  newsList.slice(1, 5).map((item, index) => {
                    return (
                      <Box className="newsBlock" key={index}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} className="date-time">
                          <Box>{format(new Date(item.releaseDate), 'PPP')}</Box>
                          <Box>
                            {item.readTime} {t('news.readTime')}
                          </Box>
                        </Box>
                        <Link className="link-text heading" underline="none" onClick={() => newsDetail(item.id)}>
                          {item.title}
                        </Link>
                      </Box>
                    );
                  })}
              </Grid>
            </Fragment>
          ) : (
            <Grid item md={12}>
              <h4>{t('noDataFound')}</h4>
            </Grid>
          )}
        </Grid>
        <Box className="widget_cta_button">
          <IconLabelButtons
            endIcon={<RightArrow />}
            type="button"
            label={t('viewAllCTA')}
            className="customButton transLabel dark endTrans"
            onClick={() => handleOpenDetailsCard()}
            disabled={newsList.length === 0}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NewsWidget;
