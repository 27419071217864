import React from 'react';
import { Box } from '@mui/material';
import IconLabelButtons from 'src/components/Buttons';
import { RightArrow } from 'src/components/Icons';
// import { useDispatch } from 'react-redux';
// import { getlinkWidegt } from '../../store/action/linkWidget.actions'
// import { useEffect } from 'react';
// import { useState } from 'react';
// import { useNavigate } from "react-router-dom";
import { css, cx } from '@emotion/css';
import linkBgImage from 'src/assets/imgs/image_marketing.png'

const LinksWidget = props => {
    // let navigate = useNavigate();

    
    // const dispatch = useDispatch();
    // const [id] = useState(125)
    // useEffect(() => {
    //     dispatch(getlinkWidegt(id))
    //     // eslint-disable-next-line
    // }, [])

    const BgImage = props.dashboards && props.dashboards.link? props.dashboards.link.pictureUrl : linkBgImage;
    const customCSS = {
        linkWidgetBackground: css`
            background: linear-gradient(0deg, rgba(226, 226, 226, 0.6), rgba(226, 226, 226, 0.6)),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 100%),
            url(${BgImage}), #ffffff;
            background-size:cover;
    `
    };

    function goToLink(link) {
        if (link) {
            window.open(`${link}`,'__blank')
        } else {
            
            window.open("https://hub.workshopreport.com/",'__blank')
            
        }
    };

    if (props.dashboards && props.dashboards.link) {
        const {link, linkTitle, teaser, title } = props.dashboards.link
        return (
            <Box className={`card-container no-cta links-widget ${cx(customCSS.linkWidgetBackground)}`}>
                <h3>{title ? title : "VDO Hub"}</h3>
                <Box sx={{ height: 'calc(100% - 52px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box className='link-text'>
                        {teaser ? teaser : "Your password manager for VDO workshops"}
                    </Box>

                    <Box className='widget_cta_button'>
                        <IconLabelButtons endIcon={<RightArrow />} type="button" label={linkTitle ? linkTitle : "Visit VDO Hub"} className="customButton transLabel dark endTrans" onClick={() => goToLink(link)} />
                    </Box>
                </Box>
            </Box>
        );
    }
    else {
        return (
            <Box className={`card-container no-cta links-widget ${cx(customCSS.linkWidgetBackground)}`}>
                <h3>{"Data Not Found"}</h3>
                <Box sx={{ height: 'calc(100% - 52px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box className='link-text'>
                        Your password manager for VDO workshops
                    </Box>

                    <Box className='widget_cta_button'>
                        <IconLabelButtons endIcon={<RightArrow />} type="button" label="Visit VDO Hub" className="customButton transLabel dark endTrans" />
                    </Box>
                </Box>
            </Box>
        );
    }
};

export default LinksWidget;