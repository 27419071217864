/** @format */

import React, { Fragment, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from '../Icons';

export const AutoCompleteSelectLight = props => {
  const { t } = useTranslation();
  const { setdatabyname, options, customclass, loading, loadingText, renderOption, ...data } = props;
  const [open, setOpen] = useState(props.open ?? false);

  return (
    <Box className={`autoComplete-wrapper light ${props.customclass}`}>
      <Autocomplete
        className="autoComplete light"
        disablePortal
        id="combo-box-demo"
        options={props.options}
        getOptionLabel={option => (option.name ? option.name : option)}
        autoComplete={true}
        autoSelect={true}
        includeInputInList={true}
        disableClearable={true}
        disabled={props.disabled}
        loading={loading ?? false}
        loadingText={loadingText ?? 'Loading...'}
        popupIcon={<ChevronDown />}
        onChange={(event, newValue) => {
          props.setdatabyname(props.name, newValue);
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={props.open}
        value={props.value ? props.value : ''}
        noOptionsText={t('placeholder.noOptions')}
        renderInput={params => (
          <TextField
            {...params}
            {...data}
            placeholder={props.placeholder ? props.placeholder : t('Select')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {open && props.loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              )
            }}
          />
        )}
        renderOption={renderOption}
        multiple={props.multiple}
      />
    </Box>
  );
};
