/** @format */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_CANDIDATES_LIST,
  UPDATE_CANDIDATES,
  NEXT_CANDIDATES_LIST,
  UPDATE_USER_ROLE,
  GET_USER_ROLES_BY_LANG,
  FILTER_MARKET_CANDIDATE,
  GET_USER_LIST,
  NEXT_USER_LIST,
  SEARCH_USER_CLAUSE,
  DELETE_USER_CLAUSE,
  FILTER_USER_GROUP
} from './type.actions';
import axiosInstance from '../../utils/axios';
import { ENDPOINTS } from 'src/constants';

export const getCandidatesList = createAsyncThunk(GET_CANDIDATES_LIST, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/candidates?limit=${payload.limit}`, { headers });

  return response.data;
});

export const nextCandidatesList = createAsyncThunk(NEXT_CANDIDATES_LIST, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/candidates?limit=${payload.limit}&&cursor=${payload.cursor}`, { headers });

  return response.data;
});

export const updateCandidate = createAsyncThunk(UPDATE_CANDIDATES, async payload => {
  const { id, lang } = payload;
  const headers = {
    'Accept-Language': lang
  };
  const response = await axiosInstance.post(`candidates/${id}/process`, payload, { headers });

  return response.data;
});

export const updateUserRole = createAsyncThunk(UPDATE_USER_ROLE, async payload => {
  const { uuid, roles, lang } = payload;
  const headers = {
    'Accept-Language': lang
  };
  const response = await axiosInstance.put(
    `${ENDPOINTS.GET_USER_INFO_ENDPOINT}${uuid}/roles`,
    { uuid, roles },
    { headers }
  );

  return response.data;
});

export const getUserRoleByLang = createAsyncThunk(GET_USER_ROLES_BY_LANG, async payload => {
  const { lang } = payload;
  const headers = {
    'Accept-Language': lang
  };
  const response = await axiosInstance.get(`${ENDPOINTS.GET_USER_ROLES_ENDPOINT}?lang=${lang}`, { headers });

  return {
    market: lang,
    roles: response.data.roles
  };
});

export const filterMarket = createAction(FILTER_MARKET_CANDIDATE);
