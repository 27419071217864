import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CLEAR, GET_NEWS_BY_ID, GET_NEWS_LIST } from './type.actions';
import axiosInstance from '../../utils/axios';

export const getNewsList = createAsyncThunk(GET_NEWS_LIST, async (payload) => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/news-posts?limit=${payload.limit}`, { headers });

  return response.data;
});

export const newsById = createAsyncThunk(GET_NEWS_BY_ID, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };

  let response;
  if(payload.contentLang){
    response = await axiosInstance.get(`/news-posts/${payload.id}/view?lang=${payload.contentLang}`, { headers });
  }else{
    response = await axiosInstance.get(`/news-posts/${payload.id}/view`, { headers });
  }

  return response.data;
});

export const clear = createAction(CLEAR);
