/** @format */
import { createReducer } from '@reduxjs/toolkit';
import {
  deleteUser,
  deleteUserClause,
  getUserList,
  nextUserList,
  searchUserClause,
  updateUserListPayload
} from 'src/store/action/user.Action';

const initialState = {
  updatedUser: undefined,
  roles: {},
  userList: [],
  nextUserCursor: undefined,
  filterUser: [],
  userClauses: [],
  userListPayload: {
    limit: 50,
    order: 'created',
    orderDirection: 'DESC',
    userClauses: [],
    userGroup: [],
    market: []
  }
};

const userReducer = createReducer(initialState, builder => {
  builder
    .addCase(updateUserListPayload, (state, action) => {
      state.userListPayload = action.payload;
    })
    .addCase(getUserList.fulfilled, (state, action) => {
      state.userList = action.payload.users;
      state.nextUserCursor = action.payload.nextCursor;
    })
    .addCase(nextUserList.fulfilled, (state, action) => {
      state.userList = state.userList.concat(action.payload.users);
      state.nextUserCursor = action.payload.nextCursor;
    })
    .addCase(searchUserClause, (state, action) => {
      state.userClauses = [...state.userClauses, action.payload];
    })
    .addCase(deleteUserClause, (state, action) => {
      state.userListPayload.userClauses = state.userListPayload.userClauses.filter(clause => clause !== action.payload);
    })
    .addCase(deleteUser.fulfilled, (state, action) => {
      // state.userListPayload.userClauses = state.userListPayload.userClauses.filter(clause => clause !== action.payload);
    })
    .addDefaultCase((state, action) => {});
});

export default userReducer;
