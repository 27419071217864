/** @format */

import React from 'react';
import LinksWidget from 'src/widgets/Links';
import MarketingWidget from 'src/widgets/marketing';
import NotificationWidget from 'src/widgets/Notification';
import SearchWidget from 'src/widgets/Search';
import NewsWidget from '../../widgets/News';
import RepairIt from '../../widgets/repairIt'
// import { DefaultCard } from '../Card'

const GetWidget = props => {
  const data = props.data;
  const handleWidgetType = data => {
    switch (data.type) {
      case 'news': //can be whatever this for testing
        return <NewsWidget dashboards={data.dashboards} />;
      case 'search': //can be whatever this for testing
        return <SearchWidget dashboards={data.dashboards} />;
      case 'notifications': //can be whatever this for testing
        return <NotificationWidget dashboards={data.dashboards} />;
      case 'repairIt': //can be whatever this for testing
        return <RepairIt data={data} dashboards={data.dashboards} />;
      case 'link': //can be whatever this for testing
        // return <DefaultCard data={data} />;
        return <LinksWidget data={data} dashboards={data.dashboards} />
      case 'marketing':
        return <MarketingWidget dashboards={data.dashboards} />
      default:
        return 'No type widget match!'; // when not found that widget *can be whatever
    }
  };

  return data && handleWidgetType(data);
};

export { GetWidget };
