/** @format */

import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import '../../../node_modules/react-dropdown-tree-select/dist/styles.css';

const Container = props => {
  const [dataState, setDataState] = useState(props.data);
  const { selectedClass, value, data, onBlur, ...rest } = props;

  useEffect(() => {
    if (!isEqual(data, dataState)) {
      setDataState(data);
    }
  }, [data, dataState]);

  return (
    <DropdownTreeSelect
      data={data}
      className={selectedClass}
      inlineSearchInput={true}
      showPartiallySelected={true}
      texts={props.texts}
      {...rest}
      isDefaultValue={true}
      value={value}
      onBlur={onBlur}
    />
  );
};
export default Container;
