/** @format */

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { setDefaultOptions } from 'date-fns';

const fallbackLanguage = 'en';
const supportedLanguages = ['en', 'en-GB', 'de', 'de-AT', 'it', 'es', 'fr', 'nl', 'pl'];
const i18nOptions = {
  debug: false,
  fallbackLng: fallbackLanguage,
  supportedLngs: supportedLanguages,
  nonExplicitSupportedLngs: true,
  load: 'all',
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  react: {
    useSuspense: true
  },
  detection: {
    caches: []
  }
};

i18n
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./locales/${language}.json`)
        .then(resources => {
          callback(null, resources);
        })
        .catch(error => {
          callback(error, null);
        });
    })
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nOptions, () => {
    document.documentElement.lang = i18n.resolvedLanguage || fallbackLanguage; // eslint-disable-line no-undef
  })
  .then(() => {
    if (i18n.resolvedLanguage !== 'en') {
      import(`date-fns/locale/${i18n.resolvedLanguage}/index.js`).then(locale => {
        setDefaultOptions({ locale: locale });
      });
    }
  });

export default i18n;
