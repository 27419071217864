import React from 'react';

const Subheader = ({ title, subtitle, ...rest }) => {
  return (
    <div className={`subnav_header-wrapper`}>
      <h2 className={`subnav_header-title`}>{title}</h2>
      {subtitle && <h6 className={`subnav_header-sub`}>{subtitle}</h6>}
    </div>
  );
};


export default Subheader;