/** @format */
import { createReducer } from '@reduxjs/toolkit';
import { getlinkWidegt } from '../action/linkWidget.actions';

const initialState = {
  serialNumber: undefined
};

const linkWidegtReducer = createReducer(initialState, builder => {
  builder
    .addCase(getlinkWidegt.pending, (state, action) => {
      state.loading = true;
      state.serialNumber = action.meta.requestId;
    })
    .addCase(getlinkWidegt.fulfilled, (state, action) => {
      if (state.serialNumber === action.meta.serialNumber) {
        state.loading = false;
        state.serialNumber = action.payload;
      }
    })
    .addCase(getlinkWidegt.rejected, (state, action) => {
      if (state.serialNumber === action.meta.serialNumber) {
        state.loading = false;
        state.serialNumber = initialState.serialNumber;
      }
    })
    .addDefaultCase((state, action) => {});
});

export default linkWidegtReducer;

