/** @format */

import React, { useState } from 'react';
import { Box, Chip, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { CloseSmallIcon, SearchIconPro } from '../Icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSearchOption, searchOption } from 'src/store/action/search.actions';
import { t } from 'i18next';
import { dataLayerPushEvent, SEARCH_EVENT } from 'src/utils/dataLayer';

const SearchChip = () => {
  let dispatch = useDispatch();
  const clauses = useSelector(state => state.searchReducer.clauses);
  const [value, setValue] = useState('');

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleKeyDown = evt => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
      const clause = value.trim();

      if (clause) {
        dispatch(searchOption(clause));
        setValue('');
        dataLayerPushEvent(SEARCH_EVENT, {
          keyword: clause,
          place: 'Search Menu'
        });
      }
    }
  };

  const handleDelete = clause => () => {
    dispatch(deleteSearchOption(clause));
  };

  const isOperator = clause => {
    return ['OR', 'AND'].includes(clause.toUpperCase());
  };

  return (
    <Box className="search-chip dark">
      <OutlinedInput
        id="clauses"
        placeholder={t('keywords')}
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <SearchIconPro />
          </InputAdornment>
        }
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {clauses.map((clause, i) => {
        return (
          <Chip
            key={i}
            label={!isOperator(clause) ? clause : clause.toUpperCase()}
            title={clause}
            onDelete={handleDelete(clause)}
            deleteIcon={
              <IconButton>
                <CloseSmallIcon color="#ffffff" height="8" width="8" />
              </IconButton>
            }
          />
        );
      })}
    </Box>
  );
};

export default SearchChip;
