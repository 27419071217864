/** @format */

import * as React from 'react';
import { FormGroup, Radio } from '@mui/material';
import { RadioEmpty, RadioFilled } from 'src/components/Icons';

const RadioBox = ({ label, boxProps, inputProps, checked }) => {
  return (
    <FormGroup className="checkbox_box radio" {...boxProps}>
      <Radio id={label} icon={<RadioEmpty />} checkedIcon={<RadioFilled />} {...inputProps} checked={checked} />
      {label ? (
        <label htmlFor={label} className="radioLabel">
          {label}
        </label>
      ) : null}
    </FormGroup>
  );
};

export default RadioBox;
