/** @format */

import React, { Fragment, useEffect } from 'react';
import { ScrollAble } from 'src/components/ScrollAble';
import Account from './account';
import { useDispatch, useSelector } from 'react-redux';
import Personal from './personal';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import NewsLetter from './newsletter';
import UserReporting from './userReporting';
import { profilePageAction } from 'src/store/action/profile.Action';

const Profile = () => {
  const profilePage = useSelector(state => state.profileReducer.profilePage);
  const navOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (pathname.replace('/profile/', '') === 'user-reporting') {
      dispatch(profilePageAction(5));
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    if (user && user.isWorkshop) {
      navigate('/topics');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <ScrollAble marginTop="34px" className="profile">
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          {['2', '3', '4'].indexOf(profilePage) && (
            <Grid item xs={12} xl={navOpen ? 12 : 5}>
              {profilePage === 3 && <Account />}
              {profilePage === 2 && <Personal />}
              {profilePage === 4 && <NewsLetter />}
              {profilePage === 5 && <UserReporting />}
            </Grid>
          )}
        </Grid>
      </ScrollAble>
    </Fragment>
  );
};

export default Profile;
