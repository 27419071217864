import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const OutlinedInputFieldsDark = props => {
    return (
        <Box
            component="form"
            // sx={{
            //     '& .MuiTextField-root': { width: '100%' },
            // }}
            noValidate
            autoComplete="off"
        >
            <TextField
                className='text-fields dark'
                id="outlined-error"
                {...props}
            />
        </Box>
    );
};

export default OutlinedInputFieldsDark;
