/** @format */

import { Box } from '@mui/system';
import React, { forwardRef, Fragment, useState } from 'react';
import {
  ChevronDown,
  ChevronUp,
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  EyeIcon,
  HideIcon,
  StackIcon,
  ThreeDotsIcon
} from 'src/components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import {
  cloneContentPost,
  cloneMarketingPost,
  cloneNewsPost,
  deleteContentPost,
  deleteMarketingsPost,
  deleteNewsPost,
  disablePost,
  editContentPost,
  editMarketingPost,
  editNewsPost,
  editorOpen
} from 'src/store/action/editor.Action';
import { css, cx } from '@emotion/css';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import IconLabelButtons from 'src/components/Buttons';
import { getContentList } from 'src/store/action/topic.Action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const ContentCard = forwardRef(
  (
    {
      data,
      totalItem,
      index,
      menuOpen,
      openIndex,
      onMenuOpen,
      onAccordionOpen,
      accordionId,
      onAccordionClose,
      onMenuClose
    },
    ref
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useAuth();
    const checkNavOpen = useSelector(state => state.navigationReducer.navigationOpen);
    const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
    const openState = useSelector(state => state.editorReducer.openState);
    const [isModalOpen, setDeleteModal] = useState(false);
    const [postId, setPostId] = useState(undefined);
    const [published, setPublished] = useState(data.published ?? true);
    const [isDeleted, setIsDeleted] = useState(false);

    const customCSS = {
      typeWrap: css`
        margin-left: 24px;
        @media screen and (max-width: 899px) {
          margin-left: 8px;
        }
      `
    };

    const editPost = () => {
      const payload = {
        lang: user ? user.locale : 'en',
        id: data.id
      };

      if (data.contentType === 'content') {
        dispatch(editContentPost(payload)).then(data => {
          if (data) {
            dispatch(editorOpen(!openState));
          }
        });
      } else if (data.contentType === 'marketing') {
        dispatch(editMarketingPost(payload)).then(data => {
          if (data) {
            dispatch(editorOpen(!openState));
          }
        });
      } else if (data.contentType === 'news') {
        dispatch(editNewsPost(payload)).then(data => {
          if (data) {
            dispatch(editorOpen(!openState));
          }
        });
      }

      onMenuClose();
    };

    const clonePost = () => {
      const payload = {
        lang: user ? user.locale : 'en',
        id: data.id
      };

      if (data.contentType === 'content') {
        dispatch(cloneContentPost(payload)).then(data => {
          if (data) {
            dispatch(editorOpen(!openState));
          }
        });
      } else if (data.contentType === 'marketing') {
        dispatch(cloneMarketingPost(payload)).then(data => {
          if (data) {
            dispatch(editorOpen(!openState));
          }
        });
      } else if (data.contentType === 'news') {
        dispatch(cloneNewsPost(payload)).then(data => {
          if (data) {
            dispatch(editorOpen(!openState));
          }
        });
      }

      onMenuClose();
    };

    const deleteCompleted = confirmDel => {
      setIsDeleted(true);
      if (confirmDel) setDeleteModal(!confirmDel);
      setPostId(undefined);
    };
    const deletePost = (confirmDel, type, id) => {
      onMenuClose();
      setDeleteModal(!confirmDel);
      setPostId(id);

      if (type === 'news') {
        if (confirmDel) {
          dispatch(deleteNewsPost(postId)).then(response => {
            if (response) {
              deleteCompleted(confirmDel);
            }
          });
        }
      } else if (type === 'marketing') {
        if (confirmDel) {
          dispatch(deleteMarketingsPost(postId)).then(response => {
            if (response) {
              deleteCompleted();
            }
          });
        }
      } else if (type === 'content') {
        if (confirmDel) {
          dispatch(deleteContentPost(postId)).then(response => {
            if (response) {
              deleteCompleted();
            }
          });
        }
      }
    };

    const togglePost = () => {
      dispatch(
        disablePost({
          type: data.contentType,
          id: data.id,
          published: !published,
          locale: data.market
        })
      ).then(response => {
        if (response) {
          setPublished(!published);
        }
      });
    };

    const accordionChevrons = () => {
      if (!checkNavOpen && !isMobile) {
        return;
      }

      if (accordionId === index) {
        return (
          <button onClick={() => onAccordionClose()}>
            <ChevronUp color="#FFA500" />
          </button>
        );
      } else {
        return (
          <button onClick={() => onAccordionOpen(index)}>
            <ChevronDown color="rgb(0,0,0)" />
          </button>
        );
      }
    };

    return !isDeleted ? (
      <Fragment>
        <Fragment>
          <Box className={`content-card-wrapper ${!published ? 'unpublished' : ''}`} ref={ref ?? ref}>
            <Box className="content-type">
              <Box className="type-icon">
                <StackIcon color="#FFA500" width="31.5" height="31.5" />
              </Box>
              <Box className={cx(customCSS.typeWrap)}>
                <span>{t('editorContent.type.' + data.contentType)}</span>
                <span className="content-type-tag">{data.tag}</span>
              </Box>
            </Box>
            <Box className="content-title">
              <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{data.title}</Box>
            </Box>
            <Box className="content-release">{data.releaseDate}</Box>

            {!checkNavOpen && !isMobile ? (
              <>
                <Box className="content-created">{data.created}</Box>
                <Box className="content-removal">{data.expiryDate}</Box>
                {/* Hide <Box className="content-usergroups">{data.roles?.length > 0 && data.roles[0]}</Box> */}
                <Box className="content-market">{data.market}</Box>
                <Box className="content-clicks">{data.clicks}</Box>
              </>
            ) : null}

            <Box className="content-actions">
              <Box className="content-action-menu-wrap">
                <button onClick={() => onMenuOpen(index)} className={openIndex === index && menuOpen ? 'active' : ''}>
                  <ThreeDotsIcon color="#000000" />
                </button>

                {openIndex === index && menuOpen && (
                  <Box
                    className="action-menu"
                    style={index + 3 >= totalItem && totalItem > 3 ? { top: 'auto', bottom: 40 } : {}}>
                    <button className="action-menu-item" onClick={editPost}>
                      <span>
                        <EditIcon />
                      </span>{' '}
                      {t('editorContent.postAction.edit')}
                    </button>
                    <button className="action-menu-item" onClick={clonePost}>
                      <span>
                        <DuplicateIcon />
                      </span>{' '}
                      {t('editorContent.postAction.duplicate')}
                    </button>
                    <button className="action-menu-item" onClick={togglePost}>
                      <span>{published ? <HideIcon /> : <EyeIcon />}</span>{' '}
                      {published ? t('editorContent.postAction.hide') : t('editorContent.postAction.show')}
                    </button>
                    <button className="action-menu-item" onClick={() => deletePost(false, null, data.id)}>
                      <span>
                        <DeleteIcon />
                      </span>{' '}
                      {t('editorContent.postAction.delete')}
                    </button>
                  </Box>
                )}
              </Box>
              {accordionChevrons()}
            </Box>
          </Box>

          {accordionId === index && checkNavOpen && (
            <Box className={`accordionBody ${!published ? 'unpublished' : ''}`}>
              {isMobile ? (
                <Fragment>
                  <Box className="content">
                    <Box className="heading">{t('editorContent.tab_table_heading.release')}</Box>
                    <Box className="head-detail">{data.releaseDate}</Box>
                    <Box className="content-blank"></Box>
                  </Box>
                  <Box className="content">
                    <Box className="heading">{t('editorContent.tab_table_heading.removal')}</Box>
                    <Box className="head-detail">{data.expiryDate}</Box>
                    <Box className="content-blank"></Box>
                  </Box>
                </Fragment>
              ) : (
                <Fragment>
                  <Box className="content">
                    <Box className="heading">
                      {t('editorContent.tab_table_heading.release')} + {t('editorContent.tab_table_heading.removal')}
                    </Box>
                    <Box className="head-detail">
                      {data.releaseDate} + {data.expiryDate}
                    </Box>
                    <Box className="content-blank"></Box>
                  </Box>
                </Fragment>
              )}

              <Box className="content">
                <Box className="heading">{t('editorContent.tab_table_heading.userGroups')}</Box>
                <Box className="head-detail">{data.roles?.length && data.roles.join(', ')}</Box>
                <Box className="content-blank"></Box>
              </Box>
              <Box className="content">
                <Box className="heading">{t('editorContent.tab_table_heading.market')}</Box>
                <Box className="head-detail">{data.market}</Box>
                <Box className="content-blank"></Box>
              </Box>
              <Box className="content">
                <Box className="heading">{t('editorContent.tab_table_heading.clicks')}</Box>
                <Box className="head-detail">{data.clicks}</Box>
                <Box className="content-blank"></Box>
              </Box>
            </Box>
          )}
        </Fragment>

        <Modal
          open={isModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="delete-modal">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('editorContent.deletion.title')}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {t('editorContent.deletion.description')}
            </Typography>
            <Box sx={{ mt: 4, justifyContent: 'flex-end', display: 'flex', gap: 2 }}>
              <IconLabelButtons
                type="button"
                label={t('editorContent.deletion.no')}
                className="customButton dark"
                onClick={() => setDeleteModal(false)}
              />
              <IconLabelButtons
                type="button"
                label={t('editorContent.deletion.yes')}
                className="customButton light"
                onClick={() => deletePost(true, data.contentType)}
              />
            </Box>
          </Box>
        </Modal>
      </Fragment>
    ) : null;
  }
);

export default ContentCard;
