/** @format */

import { configureStore } from '@reduxjs/toolkit';
import {
  navigationReducer,
  dashboardWidgetReducer,
  dashboardWidgetDataReducer,
  cardLayerReducer,
  linkWidegtReducer,
  searchReducer,
  profileReducer,
  repairItReducer,
  editorReducer,
  newsReducer,
  topicReducer,
  detailsLayerReducer,
  dashboardConfigReducer,
  bottomNavReducer,
  marketingReducer,
  publicPageReducer,
  dashboardsReducer,
  adminReducer,
  contentReducer,
  nortificationLayerReducer,
  userReportingReducer,
  userReducer,
  adminTopicsReducer
} from './reducers';

export const store = configureStore({
  reducer: {
    navigationReducer,
    dashboardWidgetReducer,
    dashboardWidgetDataReducer,
    cardLayerReducer,
    linkWidegtReducer,
    searchReducer,
    profileReducer,
    repairItReducer,
    editorReducer,
    newsReducer,
    topicReducer,
    detailsLayerReducer,
    dashboardConfigReducer,
    bottomNavReducer,
    marketingReducer,
    publicPageReducer,
    dashboardsReducer,
    adminReducer,
    contentReducer,
    nortificationLayerReducer,
    userReportingReducer,
    userReducer,
    adminTopicsReducer
  }
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
