import {createAsyncThunk} from '@reduxjs/toolkit';
import {GET_CONTENT_POST_BY_ID} from './type.actions'
import axiosInstance from '../../utils/axios'

export const contentPostById = createAsyncThunk(GET_CONTENT_POST_BY_ID, async payload => {
  const headers ={
      'Accept-Language' : payload.lang,
  }

  let response;
  if(payload.contentLang){
    response = await axiosInstance.get(`/content-posts/${payload.id}/view?lang=${payload.contentLang}`, { headers });
  }else{
    response = await axiosInstance.get(`/content-posts/${payload.id}/view`, { headers });
  }

  return response.data;
})
