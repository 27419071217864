/** @format */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'src/utils/axios';
import { NORTIFICATION_LAYER_OPEN, NORTIFICATION_LAYER_WIDTH, NORTIFICATION_LAYER_DATA, MARK_READ, MARK_ALL_READ, GET_NORTIFICATIONS, GET_NORTIFICATIONS_NEXT } from './type.actions';

export const nortificationLayerAction = createAction(NORTIFICATION_LAYER_OPEN);

export const nortificationLayerWidth = createAction(NORTIFICATION_LAYER_WIDTH)

export const nortificationLayerData = createAction(NORTIFICATION_LAYER_DATA, (head, data) => {
    return { payload: { head, data } }
})

export const markRead = createAsyncThunk(MARK_READ, async id => {
    // const headers ={
    //     'Accept-Language' : payload.lang,
    // }
    const response = await axiosInstance.post(`/notifications/${id}/mark-as-read`);
    
    return response.data;
})

export const markAllRead = createAsyncThunk(MARK_ALL_READ, async () => {
    // const headers ={
    //     'Accept-Language' : payload.lang,
    // }
    const response = await axiosInstance.post(`/notifications/mark-as-read`);
    
    return response.data;
})

export const getNortifications = createAsyncThunk(GET_NORTIFICATIONS, async (payload) => {
    const response = await axiosInstance.get(`/notifications?limit=${payload.limit}`);
    return response.data;
})

export const getNortifications_Next = createAsyncThunk(GET_NORTIFICATIONS_NEXT, async (payload) => {
    const {limit, cursor} = payload
    const response = await axiosInstance.get(`/notifications?limit=${limit}&cursor=${cursor}`);
    return response.data;
})