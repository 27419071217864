import {createAsyncThunk} from '@reduxjs/toolkit';
import {CREATE_DASHBOARD_CONFIG, DELETE_DASHBOARD_CONFIG, UPDATE_DASHBOARD_CONFIG, GET_DASHBOARD_CONFIG} from './type.actions'
import axiosInstance from '../../utils/axios'

/**
 * @param {Object[]: {uuid, layout}
 */

export const createDashboardConfig = createAsyncThunk(CREATE_DASHBOARD_CONFIG, async (data) => {
    const response = await axiosInstance.post(`/users/${data.uuid}/dashboard-configurations`,
    {
        layout: data.layout
    });
    return response.data
});


/**
 * @param {Object[]: {uuid, id}}
 */
export const deleteDashboardConfig = createAsyncThunk(DELETE_DASHBOARD_CONFIG, async (data) => {
    const response = await axiosInstance.delete(`/users/${data.uuid}/dashboard-configurations/${data.id}`);
    return response.data
});

/**
 * @param {Object[]: {uuid, id, layout}}
 */

export const updateDashboardConfig = createAsyncThunk(UPDATE_DASHBOARD_CONFIG, async (data) => {
    const response = await axiosInstance.put(`/users/${data.uuid}/dashboard-configurations/${data.id}`,
    {
        layout: data.layout
    });
    return response.data
});

/**
 * @param {Object[]: {uuid, id}}
 */

export const getDashboardConfig = createAsyncThunk(GET_DASHBOARD_CONFIG, async (data) => {
    const response = await axiosInstance.get(`/users/${data.uuid}/dashboard-configurations/${data.id}`);
    return response.data
});


