import { css, cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { SwipeableDrawer } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollAble } from 'src/components/ScrollAble';
import TabNav from 'src/components/Tabs';
import { detailsLayerAction } from 'src/store/action/detailsLayerAction';
import { ContentCardNormal, ContentCardWide } from './contentCard';
import TabContent from './tab_content';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import IconLabelButtons from 'src/components/Buttons';
import { RightArrow } from 'src/components/Icons';
import CloseButton from 'src/components/Buttons/closeButton';

const DetailsLayer = () => {
  const { t } = useTranslation();
  let location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const detailsOpenState = useSelector(state => state.detailsLayerReducer.detailsOpenState);
  const detailsLayerHead = useSelector(state => state.detailsLayerReducer.detailsLayerHead);
  const detailsLayerData = useSelector(state => state.detailsLayerReducer.detailsLayerData);
  const detailsLayerWidth = useSelector(state => state.detailsLayerReducer.detailsLayerWidth);
  const contentTabData = detailsLayerHead && detailsLayerHead.tabs && detailsLayerHead.tabs.map((item, index) => {
    return {
      heading: item.tabName,
      content: <TabContent dataGrid={item.id === 0 ? detailsLayerData : 'No Data'} wrapperClass="news_wrapper"
                           contentClass="news_card"/>
    };
  });

  const toggleDetails = (openEvent) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(detailsLayerAction(openEvent));
  };

  const gotoTopic = () => {
    navigate('/topics');
    dispatch(detailsLayerAction(!detailsOpenState));
  };

  const gotoSearch = () => {
    navigate('/search');
    dispatch(detailsLayerAction(!detailsOpenState));
  };

  const handleCloseDetailsLayer = () => {
    dispatch(detailsLayerAction(!detailsOpenState));
  };

  const customCSS = {
    previewLayer: css`
      &.MuiPaper-root {
        background: #FFFDFA;
        padding: 40px 60px;
        border-radius: 40px 0 0 40px;
        width: ${detailsLayerWidth}%;
        @media screen and (max-width: 768px) {
          padding: 20px 16px;
        }
      }
    `,
    layerCloseButton: css`
      &.close-button {
        justify-content: flex-start;
        @media screen and (max-width: 768px) {
          justify-content: flex-end;
        }
      }
    `,
    heading: css`
      margin-top: 40px;
    `,
    cardsWrap: css`
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    `
  };

  let cardCounter = 0;

  return (
    <SwipeableDrawer
      anchor={'right'}
      onClose={toggleDetails(false)}
      onOpen={toggleDetails(true)}
      PaperProps={{ className: `${cx(customCSS.previewLayer)}` }}
      ModalProps={{ sx: { zIndex: 6000 } }}
      open={detailsOpenState}
    >
      <CloseButton
        label={t('close')}
        startclose={+true}
        onClick={() => handleCloseDetailsLayer()}
        className={`close-button dark ${cx(customCSS.layerCloseButton)}`}
      />
      <ScrollAble>
        <h1 className={cx(customCSS.heading)}>{detailsLayerHead && detailsLayerHead.title}</h1>
        {detailsLayerHead && <p>{detailsLayerHead.description}</p>}
        {detailsLayerHead && detailsLayerHead.tabs ? <TabNav data={contentTabData}/> :
          <Box>
            {location.pathname === '/profile' && detailsLayerData && detailsLayerData.length <= 0 &&
              <Box sx={{ fontSize: 24, margin: '2rem 0' }}>
                {t('collection.noCollection')}
                <Box sx={{ margin: '4rem 0' }}>
                  <IconLabelButtons endIcon={<RightArrow/>} type="button" label={t('collection.browse_cta')}
                                    className="customButton transLabel dark endTrans" onClick={gotoTopic}/>
                  <IconLabelButtons endIcon={<RightArrow/>} type="button" label={t('collection.search_cta')}
                                    className="customButton transLabel dark endTrans" sx={{ marginLeft: 5 }}
                                    onClick={gotoSearch}/>
                </Box>
              </Box>
            }
            <Box className={cx(customCSS.cardsWrap)} sx={{ mt: '40px' }}>
              {detailsLayerData && detailsLayerData.slice(0, 2).map((item, index) => {
                return <ContentCardWide data={item} key={index} contentClass="news_card"/>;
              })}
            </Box>
            <Box className={cx(customCSS.cardsWrap)} sx={{ mt: '24px' }}>
              {detailsLayerData && detailsLayerData.slice(2, 6).map((item, index) => {
                return <ContentCardNormal data={item} key={index} contentClass="news_card news_card_normal"/>;
              })}
            </Box>

            {detailsLayerData && detailsLayerData.slice(6, detailsLayerData.length).map((item, index, data) => {
              if (cardCounter >= 7) {
                cardCounter = 0;
              }

              cardCounter++;

              if (cardCounter < 5) {
                if (data[index + 1]) {
                  cardCounter++;
                }

                return (
                  <Box className={cx(customCSS.cardsWrap)} sx={{ mt: '24px' }}>
                    <ContentCardWide data={item} key={index} contentClass="news_card"/>
                    {data[index + 1] ?
                      <ContentCardWide data={data[index + 1]} key={index + 1} contentClass="news_card"/>
                      : null}
                  </Box>
                );
              } else {
                if (data[index + 2]) {
                  cardCounter += 2;
                } else if (data[index + 1]) {
                  cardCounter++;
                }

                return (
                  <Box className={cx(customCSS.cardsWrap)} sx={{ mt: '24px' }}>
                    <ContentCardNormal data={item} key={index} contentClass="news_card news_card_normal"/>
                    {data[index + 1] ?
                      <ContentCardWide data={data[index + 1]} key={index + 1} contentClass="news_card"/>
                      : null}
                    {data[index + 2] ?
                      <ContentCardNormal data={data[index + 2]} key={index + 2}
                                         contentClass="news_card news_card_normal"/>
                      : null}
                  </Box>
                );
              }
            })}
          </Box>
        }
      </ScrollAble>
    </SwipeableDrawer>
  );
};

export default DetailsLayer;
