import React from 'react';
import { Box } from '@mui/system';
import { Chip, IconButton } from '@mui/material';
import { CloseSmallIcon } from '../Icons';

export const TagBasic = props => {
    return (
        <Box className='custom-tag' sx={{ backgroundColor: `${props.bgcolor}` }} {...props}>
            {props.content}
        </Box >
    );
}

export const ChipBasic = props => {
    return (
        <Box>
            <Chip
                className={`custom-chip ${props.chipClass}`}
                label={props.label}
                sx={{ background: `${props.background}`, color: `${props.color}` }}
            />
        </Box>
    )
}

export const ChipClearable = props => {
    const handleDelete = () => {
        console.info("Handle delete function")
    }
    return (
        <Box>
            <Chip
                className='custom-chip'
                label={props.label}
                onDelete={handleDelete}
                deleteIcon={<IconButton disableRipple><CloseSmallIcon width="7.92" height="7.92" color={props.iconColor} /></IconButton>}
                sx={{ background: `${props.background}`, color: `${props.color}` }}
            />
        </Box>
    )
}