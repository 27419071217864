/** @format */

import { css, cx } from '@emotion/css';
import { Chip, Grid, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import MultiSelectDropdownDark from 'src/components/MultiSelectDropdowns/MultiSelectDropdownDark';
import { ScrollAble } from 'src/components/ScrollAble';
import Subheader from 'src/components/Subheader';
import { filterMarket } from 'src/store/action/admin.Action';
import { marketMap } from '../../utils/market';
import { CloseSmallIcon, SearchIconPro } from 'src/components/Icons';

const customCSS = {
  filterRow: css`
    margin-top: 22px;
    align-items: center;
  `,
  filterLabel: css`
    font: 500 17px/25px ContinentalStagSansW-M;
    @media screen and (max-width: 899px) {
      margin-bottom: 16px !important;
    }
  `
};

const userGroup = [
  'driver',
  'fleet',
  'workshop',
  'workshop-cv',
  'workshop-pv',
  'partner',
  'authority',
  'trainer',
  'conti-internal'
];

const AdminBaseLayer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [markets, setMarkets] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchValues, setSearchValues] = useState([]);

  useEffect(() => {
    dispatch(filterMarket(markets));
  }, [dispatch, markets]);

  const setDataByName = (e, data) => {
    setMarkets(data);
  };

  const resetFilters = () => {
    setMarkets([]);
  };

  const isOperator = clause => {
    return ['OR', 'AND'].includes(clause.toUpperCase());
  };

  return (
    <>
      <Subheader title={t('editorBase.title')} />
      <Grid height="100%" marginTop="32px">
        <Box className="editorBaseWrapper">
          <Grid container className={cx(customCSS.filterRow)} sx={{ mt: '40px' }}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('admin.locale')}
            </Grid>
            <Grid item xs={12} lg={7}>
              <MultiSelectDropdownDark
                listdata={Array.from(marketMap, ([id, name]) => ({ id, name: t(`market.${name}`) }))}
                name="market"
                setdatabyname={setDataByName}
                defaultValue={markets}
              />
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)} sx={{ mt: '32px' }}>
            <IconLabelButtons
              type="button"
              label={t('editorBase.cta')}
              className="customButton dark"
              onClick={resetFilters}
            />
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default AdminBaseLayer;
