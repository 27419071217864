/** @format */

import { css, cx } from '@emotion/css';
import { Box } from '@mui/system';
import React from 'react';

const Copyright = props => {
  const customCSS = {
    copyright: css`
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
    `
  };
  const currentYear = new Date().getFullYear();
  return <Box className={cx(customCSS.copyright)}>© Continental AG {currentYear || `2023`}</Box>;
};
export default Copyright;
