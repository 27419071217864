/** @format */

import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000
  }
}));

const SlashScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box width={40}>
        {/* <LinearProgress /> */}
        {/* <CircularProgress /> */}
        <span className="loader-progress"></span>
      </Box>
    </div>
  );
};

export default SlashScreen;
