/** @format */

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigationOpenAction, setMobileDevice } from 'src/store/action/navigationOpenAction';
import Navigation from './navigation';
import { cx, css } from '@emotion/css';
import { LeftArrow, RightArrow } from 'src/components/Icons';
import { LogoContinental } from 'src/components/branding-logo';
import BottomNav from './navigation/bottomNav';
import IconLabelButtons from 'src/components/Buttons';
// import CookiesLayer from 'src/cookieLayer';
// import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BottomDrawer from './bottomDrawer';
import useAuth from 'src/hooks/useAuth';
import { helpButtonAction } from 'src/store/action/bottomNavActions';
import { CONTENT_LAYER_EVENT, dataLayerPushEvent } from 'src/utils/dataLayer';
// import { Global } from '@emotion/react';

const drawerBleeding = 56;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff'
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 50,
  height: 6,
  backgroundColor: '#CCC',
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 25px)'
}));

// const usePrevLocation = (location) => {
//   const prevLocRef = useRef(location)
//   useEffect(() => {
//     prevLocRef.current = location
//   }, [location])
//   return prevLocRef.current
// }

const Layouts = ({ children, data }) => {
  const navOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
  const dispatch = useDispatch();
  let location = useLocation();
  let navigate = useNavigate();
  // const prevLocation = usePrevLocation(location)

  const [windowHeight, setWindowHeight] = useState();
  const [mobileWidth, setMobile] = useState(isMobile);
  const heightHeader = mobileWidth ? 5 : 8;
  const widthwNav = mobileWidth ? 13.8 : 12.8;
  const styled = {
    header: css`
      height: ${heightHeader}rem;
    `,
    contentWrapper: css`
      height: ${windowHeight}px;
    `,
    navigationBar: mobileWidth
      ? css`
          width: 100%;
        `
      : css`
          width: ${widthwNav}rem;
        `,
    subnavigation: mobileWidth
      ? css`
          width: 100%;
        `
      : css`
          width: calc((100% - ${widthwNav}rem) / 2);
        `,
    containerInner: css`
      // height: calc(100% - ${heightHeader}rem);
      height: 100%;
    `,
    contentBox: mobileWidth
      ? css`
          transform: translateY(${navOpen ? `calc(92% + ${widthwNav}rem)` : ` ${widthwNav}rem`});
          height: calc((100% - ${widthwNav}rem));
          width: 100%;
        `
      : css`
          transform: translateX(${navOpen ? `calc(50% + ${widthwNav}rem)` : ` ${widthwNav}rem`});
          width: calc((100% - ${widthwNav}rem));
        `,
    projection: navOpen
      ? css`
          visibility: hidden;
        `
      : css`
          position: absolute;
          top: 0;
          left: 127px;
          height: 80px;
          width: 40px;
          background: #ffa500;
          z-index: 1;
          animation: cssAnim 0s 0.5s forwards;
          visibility: hidden;
          @keyframes cssAnim {
            to {
              visibility: visible;
            }
          }
        `,
    mobilelogic: css`
      @media and screen(max-width:767px) {
        display: none;
      }
    `
  };

  const { user, user_status } = useAuth();
  useEffect(() => {
    if (user_status === 403) {
      return navigate('/register');
    }
    if (user && user.onboardingComplete === false) {
      dispatch(helpButtonAction(true));
      navigate('/onboarding');
    }
    // eslint-disable-next-line
  }, [user, user_status]);

  useEffect(() => {
    setWindowHeight(window?.innerHeight);
    onResize();
    window.addEventListener('resize', () => {
      setWindowHeight(window?.innerHeight);
      onResize();
    });

    return () => {
      window.removeEventListener('resize', () => {
        setWindowHeight(window?.innerHeight);
        onResize();
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(navigationOpenAction(false));
    if (location.pathname === '/profile' || location.pathname === '/search' || location.pathname === '/topics') {
      dispatch(navigationOpenAction(true));
    }
    if (location.pathname === '/editor' && !isMobile) {
      dispatch(navigationOpenAction(true));
    }
    // eslint-disable-next-line
  }, [location]);

  const handleOpenSubNav = () => {
    // setNavOpen(!navOpen);
    dispatch(navigationOpenAction(!navOpen));
    dataLayerPushEvent(CONTENT_LAYER_EVENT, {
      action: !navOpen ? 'open' : 'close'
    });
  };

  const onResize = () => {
    if (window.innerWidth <= 899) {
      setMobile(true);
      dispatch(setMobileDevice(true));
    }
    if (window.innerWidth > 900) {
      setMobile(false);
      dispatch(setMobileDevice(false));
    }
  };

  return (
    <section className={cx(styled.contentWrapper, `containerWrapper`)}>
      <Box className={cx(styled.containerInner, 'containerInner')}>
        <Box className={mobileWidth ? cx(styled.mobilelogic) : cx(styled.projection)}></Box>
        <Box className={cx(styled.navigationBar, 'navigationBar')}>
          <Box sx={{ position: 'relative' }}>
            <div className={cx(styled.header, 'header')}>
              <Link to={user?.isWorkshop ? '/topics' : '/dashboard'}>
                <LogoContinental />
              </Link>
              {mobileWidth ? <div className="mobile_logo_txt">EXTRANET</div> : null}
            </div>
            {!mobileWidth && <div className="logo-extranet-text">EXTRANET</div>}
            <Navigation />
          </Box>
          {!mobileWidth && !user?.isWorkshop && <BottomNav />}
          {/* <BottomNav /> */}
        </Box>
        {Boolean(data.subItem) && (
          <Box className={cx(styled.subnavigation, 'subnavigation')}>
            <data.subItem />
            {/* sub navigation component for each of page*/}
          </Box>
        )}
        {mobileWidth ? (
          <Fragment>
            {/* <Global
              styles={{
                ".MuiDrawer-root > .MuiPaper-root": {
                  height: `calc(50% - ${drawerBleeding}px)`,
                  overflow: "visible"
                }
              }}
            /> */}

            <SwipeableDrawer
              anchor="bottom"
              open={!navOpen}
              onClose={() => handleOpenSubNav()}
              onOpen={() => handleOpenSubNav()}
              swipeAreaWidth={drawerBleeding}
              hideBackdrop={true}
              disableDiscovery={true}
              disableSwipeToOpen={false}
              ModalProps={{
                keepMounted: true,
                className: 'drawer_Dashbaord'
              }}>
              {location.pathname !== '/dashboard' && (
                <StyledBox
                  sx={{
                    position: 'absolute',
                    top: navOpen ? -drawerBleeding : 0,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    visibility: 'visible',
                    right: 0,
                    left: 0,
                    backgroundColor: '#FFF'
                  }}>
                  <Puller />
                </StyledBox>
              )}
              {children}
            </SwipeableDrawer>
          </Fragment>
        ) : (
          <Box className={cx(styled.contentBox, 'contentBox')}>
            <Box className={navOpen ? `contentBoxInnerOpen` : `contentBoxInner`}>{children}</Box>
            {mobileWidth
              ? null
              : Boolean(data.subItem) && (
                  <IconLabelButtons
                    type="button"
                    onClick={() => handleOpenSubNav()}
                    className="btnToggle customButton noLabel dark"
                    endIcon={navOpen ? <LeftArrow /> : <RightArrow />}
                  />
                )}
          </Box>
        )}
        {/* <div className={cx(styled.contentBox, 'contentBox')}>
          <div className={navOpen ? `contentBoxInnerOpen` : `contentBoxInner`}>{children}</div>
          <IconLabelButtons endIcon={navOpen ? <LeftArrow /> : <RightArrow />} type="button" className="btnToggle customButton noLabel dark" onClick={() => handleOpenSubNav()} />
        </div> */}
      </Box>
      <BottomDrawer />
      {/* <CookiesLayer/> */}
    </section>
  );
};

export default Layouts;
