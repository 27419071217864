/** @format */
import { createReducer } from '@reduxjs/toolkit';
import { navigationOpenAction, setMobileDevice } from '../action/navigationOpenAction';

const initialState = {
  navigationOpen: false,
  mobileWidth: false
};

const navigationReducer = createReducer(initialState, builder => {
  builder
    .addCase(navigationOpenAction, (state, action) => {
      state.navigationOpen = action.payload;
    })
    .addCase(setMobileDevice, (state, action) => {
      state.mobileWidth = action.payload;
    })
    .addDefaultCase((state, action) => { });
});

export default navigationReducer;
