/** @format */
import { createReducer } from '@reduxjs/toolkit';
import { getfooterLinks } from '../action/publicPage.Action';

const initialState = {
    footerLinks:[],
    footerLinks_fail:false
};

const publicPageReducer = createReducer(initialState, builder => {
  builder
    .addCase(getfooterLinks.fulfilled, (state, action) => {
        state.footerLinks = action.payload.footerLinks;
        state.footerLinks_fail = action.payload.footerLinks.length <= 0 ?true:false
      })
    .addDefaultCase((state, action) => {});
});

export default publicPageReducer;
