/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { store } from './store';
import { Provider } from 'react-redux';
import './i18n';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from 'src/contexts/KeycloakContext';

const theme = createTheme({
  typography: {
    fontFamily: 'ContinentalStagSansW',
    fontSize: 24
  }
});

const root = (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </ThemeProvider>
);

ReactDOM.render(root, document.getElementById('root'));
