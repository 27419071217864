/** @format */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DASHBOARD_WIDGET, GET_MARKETING_WIDGET } from './type.actions';
import axiosInstance from '../../utils/axios'

const dashBoardWidgetAction = createAction(DASHBOARD_WIDGET);

const getMarketingWidget = createAsyncThunk(GET_MARKETING_WIDGET, async (id) => {
    const response = await axiosInstance.get(`/marketing-posts/${id}`);
    return response.data;
})

// const getNortifications = createAsyncThunk(GET_NORTIFICATIONS, async (id) => {
//     const response = await axiosInstance.get(`/notifications`);
//     return response.data;
// })



export { dashBoardWidgetAction, getMarketingWidget };
