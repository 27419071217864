/** @format */

import React, { Fragment, lazy, useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ScrollAble } from 'src/components/ScrollAble';
import { css, cx } from '@emotion/css';
import { getContentDetailById, getNewDetailById } from 'src/store/action/topic.Action';
import useAuth from 'src/hooks/useAuth';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CallIcon, LeftArrow, MessageIcon } from 'src/components/Icons';
import userImage from 'src/assets/imgs/bg-login.jpg';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { ChipBasic } from 'src/components/Tags';
import CloseButton from 'src/components/Buttons/closeButton';
import { marketingPostById } from 'src/store/action/marketing.action';
import { topicDisplayName } from 'src/utils/topicDisplayName';
import Errorspage from '../errors';

const MultiplePDFViewer = lazy(() => import('src/widgets/cardLayer/multiplePDFViewer'));

const TopicPage = () => {
  const customCSS = {
    layerCloseButton: css`
      z-index: 99;
      &.close-button {
        justify-content: flex-start;
        @media screen and (max-width: 768px) {
          justify-content: flex-end;
        }
      }
    `,
    heading: css`
      margin-top: 40px;
    `,
    cardsWrap: css`
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    `,
    postDetailContainer: css`
      max-width: 1348px;
      margin: 40px auto;

      & > * {
        max-width: 800px;
        margin: 0 auto;

        & hr {
          text-align: center;
        }
      }
    `,
    postDate: css`
      color: #6b6b6b;
      letter-spacing: 0.01em;
    `,
    postHeading: css`
      margin-top: 8px;
    `,
    postTags: css`
      display: flex;
      column-gap: 4px;
      margin-top: 40px;
      flex-wrap: wrap;
      row-gap: 4px;
    `,
    activeChip: css`
      &.MuiChip-filled {
        background: #f4e7d4;
      }
    `,
    divider: css`
      &.MuiDivider-root {
        background: rgba(0, 0, 0, 0.3);

        margin: 24px auto 0;
      }
    `,
    postAuthorImage: css`
      width: 72px;
      height: 72px;
      border-radius: 6.74286px;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    `,
    postAuthorName: css`
      font-size: 18px;
      line-height: 29px;
    `,
    postAuthorTitle: css`
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #969696;
    `,
    authorAction: css`
      height: 50px;
      width: 50px;
      display: grid;
      place-items: center;
      background: #b3b3b3;
      border-radius: 50%;
    `,
    authorActionDetail: css`
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #969696;
      margin-left: 16px;
    `,
    authorSig: css`
      margin-top: 24px;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #000000;
      display: flex;
      justify-content: flex-end;

      span {
        color: #969696;
      }
    `
  };
  const location = useLocation();
  const navigate = useNavigate();
  const { pageId } = useParams();
  const [param] = useSearchParams();
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useAuth();
  const [postDetails, setPostDetails] = useState();
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      const type = location.pathname.split('/', 2)[1];

      if (type === 'content') {
        const payload = {
          lang: user ? user.locale : 'en',
          id: pageId,
          contentLang: param.get('lang') ? param.get('lang') : ''
        };
        dispatch(getContentDetailById(payload)).then(data => {
          if (data.payload) {
            setPostDetails(data.payload.contentPost);
          } else {
            setNotFound(true);
          }
        });
      } else if (type === 'news') {
        const payload = {
          lang: user ? user.locale : 'en',
          id: pageId,
          contentLang: param.get('lang') ? param.get('lang') : ''
        };
        dispatch(getNewDetailById(payload)).then(data => {
          if (data.payload) {
            setPostDetails(data.payload.newsPost);
          } else {
            setNotFound(true);
          }
        });
      } else if (type === 'marketing') {
        const payload = {
          lang: user ? user.locale : 'en',
          id: pageId,
          contentLang: param.get('lang') ? param.get('lang') : ''
        };
        dispatch(marketingPostById(payload)).then(data => {
          if (data.payload) {
            setPostDetails(data.payload.marketingPost);
          } else {
            setNotFound(true);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleClosePostDetailLayer = () => {
    navigate('/topics');
  };

  if (notFound) {
    return (
      <Fragment>
        <CloseButton
          label="Back"
          startclose={+true}
          startIcon={<LeftArrow />}
          onClick={() => handleClosePostDetailLayer()}
          className={`close-button dark ${cx(customCSS.layerCloseButton)}`}
        />
        <Errorspage />
      </Fragment>
    );
  }

  if (postDetails) {
    return (
      <Fragment>
        <CloseButton
          label="Back"
          startclose={+true}
          startIcon={<LeftArrow />}
          onClick={() => handleClosePostDetailLayer()}
          className={`close-button dark ${cx(customCSS.layerCloseButton)}`}
        />
        <ScrollAble marginTop="34px" className="admin-user">
          <Box className={cx(customCSS.postDetailContainer)}>
            <Box className={`p-small ${cx(customCSS.postDate)}`}>
              {postDetails && format(new Date(postDetails.releaseDate), 'PPP')}
            </Box>
            <h2 className={cx(customCSS.postHeading)}>{postDetails && postDetails.title}</h2>
            <Box className={cx(customCSS.postTags)}>
              {postDetails &&
                postDetails.topics &&
                postDetails.topics.length &&
                postDetails.topics.map((item, index) => {
                  return <ChipBasic key={index} label={topicDisplayName(item)} background={'rgba(0, 0, 0, 0.05)'} />;
                })}
              {postDetails &&
                postDetails.tags &&
                postDetails.tags.length &&
                postDetails.tags.map((item, index) => {
                  return <ChipBasic key={index} label={item.name} background={'#F4E7D4'} />;
                })}
            </Box>
            <Divider className={cx(customCSS.divider)} />

            <Box
              component="p"
              sx={{ mt: '56px' }}
              dangerouslySetInnerHTML={{ __html: postDetails && postDetails.text }}></Box>

            {postDetails && postDetails.documents && postDetails.documents.length && (
              <MultiplePDFViewer documents={postDetails.documents} />
            )}

            {/* Temporarily disable department */}
            {false && postDetails && postDetails.departments && postDetails.departments.length
              ? postDetails.departments.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: '80px' }}>
                        <Box className={cx(customCSS.postAuthorImage)}>
                          <img src={userImage} alt="User Profile" />
                        </Box>
                        <Box sx={{ ml: '16px' }}>
                          <Box className={cx(customCSS.postAuthorName)}>{item?.contactPerson?.name}</Box>
                          <Box className={cx(customCSS.postAuthorTitle)}>{item?.name}</Box>
                        </Box>
                      </Box>

                      <Box sx={{ mt: '24px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box className={cx(customCSS.authorAction)}>
                            <CallIcon width="18.04" height="18.05" color="#fff" />
                          </Box>
                          <Box className={cx(customCSS.authorActionDetail)}>Call {item?.contactPerson?.phone}</Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: '8px' }}>
                          <Box className={cx(customCSS.authorAction)}>
                            <MessageIcon height="18.78" width="18.78" color="#fff" />
                          </Box>
                          <Box className={cx(customCSS.authorActionDetail)}>
                            Email{' '}
                            <a href={`mailto:${item?.contactPerson?.email}`} target="_blank" rel="noreferrer">
                              {item?.contactPerson?.email}
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </Fragment>
                  );
                })
              : null}
            <Divider className={cx(customCSS.divider)} sx={{ mt: '80px !important' }} />
          </Box>
        </ScrollAble>
      </Fragment>
    );
  }

  return <></>;
};

export default TopicPage;
