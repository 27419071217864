/** @format */
import { createReducer } from '@reduxjs/toolkit';
import { dashboardWidgetDataAction } from 'src/store/action/dashboardWidgetDataAction';
const initialState = {
  dashboardWidgetData: []
};

const dashboardWidgetDataReducer = createReducer(initialState, builder => {
  builder
    .addCase(dashboardWidgetDataAction, (state, action) => {
      state.dashboardWidgetData = action.payload;
    })
    .addDefaultCase((state, action) => {});
});

export default dashboardWidgetDataReducer;
