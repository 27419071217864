import { createAction } from '@reduxjs/toolkit';
import {
    DETAILS_LAYER_ACTION, DETAILS_LAYER_WIDTH, DETAILS_LAYER_DATA,
    FORM_LAYER_WIDTH, FORM_LAYER_ACTION,
    POST_DETAIL_LAYER_ACTION, POST_DETAIL_LAYER_WIDTH, POST_DETAIL_LAYER_DATA,
    CLEAR_DATA
} from './type.actions';

const detailsLayerAction = createAction(DETAILS_LAYER_ACTION);
const detailsLayerWidth = createAction(DETAILS_LAYER_WIDTH);
const detailsLayerData = createAction(DETAILS_LAYER_DATA, (head, data) => {
    return { payload: { head, data } }
});

const formLayerAction = createAction(FORM_LAYER_ACTION);
const formLayerWidth = createAction(FORM_LAYER_WIDTH);

const postDetailLayerAction = createAction(POST_DETAIL_LAYER_ACTION);
const postDetailLayerWidth = createAction(POST_DETAIL_LAYER_WIDTH);
const postDetailLayerData = createAction(POST_DETAIL_LAYER_DATA, (head, data) => {
    return { payload: { head, data } }
});

const clearData = createAction(CLEAR_DATA);

export {
    detailsLayerAction, detailsLayerWidth, detailsLayerData,
    formLayerAction, formLayerWidth,
    postDetailLayerAction, postDetailLayerWidth, postDetailLayerData, clearData
};