/** @format */

import React, { Fragment, useEffect, useRef, useState } from 'react';
import Subheader from 'src/components/Subheader';
import TopicsCard, { TopicsFavoriteCard } from './topicsCard';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  getTopicContentList,
  getTopicInfoType,
  selectedTopic,
  setSelectedInfoTypeTab
} from 'src/store/action/topic.Action';
import useAuth from 'src/hooks/useAuth';
import { topicDisplayName } from 'src/utils/topicDisplayName';
import { navigationOpenAction } from 'src/store/action/navigationOpenAction';
import _ from 'lodash';
import { flatternTopics } from '.';
import { TabTopicBrands } from './tabTopicBrands';

const TopicsBaseLayer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
  const topicList = useSelector(state => state.topicReducer.topicList);
  const topicListByBrand = useSelector(state => state.topicReducer.topicListByBrand);
  const selectedBrand = useSelector(state => state.topicReducer.selectedBrand);
  const currentTopicId = useSelector(state => state.topicReducer.topicId);
  const { user } = useAuth();
  const [activeFavoriteItem, setActiveFavoriteItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [activeSubItem, setActiveSubItem] = useState(null);
  const [active_3rd_Child, setActive3rdChild] = useState(null);
  const [favoriteTopics, setFavoriteTopics] = useState([]);
  const topicSrcollableRef = useRef();
  const topicWrapperSrcollableRef = useRef();
  const tabTopicBrandsRef = useRef();
  const [isFixedTabBrandsContent, setIsFixedTabBrandsContent] = useState(false);

  const openLayer = ({ topicId, rootItem, index, subItem, index_2, index_3, favoriteItem, cardTopicName }) => {
    setActiveFavoriteItem(favoriteItem);

    setActiveItem(index);
    setActiveSubItem(index_2);
    setActive3rdChild(index_3);
    if (
      currentTopicId === topicId &&
      activeItem !== null &&
      typeof index_2 === 'undefined' &&
      typeof index_3 === 'undefined'
    ) {
      setActiveItem(null);
    }
    if (currentTopicId === topicId && activeSubItem !== null && typeof index_3 === 'undefined') {
      setActiveSubItem(null);
    }

    let child = subItem;
    let rootTopic = rootItem;
    let subTopic;
    let topicName;
    if (subItem && subItem.children) {
      topicName = subItem.children.find(onedata => {
        return onedata.id && onedata.id === topicId;
      });
      subTopic = topicName ? topicDisplayName(subItem) : null;
    }
    dispatch(
      selectedTopic(
        topicName
          ? topicDisplayName(topicName)
          : child
          ? topicDisplayName(child)
          : cardTopicName
          ? cardTopicName
          : rootItem,
        {
          rootTopic: rootTopic,
          subTopic: subTopic
        }
      )
    );
    dispatch(
      getTopicInfoType({
        id: topicId,
        lang: user ? user.locale : 'en'
      })
    );
    dispatch(
      getTopicContentList({
        id: topicId,
        limit: 25,
        lang: user ? user.locale : 'en'
      })
    );
    dispatch(setSelectedInfoTypeTab(0));

    if (isMobile) {
      if (index_2 >= 0 || (topicList[index] && typeof topicList[index].children === 'undefined')) {
        dispatch(navigationOpenAction(false));
      }

      if (topicList[index] && topicList[index].children) {
        const offsetTop = topicWrapperSrcollableRef.current ? topicWrapperSrcollableRef.current.offsetTop : 0;
        const offsetTitle = 55;
        setTimeout(() => {
          if (topicWrapperSrcollableRef.current && topicSrcollableRef.current) {
            topicWrapperSrcollableRef.current.scroll(1000, 0);
            topicSrcollableRef.current.scroll(0, offsetTop - offsetTitle);
          }
        }, 0);
      }
    }
  };

  useEffect(() => {
    // Find favorite topics
    setFavoriteTopics(flatternTopics(topicList).filter(topic => topic.favorite));
  }, [topicList]);

  const handleScroll = e => {
    const offsetTop = tabTopicBrandsRef.current.offsetTop - 80 - 59;
    if (e.currentTarget.scrollTop >= offsetTop) {
      setIsFixedTabBrandsContent(true);
    } else {
      setIsFixedTabBrandsContent(false);
    }
  };

  const topicListsRendered = selectedBrand
    ? [...topicListByBrand]
    : topicList && topicList.length > 0
    ? [...topicList]
    : [];

  return (
    <>
      <Box
        className={`base_topic_srollable ${isFixedTabBrandsContent ? 'fixedTab' : ''}`}
        height="100%"
        onScroll={handleScroll}>
        <Subheader title={t('topics.topicsBaseHeader')} subtitle={t('topics.topicsBaseSubHeader')} />
        <Box height="100%" marginTop="32px">
          {favoriteTopics && favoriteTopics.length ? (
            <div>
              <h4 className="base_topic_heading">Your Favorites</h4>
              <Grid container className="base_topic_favorite">
                <Grid item xs={12} md={12} className="base_topic_list">
                  {favoriteTopics
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, i) => {
                      return (
                        <TopicsFavoriteCard
                          key={i}
                          cardTopic={topicDisplayName(item)}
                          child={item.children}
                          openLayer={openLayer}
                          ItemIndex={i}
                          activeItem={activeFavoriteItem}
                          cardTopicId={item.id}
                          rootItem={item.rootItem}
                        />
                      );
                    })}
                </Grid>
              </Grid>
            </div>
          ) : null}
          <Grid container className="sticky-tab-content">
            {favoriteTopics && favoriteTopics.length > 0 ? (
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <h4 className="base_topic_heading">Topics</h4>
                </Grid>
                <Grid item>
                  <TabTopicBrands
                    setActiveItem={setActiveItem}
                    setActiveSubItem={setActiveSubItem}
                    setActive3rdChild={setActive3rdChild}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TabTopicBrands
                  setActiveItem={setActiveItem}
                  setActiveSubItem={setActiveSubItem}
                  setActive3rdChild={setActive3rdChild}
                />
              </Grid>
            )}
          </Grid>
          <Grid container ref={tabTopicBrandsRef}>
            <Grid item xs={12} md={12} className="base_topic_list">
              {topicListsRendered && topicListsRendered.length > 0
                ? topicListsRendered.map((item, i) => {
                    return (
                      <TopicsCard
                        key={i}
                        cardTopic={topicDisplayName(item)}
                        child={item.children}
                        openLayer={openLayer}
                        ItemIndex={i}
                        activeItem={activeItem}
                        activeSubItem={activeSubItem}
                        cardTopicId={item.id}
                        rootItem={item}
                        active_3rd_Child={active_3rd_Child}
                      />
                    );
                  })
                : null}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default TopicsBaseLayer;
