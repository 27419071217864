/** @format */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { GET_ATTRIBUTE_LIST, GET_USER_REPORTING_STATUS, USER_REPORTING } from './type.actions';

const getAttributeList = createAsyncThunk(GET_ATTRIBUTE_LIST, async payload => {
  try {
    const headers = {
      'Accept-Language': payload.lang
    };
    const response = await axiosInstance.get(`/user-reporting/attributes`, { headers });

    return response.data.attributes;
  } catch (error) {
    return error;
  }
});

const getUserReportingStatus = createAsyncThunk(GET_USER_REPORTING_STATUS, async payload => {
  try {
    const response = await axiosInstance.get(`/user-reporting/status`);

    return response.data.available;
  } catch (error) {
    return error;
  }
});

const submitUserReporting = createAsyncThunk(USER_REPORTING, async payload => {
  try {
    const headers = {
      'Accept-Language': payload.lang
    };
    const response = await axiosInstance.post(`/user-reporting`, payload.data, { headers });

    return response.data;
  } catch (error) {
    return error;
  }
});

export { getAttributeList, getUserReportingStatus, submitUserReporting };
