/** @format */

import React from 'react';
import { Box } from '@mui/material';
import IconLabelButtons from 'src/components/Buttons';
import { RightArrow } from 'src/components/Icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { postDetailLayerAction, postDetailLayerData, postDetailLayerWidth } from 'src/store/action/detailsLayerAction';
import { format } from 'date-fns';
import { newsById } from 'src/store/action/news.Action';
import useAuth from 'src/hooks/useAuth';
import {
  getNortifications,
  markRead,
  nortificationLayerAction,
  nortificationLayerWidth
} from 'src/store/action/nortification.Action';
import { contentPostById } from 'src/store/action/content.action';
import { CLICK_BUTTON_EVENT, CLICK_NOTIFICATION_EVENT, dataLayerPushEvent } from 'src/utils/dataLayer';

const NotificationWidget = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const detailsLayerOpen = useSelector(state => state.detailsLayerReducer.detailsOpenState);
  const postDetailOpenState = useSelector(state => state.detailsLayerReducer.postDetailOpenState);
  const notificationsList =
    props.dashboards && props.dashboards.notifications && props.dashboards.notifications.length
      ? props.dashboards.notifications
      : [];

  const handleOpenDetailsCard = () => {
    let payload = {
      limit: 25
    };
    dispatch(getNortifications(payload)).then(() => {
      dispatch(nortificationLayerAction(!detailsLayerOpen));
      dispatch(nortificationLayerWidth(91));
    });
    dataLayerPushEvent(CLICK_BUTTON_EVENT, {
      click_text: 'View all',
      headline_text: 'Notifications',
      target_screen: 'Notifications'
    });
  };

  const notificationTopic = (id, type, nId, langcode) => {
    const payload = {
      lang: user ? user.locale : 'en',
      id: id
    };

    if (payload.lang !== langcode) {
      payload.contentLang = langcode;
    }

    if (type === 'news') {
      dispatch(markRead(nId));
      dispatch(newsById(payload)).then(data => {
        if (data.payload) {
          const headPayload = {
            title: data.payload.newsPost.title
          };
          dispatch(postDetailLayerAction(!postDetailOpenState));
          dispatch(postDetailLayerWidth(91));
          dispatch(postDetailLayerData(headPayload, data.payload.newsPost));
        }
      });
    } else if (type === 'content') {
      dispatch(markRead(nId));
      dispatch(contentPostById(payload)).then(data => {
        if (data.payload) {
          const headPayload = {
            title: data.payload.contentPost.title
          };
          dispatch(postDetailLayerAction(!postDetailOpenState));
          dispatch(postDetailLayerWidth(91));
          dispatch(postDetailLayerData(headPayload, data.payload.contentPost));
        }
      });
    }
  };

  return (
    <Box className="card-container">
      <h3>{t('Notifications')}</h3>

      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        className="notification-wrapper">
        <Box>
          {notificationsList && notificationsList.length ? (
            notificationsList.slice(0, 3).map((item, i) => {
              return (
                <Box
                  className="notification-box"
                  key={i}
                  onClick={() => {
                    notificationTopic(item.internal.id, item.internal.contentType, item.id, item.langcode);
                    dataLayerPushEvent(CLICK_NOTIFICATION_EVENT, {
                      headline_text: item.title
                    });
                  }}>
                  <Box className={item.read ? 'notification-head' : 'notification-head notification-present'}>
                    {item.title}
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }} className="notification-detail">
                    <Box>
                      {item.action === 'update' && t('Notifications_Action.new_update')}
                      {item.action === 'create' && t('Notifications_Action.new_content')}
                    </Box>
                    <Box>{format(new Date(item.date * 1000), 'PPP')}</Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <h4>{t('noDataFound')}</h4>
            </Box>
          )}
        </Box>

        <Box className="widget_cta_button">
          <IconLabelButtons
            endIcon={<RightArrow />}
            type="button"
            label={t('viewAllCTA')}
            className="customButton transLabel dark endTrans"
            onClick={handleOpenDetailsCard}
            disabled={notificationsList.length === 0}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationWidget;
