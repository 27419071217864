/** @format */
import { createReducer } from '@reduxjs/toolkit';
import {
  profilePageAction,
  updateProfile,
  changePassword,
  clear,
  getNewsletterSettings,
  updateNewsletterSettings
} from 'src/store/action/profile.Action';
const initialState = {
  profilePage: 2,
  updatedProfileSuccess: undefined,
  changePassword: undefined,
  newsletterSettings: undefined,
  updateNewsletterSettingsSuccess: false,
  updateNewsletterSettingsErrorMessage: undefined
};

const profileReducer = createReducer(initialState, builder => {
  builder
    .addCase(profilePageAction, (state, action) => {
      state.profilePage = action.payload;
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.updatedProfileSuccess = action.payload;
    })
    .addCase(changePassword.fulfilled, (state, action) => {
      state.changePassword = action.payload;
    })
    .addCase(clear, (state, action) => {
      state.changePassword = initialState.changePassword;
      state.updatedProfileSuccess = initialState.updatedProfileSuccess;
      state.updateNewsletterSettingsSuccess = initialState.updateNewsletterSettingsSuccess;
      state.updateNewsletterSettingsErrorMessage = initialState.updateNewsletterSettingsErrorMessage;
    })
    .addCase(getNewsletterSettings.fulfilled, (state, action) => {
      state.newsletterSettings = action.payload;
    })
    .addCase(updateNewsletterSettings.fulfilled, (state, action) => {
      if (action.payload.errors) {
        state.updateNewsletterSettingsErrorMessage = action.payload.errors[0].message;
      }
      if (action.payload === 204) {
        state.updateNewsletterSettingsSuccess = true;
      }
    })
    .addDefaultCase((state, action) => {});
});

export default profileReducer;
