/** @format */
import { createReducer } from '@reduxjs/toolkit';
import { cardLayerAction, cardLayerWidth, cardLayerData } from '../action/cardLayerAction';

const initialState = {
  cardLayerOpen: false,
  cardLayerWidth:50,
  cardLayerData:undefined
};

const cardLayerReducer = createReducer(initialState, builder => {
  builder
    .addCase(cardLayerAction, (state, action) => {
      state.cardLayerOpen = action.payload;
    })
    .addCase(cardLayerWidth, (state, action)=>{
      state.cardLayerWidth = action.payload
    })
    .addCase(cardLayerData, (state, action)=>{
      state.cardLayerHead = action.payload.head;
      state.cardLayerData = action.payload.data
    })
    .addDefaultCase((state, action) => {});
});

export default cardLayerReducer;
