/** @format */

import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import i18n from 'src/i18n';
import { getfooterLinks } from 'src/store/action/publicPage.Action';
import CardLayer from 'src/widgets/cardLayer';

const Login = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(getfooterLinks(i18n.resolvedLanguage));
      //   return navigate('/');
      const currentUrl = window.location.href;
      return window.location.replace(
        process.env.REACT_APP_KEY_CLOCK_URL +
          '/realms/master/protocol/openid-connect/auth?client_id=extranet-frontend&redirect_uri=' +
          currentUrl +
          '&state=9f8c8dcc-e311-4a30-83ee-57013ec7925a&response_mode=fragment&response_type=code&scope=openid&nonce=f705964d-cae9-4a61-851b-888a8c5b18ae&ui_locales=en'
      );
    } else {
      return navigate('/dashboard');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <CardLayer />
    </Fragment>
  );
};

export default Login;
