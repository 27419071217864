/** @format */

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RenderRoutes from 'src/routes';
import { useTranslation } from 'react-i18next';
import useDetectBrowser from './hooks/useDetectBrowser';
import { useEffect } from 'react';

function App() {
  const { t } = useTranslation();
  const { isJavaFX } = useDetectBrowser();

  useEffect(() => {
    if (isJavaFX) {
      window.location.href = '/workshop/index.html';
    }
  }, [isJavaFX]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={{
        cancelButtonLabel: t('editorBase.labels.cancel')
      }}>
      <RenderRoutes />
    </LocalizationProvider>
  );
}

export default App;
