import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { Grid, StepButton } from '@mui/material';
import { PreviewIcon } from 'src/components/Icons';
import IconLabelButtons from 'src/components/Buttons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';



const LayerHeader = props => {

    const { postType, activeStep, togglePreview } = props;
    const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
    const {t} = useTranslation();
    const steps = [
        t('editorContent.tabNav.setup'), 
        t('editorContent.tabNav.content'), 
        t('editorContent.tabNav.settings')
    ];

    return (
        <Grid container className="steps-inner"
            sx={activeStep === 0 ? { justifyContent: 'center' } : { justifyContent: 'space-between' }}
        >
            {activeStep !== 0 &&
                <Grid item md={4} className='editor-steps-heading'>
                    {postType === "marketing" && <h1>{t('editorContent.postSetup.postType.Marketing_post')}</h1>}
                    {postType === "news" && <h1>{t('editorContent.postSetup.postType.News_article')}</h1>}
                    {postType === "content" && <h1>{t('editorContent.postSetup.postType.Content_page')}</h1>}
                    <h3>{t('editorContent.tabNav.Content_creation')} </h3>
                </Grid>
            }
            <Grid item md={4} container className="step-process" justifyContent='center'                >
                <Box sx={{ maxWidth: '340px', width: '100%' }}>
                    <Stepper activeStep={activeStep} >
                        {steps.map((label, index) => {
                            return (
                                <Step key={index}>
                                    <StepButton
                                        disableRipple
                                        className={activeStep === index ? "active" : null}
                                        icon={<Box component="span" className='stepper-icon'>{index + 1}</Box>}
                                    >
                                        {label}
                                    </StepButton>
                                    {/* <StepLabel
                            // {...labelProps}
                            >{label}</StepLabel> */}
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
            </Grid>
            {activeStep !== 0 && <Grid item md={4} className="step-btn-preview" container justifyContent="end">
                {isMobile
                    ? <IconLabelButtons
                        endIcon={<PreviewIcon color="#ffffff" />}
                        type="button"
                        onClick={togglePreview}
                        className="customButton noLabel dark borderless" />
                    : <IconLabelButtons
                        endIcon={<PreviewIcon color="#ffffff" />}
                        type="button" label={t('editorContent.tabNav.Preview')}
                        className="customButton dark"
                        onClick={togglePreview} />
                }
            </Grid>
            }
        </Grid>
    )
}

export default LayerHeader;