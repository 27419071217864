/** @format */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_CONTENT_DETAIL_BYID,
  GET_CONTENT_LIST,
  GET_CONTENT_LIST_NEXT_PAGE,
  GET_NEWS_DETAIL_BYID,
  GET_TOPIC_CONTENT_LIST,
  GET_TOPIC_CONTENT_LIST_BY_INFOTYPE,
  GET_TOPIC_CONTENT_LIST_BY_INFOTYPE_NEXT_PAGE,
  GET_TOPIC_CONTENT_LIST_NEXT_PAGE,
  GET_TOPIC_INFOTYPE,
  GET_TOPIC_LIST,
  SELECTED_TOPIC,
  SET_FILTER_OPTIONS,
  SET_SELECTED_INFO_TYPE_TAB,
  ADD_TOPIC_FAVORITE,
  DELETE_TOPIC_FAVORITE,
  SET_ORDER_OPTIONS,
  SET_SELECTED_BRAND,
  GET_TOPIC_LIST_BY_BRAND,
  SET_TOPIC_CONTENT_LIST
} from './type.actions';
import axiosInstance from '../../utils/axios';
import { ENDPOINTS } from 'src/constants';

const queryBuilder = (type, tags) => {
  tags = tags.map(tag => {
    return encodeURIComponent(tag);
  });

  return type + '[]=' + tags.join('&' + type + '[]=');
};

const subQueryBuilder = (type, tags) => {
  return '&' + queryBuilder(type, tags);
};

const defaultLimit = 50;

export const setSelectedBrand = createAction(SET_SELECTED_BRAND, brand => {
  return { payload: { brand } };
});

export const getTopicList = createAsyncThunk(GET_TOPIC_LIST, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/topics`, { headers });
  return response.data;
});

export const getTopicListByBrand = createAsyncThunk(GET_TOPIC_LIST_BY_BRAND, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/topics?filter[brand]=${payload.brand}`, { headers });
  return response.data;
});

export const getContentList = createAsyncThunk(GET_CONTENT_LIST_NEXT_PAGE, async payload => {
  const {
    keywords,
    contentTypes,
    roles,
    dateFrom,
    dateTo,
    market,
    language,
    status,
    editor,
    limit,
    onlyOwn,
    cursor,
    sort,
    order
  } = payload;
  let filters = '';

  if (keywords) {
    filters += subQueryBuilder('keywords', keywords);
  }

  if (contentTypes) {
    let contentArray = [];
    for (let i = 0; i < contentTypes.length; i++) {
      contentArray.push(contentTypes[i].id);
    }
    if (contentArray.length) {
      filters += subQueryBuilder('contentTypes', contentArray);
    }
  }

  if (roles) {
    let rolesArray = [];
    for (let i = 0; i < roles.length; i++) {
      rolesArray.push(roles[i].name);
    }
    if (rolesArray.length) {
      filters += subQueryBuilder('target-groups', rolesArray);
    }
  }

  if (dateFrom) {
    filters += `&date-from=${dateFrom}`;
  }

  if (dateTo) {
    filters += `&date-to=${dateTo}`;
  }

  if (market) {
    let marketArray = [];
    for (let i = 0; i < market.length; i++) {
      marketArray.push(market[i].name.toLowerCase());
    }
    if (marketArray.length) {
      filters += subQueryBuilder('markets', marketArray);
    }
  }

  if (language) {
    let languageArray = [];
    for (let i = 0; i < language.length; i++) {
      languageArray.push(language[i].name);
    }
    if (languageArray.length) {
      filters += subQueryBuilder('languages', languageArray);
    }
  }

  if (status !== undefined) {
    filters += `&status=${status}`;
  }

  if (editor) {
    let editorArray = [];
    for (let i = 0; i < editor.length; i++) {
      editorArray.push(editor[i].id);
    }
    if (editorArray.length) {
      filters += subQueryBuilder('editors', editorArray);
    }
  }

  if (onlyOwn) {
    filters += `&only-own=${onlyOwn}`;
  }

  if (cursor) {
    filters += `&cursor=${cursor}`;
  }

  filters += `&order=${order || `created`}`;
  filters += `&sort=${sort || `DESC`}`;

  const filterLimit = limit ?? defaultLimit;
  const response = await axiosInstance.get(`/content-list?limit=${filterLimit}${filters}`);

  return {
    // filtersData: payload,
    cursor,
    onlyOwn,
    listData: response.data
  };
});

export const getTopicContentList = createAsyncThunk(GET_TOPIC_CONTENT_LIST, async payload => {
  const { limit, cursor, id } = payload;
  let filters = '';
  if (limit) {
    filters += `limit=${limit}`;
  }
  if (cursor) {
    filters += `cursor=${cursor}`;
  }

  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/topics/${id}/content-list?${filters}`, { headers });

  return {
    topicId: id,
    data: response.data
  };
});

export const getTopicContentListNextPage = createAsyncThunk(GET_TOPIC_CONTENT_LIST_NEXT_PAGE, async payload => {
  const { limit, cursor, id, lang } = payload;
  let filters = '';
  if (limit) {
    filters += `limit=${limit}`;
  }
  if (cursor) {
    filters += `&cursor=${cursor}`;
  }
  const headers = {
    'Accept-Language': lang
  };
  const response = await axiosInstance.get(`/topics/${id}/content-list?${filters}`, { headers });
  return response.data;
});

export const getTopicInfoType = createAsyncThunk(GET_TOPIC_INFOTYPE, async payload => {
  const headers = {
    'accept-language': payload.lang
  };
  const response = await axiosInstance.get(`/topics/${payload.id}/info-types`, { headers });
  return response.data;
});

export const getContentDetailById = createAsyncThunk(GET_CONTENT_DETAIL_BYID, async payload => {
  const headers = {
    'accept-language': payload.lang
  };
  let response;
  if (payload.contentLang) {
    response = await axiosInstance.get(`/content-posts/${payload.id}/view?lang=${payload.contentLang}`, { headers });
  } else {
    response = await axiosInstance.get(`/content-posts/${payload.id}/view`, { headers });
  }
  return response.data;
});

export const getNewDetailById = createAsyncThunk(GET_NEWS_DETAIL_BYID, async payload => {
  const headers = {
    'accept-language': payload.lang
  };
  let response;
  if (payload.contentLang) {
    response = await axiosInstance.get(`/news-posts/${payload.id}/view?lang=${payload.contentLang}`, { headers });
  } else {
    response = await axiosInstance.get(`/news-posts/${payload.id}/view`, { headers });
  }

  return response.data;
});

export const selectedTopic = createAction(SELECTED_TOPIC, (topicName, breadcrum) => {
  return { payload: { topicName, breadcrum } };
});

export const setSelectedInfoTypeTab = createAction(SET_SELECTED_INFO_TYPE_TAB, tabIndex => {
  return { payload: { tabIndex } };
});

export const getTopicContentListByInfoType = createAsyncThunk(GET_TOPIC_CONTENT_LIST_BY_INFOTYPE, async payload => {
  const { limit, cursor, id, infoType } = payload;
  let filters = '';
  if (limit) {
    filters += `limit=${limit}`;
  }
  if (cursor) {
    filters += `cursor=${cursor}`;
  }
  if (infoType) {
    filters += `&info-type=${infoType}`;
  }
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/topics/${id}/content-list?${filters}`, { headers });

  return {
    infoTypeId: infoType,
    data: response.data
  };
});

export const getTopicContentListInfoTypeNextPage = createAsyncThunk(
  GET_TOPIC_CONTENT_LIST_BY_INFOTYPE_NEXT_PAGE,
  async payload => {
    const { limit, cursor, id, infoType, lang } = payload;
    let filters = '';
    if (limit) {
      filters += `limit=${limit}`;
    }
    if (cursor) {
      filters += `&cursor=${cursor}`;
    }
    if (infoType) {
      filters += `&info-type=${infoType}`;
    }
    const headers = {
      'accept-language': lang
    };
    const response = await axiosInstance.get(`/topics/${id}/content-list?${filters}`, { headers });
    return response.data;
  }
);

export const setFilterOptions = createAction(SET_FILTER_OPTIONS, payload => {
  return { payload };
});

export const addTopicFavorite = createAsyncThunk(ADD_TOPIC_FAVORITE, async payload => {
  const { uuid, id } = payload;
  await axiosInstance.post(`${ENDPOINTS.GET_USER_INFO_ENDPOINT}${uuid}/topics/${id}/favorite`);
});

export const deleteTopicFavorite = createAsyncThunk(DELETE_TOPIC_FAVORITE, async payload => {
  const { uuid, id } = payload;
  await axiosInstance.delete(`${ENDPOINTS.GET_USER_INFO_ENDPOINT}${uuid}/topics/${id}/favorite`);
});

export const setTopicContentList = createAction(SET_TOPIC_CONTENT_LIST, data => {
  return { payload: data };
});
