/** @format */

import React, { Fragment, useCallback, useRef } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotificationCard } from './contentCard';
import { useDispatch, useSelector } from 'react-redux';
import { getNortifications_Next } from 'src/store/action/nortification.Action';

const NortificationTabContent = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nextCursor = useSelector(state => state.nortificationLayerReducer.nextCursor);

  const intObserver = useRef();
  const lastPostRef = useCallback(
    post => {
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver(posts => {
        if (posts[0].isIntersecting && nextCursor) {
          const payload = {
            limit: 10,
            cursor: nextCursor
          };
          dispatch(getNortifications_Next(payload));
        }
      });

      if (post) intObserver.current.observe(post);
      // eslint-disable-next-line
    },
    [nextCursor]
  );

  return (
    <Fragment>
      {props.dataGrid && props.dataGrid ? (
        <Box className={props.wrapperClass && props.wrapperClass}>
          {props.dataGrid &&
            props.dataGrid.map((item, i) => {
              if (props.dataGrid.length === i + 1) {
                return <NotificationCard key={i} data={item} index={i} ref={lastPostRef} />;
              }
              return <NotificationCard key={i} data={item} index={i} />;
            })}
        </Box>
      ) : (
        <Box sx={{ width: '100%', mt: '20px' }}>{t('noContent')}</Box>
      )}
    </Fragment>
  );
};

export default NortificationTabContent;
