/** @format */

import Layouts from './layout';
import Dashboard from 'src/views/dashboard';
import SubDashboard from 'src/views/dashboard/SubDashboard';
import { AppIcon, PlatformsIcon, HelpIcon, SearchIconPro, StackIcon, EditorIcon } from './components/Icons';
import Editor from './views/editor';
import Topics from './views/topics';
import TopicsBaseLayer from './views/topics/topicsBaseLayer';
import EditorBaseLayer from './views/editor/editorBaseLayer';
import SearchPage from './views/search';
import SearchBaseLayer from './views/search/searchBaseLayer';

export const admin_routes = [
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    layout: Layouts,
    title: 'Dashboard',
    subItem: SubDashboard,
    icon: <AppIcon />
  },
  {
    path: '/search',
    exact: true,
    component: SearchPage,
    layout: Layouts,
    title: 'Search',
    subItem: SearchBaseLayer,
    icon: <SearchIconPro />
  },
  {
    path: '/topics',
    exact: true,
    component: Topics,
    layout: Layouts,
    title: 'Topics',
    subItem: TopicsBaseLayer,
    icon: <StackIcon />
  },
  {
    path: '/editor',
    exact: true,
    component: Editor,
    layout: Layouts,
    title: 'Editor',
    subItem: EditorBaseLayer,
    icon: <EditorIcon />
  }
];

export const user_routes = [
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    layout: Layouts,
    title: 'Dashboard',
    subItem: SubDashboard,
    icon: <AppIcon />
  },
  {
    path: '/search',
    exact: true,
    component: SearchPage,
    layout: Layouts,
    title: 'Search',
    subItem: SearchBaseLayer,
    icon: <SearchIconPro />
  },
  {
    path: '/topics',
    exact: true,
    component: Topics,
    layout: Layouts,
    title: 'Topics',
    subItem: TopicsBaseLayer,
    icon: <StackIcon />
  }
];

export const BottomNavItems = [
  {
    path: '/help',
    title: 'Help',
    exact: true,
    icon: <HelpIcon />
  },
  {
    path: '/portals',
    title: 'Portals',
    exact: true,
    icon: <PlatformsIcon />
  }
];

export const Widgets = [
  { i: 'news', name: 'news', status: true, type: 'news', data_grid: { w: 2, h: 10, x: 0, y: 0, i: 'news' } },
  { i: 'search', name: 'search', status: true, type: 'search', data_grid: { w: 1, h: 10, x: 2, y: 0, i: 'search' } },
  {
    i: 'notifications',
    name: 'notifications',
    status: true,
    type: 'notifications',
    data_grid: { w: 1, h: 10, x: 3, y: 0, i: 'notifications' }
  },
  {
    i: 'repairIt',
    name: 'repairIt',
    status: true,
    type: 'repairIt',
    data_grid: { w: 1, h: 10, x: 0, y: 9, i: 'repairIt' }
  },
  // { i: 'link', name: 'link', status: true, type: 'link', data_grid: { w: 1, h: 10, x: 1, y: 4, i: 'link' } },
  {
    i: 'marketing',
    name: 'marketing',
    status: true,
    type: 'marketing',
    data_grid: { w: 2, h: 10, x: 3, y: 4, i: 'marketing' }
  }
];

export const ENDPOINTS = {
  // User
  GET_USER_INFO_ENDPOINT: '/users/',
  GET_USER_ROLES_ENDPOINT: '/roles',

  //Widget
  GET_WIDGET_ENDPOINT: 'api/widgets/dashboard',
  GET_PUBLIC_CONTENT_INFO_ENDPOINT: 'api/public/content',
  GET_PRIVATE_CONTENT_INFO_ENDPOINT: 'api/content',

  // Footer
  GET_FOOTER_LINKS_ENDPOINTS: 'api/public/footer/links',

  // Dashboard Config
  SET_DASHBOARD_LAYOUT: 'api/dashboards/config'
};

export const VALIDATE_REGEX = {
  email: /[a-zA-Z0-9._]*@[a-zA-Z0-9.-]*\.[a-z]*/,
  phone: /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/im,
  password:
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!,;`~'":{}<>^/@#$%^&*?|\\[\]\\)(+=._-])([a-zA-Z0-9!,;`~'":{}<>^/@#$%^&*?|\\[\]\\)(+=._-]){8,30}$/
};

export const USER_PROFILE_API_URL = process.env.REACT_APP_USER_PROFILE_API_URL;

export const userRoles = [
  { id: 'driver', name: 'Driver' },
  { id: 'fleet', name: 'Fleet' },
  { id: 'workshop', name: 'Workshop' },
  { id: 'workshop-cv', name: 'Workshop CV' },
  { id: 'workshop-pv', name: 'Workshop PV' },
  { id: 'partner', name: 'Dealer/Partner' },
  { id: 'authority', name: 'Authority' },
  { id: 'trainer', name: 'Trainer/Lecturer' },
  { id: 'conti-internal', name: 'Conti Internal' }
];

export const BRANDS = {
  ALL: null,
  CONTINENTAL: 'conti',
  ATE: 'ate',
  VDO: 'vdo'
};
