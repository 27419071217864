import React, { Fragment } from 'react';
import { ScrollAble } from 'src/components/ScrollAble';




const BaseLayer = props => {
    return (
        <Fragment>
            <ScrollAble height="100%" marginTop="40px">
            </ScrollAble>
        </Fragment>
    );
}

export default BaseLayer;