/** @format */

import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { admin_routes, BottomNavItems, user_routes } from 'src/constants';
import { Avatar } from 'src/components/avatar';
import useAuth from 'src/hooks/useAuth';
import { Box } from '@mui/system';
import { helpButtonAction } from 'src/store/action/bottomNavActions';
import { StackIcon, UserIcon } from 'src/components/Icons';
import { CLICK_NAVIGATION_EVENT, dataLayerPushEvent } from 'src/utils/dataLayer';
import { SearchIconPro } from 'src/components/Icons';

const Navigation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useAuth();
  const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
  let navigate = useNavigate();

  const toggleDrawer = openEvent => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(helpButtonAction(openEvent));
    if (!openEvent) navigate(-1);
  };
  return (
    <div className={`wrapper_link`}>
      {(user && user.isAdmin) || (user && user.isEditor) ? (
        admin_routes.map((item, i) => {
          return (
            <NavLink
              key={i}
              className={`link_icon`}
              to={item.path}
              onClick={() => {
                dataLayerPushEvent(CLICK_NAVIGATION_EVENT, {
                  navigation_item: item.title
                });
              }}>
              <span className={`link_icon-icon`}>{item.icon}</span>
              <span className={`link_icon-text`}>
                {item.title === 'Dashboard' && t('menu.dashboard')}
                {/* {item.title === "Collection" && t("menu.collection")} */}
                {item.title === 'Search' && t('menu.search')}
                {item.title === 'Topics' && t('menu.topics')}
                {item.title === 'Editor' && t('menu.editor')}
                {/* {item.title === "Help" && t("menu.help")} */}
                {/* {item.title === "Portals" && t("menu.portals")} */}
                {/* {item.title} */}
              </span>
            </NavLink>
          );
        })
      ) : user && user.isWorkshop ? (
        <>
          <NavLink className={`link_icon`} to="/search">
            <span className={`link_icon-icon`}>
              <SearchIconPro />
            </span>
            <span className={`link_icon-text`}>{t('menu.search')}</span>
          </NavLink>
          <NavLink className={`link_icon`} to="/topics">
            <span className={`link_icon-icon`}>
              <StackIcon />
            </span>
            <span className={`link_icon-text`}>{t('menu.topics')}</span>
          </NavLink>
        </>
      ) : (
        user_routes.map((item, i) => {
          return (
            <NavLink key={i} className={`link_icon`} to={item.path}>
              <span className={`link_icon-icon`}>{item.icon}</span>
              <span className={`link_icon-text`}>
                {item.title === 'Dashboard' && t('menu.dashboard')}
                {/* {item.title === "Collection" && t("menu.collection")} */}
                {item.title === 'Search' && t('menu.search')}
                {item.title === 'Topics' && t('menu.topics')}
                {item.title === 'Editor' && t('menu.editor')}
                {/* {item.title === "Help" && t("menu.help")} */}
                {/* {item.title === "Portals" && t("menu.portals")} */}
                {/* {item.title} */}
              </span>
            </NavLink>
          );
        })
      )}
      {/* {admin_routes.map((item, i) => {
        return (
          <NavLink key={i} className={`link_icon`} to={item.path}>
            <span className={`link_icon-icon`}>{item.icon}</span>
            <span className={`link_icon-text`}>
              {item.title === "Dashboard" && t("menu.dashboard")}
              {item.title === "Collection" && t("menu.collection")}
              {item.title === "Search" && t("menu.search")}
              {item.title === "Topics" && t("menu.topics")}
              {item.title === "Editor" && t("menu.editor")}
              {item.title === "Help" && t("menu.help")}
              {item.title === "Portals" && t("menu.portals")}
            </span>
          </NavLink>
        );
      })} */}

      {isMobile ? (
        <Fragment>
          {user && !user.isWorkshop && (
            <>
              <Box
                sx={{
                  minWidth: '2px',
                  width: '2px',
                  height: '20px',
                  background: '#969696',
                  marginRight: '24px',
                  borderRadius: '5px'
                }}></Box>
              <NavLink className={`link_icon`} to="/profile">
                <Avatar srcImg={user?.image || ''} name={`${user?.firstName} ${user?.lastName}`} />
                <span className={`link_icon-text`}>{t('menu.profile')}</span>
              </NavLink>
            </>
          )}
          {/* for only admin */}
          {user && user.isAdmin && (
            <>
              <NavLink className={`link_icon`} to="/admin">
                <span className={`link_icon-icon`}>
                  <UserIcon />
                </span>
              </NavLink>
              <NavLink className={`link_icon`} to="/user">
                <span className={`link_icon-icon`}>
                  <UserIcon />
                </span>
              </NavLink>
            </>
          )}
          {user &&
            !user.isWorkshop &&
            BottomNavItems.map((item, i) => {
              return (
                <NavLink key={i} className={`link_icon`} to={item.path} onClick={toggleDrawer(true)}>
                  <span className={`link_icon-icon`}>{item.icon}</span>
                  {/* <span className={`link_icon-text`}>{item.title}</span> */}
                </NavLink>
              );
            })}
        </Fragment>
      ) : null}
    </div>
  );
};

export default Navigation;
