/** @format */

import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Grid, Radio, FormControlLabel, RadioGroup } from '@mui/material';
import IconLabelButtons from '../../components/Buttons';
import { RightArrow, NotReportedIcon, ReportedIcon, ListIcon, ToolIcon } from '../../components/Icons';
import SearchField from 'src/components/Search';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { repairItCheck, repairItRecord, repairItExport, clear } from '../../store/action/repairIt.actions';
import { useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
import {
  CLICK_TAB_EVENT,
  dataLayerPushEvent,
  REPAIR_DOWNLOAD_EVENT,
  REPAIR_RECORD_EVENT,
  REPAIR_REPORT_EVENT
} from 'src/utils/dataLayer';

// import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: '0 !important'
  },
  subheading: {
    marginTop: '4px',
    font: '500 10px/10px ContinentalStagSansW-M',
    letterSpacing: '0.115em'
  },
  tabWrap: {
    height: 'calc(100% - 36px - 24px)'
  },
  innerWrapper: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 24,
    right: 24,
    top: 'calc(24px + 36px + 24px + 27px)',
    bottom: 24
    // height: 'calc(100% - 36px - 24px - 27px - 24px - 24px)'
  },
  innerWrapperDSR: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 24,
    right: 24,
    top: 'calc(24px + 36px + 24px + 27px + 14px)',
    bottom: 24
  },
  stat: {
    // height: 'calc(100% - 36px - 24px - 27px - 48px)'
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchField: {
    width: '100%',
    '& .search-field.MuiPaper-root.dark': {
      maxWidth: '100%'
    }
  },
  radioBtn: {
    '& > .MuiFormControlLabel-label': {
      font: '500 17px/25px ContinentalStagSansW-M'
    },
    '& > .Mui-checked': {
      color: '#000000'
    }
  },
  TextField: {
    marginTop: '1.5rem'
  },
  recordBtn: {
    position: 'absolute',
    bottom: 24,
    right: 24
  },
  m2: {
    marginTop: '1rem'
  },
  center: {
    textAlign: 'center'
  },
  recordTab: {
    marginTop: '32px',
    '& .MuiFormControlLabel-root:last-child': {
      marginRight: 0
    },
    '& .MuiFormControlLabel-root .MuiRadio-root': {
      padding: '8px'
    }
  },
  cur_dis: 'cursor: not-allowed;'
}));

const tabProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};
const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
const RepairIt = props => {
  const dispatch = useDispatch();
  const repairItStatus = useSelector(state => state.repairItReducer.status);
  const reportId = useSelector(state => state.repairItReducer.reportId);
  const exportFile = useSelector(state => state.repairItReducer.exportFile);
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [selectedValue, setSelectedValue] = useState('');
  const [repairData, setRepairData] = useState({});
  const [searchData, setSearchData] = useState();
  const [defaultMsg, setDefaultMsg] = useState(true);
  const [isExixt, setExixt] = useState(false);
  const [seccessMsg, setSeccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  // const [pdfData, setPDFData] = useState(undefined)
  const { user } = useAuth();
  // const [csvData, setCsvData] = useState([])
  // let navigate = useNavigate();
  const { t } = useTranslation();
  const tabs = ['Report', 'Record', 'Admin'];
  // tap function onchange
  const handleChangeTap = (event, newValue) => {
    setTabValue(newValue);
    dataLayerPushEvent(CLICK_TAB_EVENT, {
      click_text: tabs[newValue]
    });
  };

  const setData = e => {
    const { name, value } = e.target;
    if (name === 'component') {
      setRepairData(stats => ({
        ...stats,
        [name]: value,
        productCode: undefined
      }));
      setSelectedValue(value);
    } else {
      setRepairData(stats => ({
        ...stats,
        [name]: value
      }));
    }
  };
  const addRecord = () => {
    setSeccessMsg('');
    setErrorMsg('');
    dispatch(repairItRecord(repairData)).then(response => {
      if (response.payload.status !== 400) {
        var isIE = false || !!document.documentMode;
        if (isIE) {
          window.navigator.msSaveBlob(response.payload, 'Drs_Report.pdf');
        } else {
          var url = window.URL || window.webkitURL;
          download(url.createObjectURL(response.payload), 'Drs_Report.pdf');
        }
      }

      dataLayerPushEvent(REPAIR_RECORD_EVENT, {
        place: 'repairIT',
        serial_number: repairData.serialNumber,
        code: repairData.productCode,
        record_type: repairData.component
      });
    });
  };

  function download(url, filename) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }

  const searchOnclick = () => {
    dispatch(repairItCheck(searchData));
    dataLayerPushEvent(REPAIR_REPORT_EVENT, {
      place: 'repairIT',
      serial_number: searchData
    });
  };
  const exportRecord = () => {
    dispatch(repairItExport());
  };

  useEffect(() => {
    if (repairItStatus === 204) {
      setDefaultMsg(false);
      setExixt(true);
      setSearchData();
      // setTimeout(() => {
      //     setExixt(false)
      //     setDefaultMsg(true)
      // }, 5000);
    }
    if (repairItStatus === 404) {
      setDefaultMsg(false);
      setExixt(false);
      setSearchData();
      // setTimeout(() => {
      //     setExixt(false)
      //     setDefaultMsg(true)
      // }, 5000);
    }
    // eslint-disable-next-line
  }, [repairItStatus]);

  useEffect(() => {
    if (reportId) {
      if (reportId.status === 400 && reportId.data) {
        const fr = new FileReader();
        fr.onload = function () {
          const error = JSON.parse(this.result);
          setErrorMsg(error.message);
        };
        fr.readAsText(reportId.data);
      } else {
        setSeccessMsg(t('repairIt.successMsg'));
      }
    }
    // eslint-disable-next-line
  }, [reportId]);

  useEffect(() => {
    if (exportFile) {
      let finalData = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(exportFile);
      var downloadLink = document.createElement('a');
      downloadLink.href = finalData;
      downloadLink.download = 'Drs_report_all.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      // window.open(`${finalData}`, '_blank')
      dispatch(clear());
      dataLayerPushEvent(REPAIR_DOWNLOAD_EVENT, {
        place: 'repairIT',
        file_name: downloadLink.download
      });
    }
    // eslint-disable-next-line
  }, [exportFile]);

  return (
    <Box className="card-container no-cta" sx={{ background: 'rgba(0, 0, 0, 0.08)' }}>
      <Box sx={{ mb: '24px' }}>
        <h3 className={classes.heading}>{t('repairIt.title')}</h3>
        {user?.drsId ? <h5 className={classes.subheading}>DRS-ID {user?.drsId}</h5> : null}
      </Box>
      <Box className={`tabbed-navigation ${classes.tabWrap}`}>
        <Tabs value={tabValue} onChange={handleChangeTap} aria-label="basic tabs example">
          <Tab label={t('repairIt.tab_1')} {...tabProps(0)} />;
          <Tab label={t('repairIt.tab_2')} {...tabProps(1)} />;
          <Tab label={t('repairIt.tab_3')} {...tabProps(2)} />;
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Box className={user?.drsid ? classes.innerWrapperDSR : classes.innerWrapper}>
            {defaultMsg ? (
              <Box className={classes.stat}>
                <ToolIcon width="33.33" height="33.33" />
                <p className={`repairIt_text ${classes.m2}`}>{t('repairIt.defaultText')}</p>
              </Box>
            ) : isExixt ? (
              <Box className={classes.stat}>
                {/* <img src={repairIcon} width={35} alt=""/> */}
                <ReportedIcon />
                <p className={`repairIt_text ${classes.m2}`}>{t('repairIt.repotedTxt')}</p>
              </Box>
            ) : (
              <Box className={classes.stat}>
                <NotReportedIcon />
                {/* <img src={repairIcon} width={35} alt=""/> */}
                <p className={`repairIt_text ${classes.m2}`}>{t('repairIt.notRepotedTxt')}</p>
              </Box>
            )}
            <Box className={classes.searchField}>
              <SearchField
                placeholder={t('repairIt.searchPlaceholder')}
                onChangeSearch={e => setSearchData(e.target.value)}
                searchData={searchData}
                classes="dark"
                onClick={searchOnclick}
              />
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Grid container className={classes.recordTab}>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={selectedValue}
              name="component"
              value={selectedValue}
              onChange={setData}
              sx={{ justifyContent: 'space-between', width: '100%' }}>
              <FormControlLabel
                value="Injektor EU3"
                className={classes.radioBtn}
                control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} />}
                label="EU3"
              />
              <FormControlLabel
                value="Injektor EU4"
                className={classes.radioBtn}
                control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} />}
                label="EU4"
              />
              <FormControlLabel
                value="Injektor EU5"
                className={classes.radioBtn}
                control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} />}
                label="EU5"
              />
              <FormControlLabel
                value="Pumpe"
                className={classes.radioBtn}
                control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} />}
                label="Pump"
              />
            </RadioGroup>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <OutlinedInputFieldsLight
                name="serialNumber"
                fullWidth
                onChange={setData}
                value={repairData && repairData.serialNumber ? repairData.serialNumber : ''}
                placeholder={t('repairIt.field_1_placeholder')}
                sx={{ mt: 2 }}
              />

              {/* <TextField name="serialNumber" variant="outlined" className={`${classes.TextField} text-fields light`} fullWidth placeholder={t('repairIt.field_1_placeholder')} InputLabelProps={{
                                shrink: true,
                            }} onChange={setData} value={repairData && repairData.serialNumber} /> */}
            </Grid>
            <Grid item xs={12} md={12}>
              <OutlinedInputFieldsLight
                name="productCode"
                fullWidth
                onChange={setData}
                value={
                  selectedValue === 'Injektor EU5' && repairData && repairData.productCode ? repairData.productCode : ''
                }
                disabled={selectedValue !== 'Injektor EU5'}
                placeholder={t('repairIt.field_2_placeholder')}
                sx={{ mt: 2 }}
              />

              {/* <TextField name="productCode" variant="outlined" 
                            className={selectedValue !== "Injektor EU5" ?`${classes.TextField} text-fields light` : `${classes.TextField} text-fields light ${classes.cur_dis}`} fullWidth placeholder={t('repairIt.field_2_placeholder')} InputLabelProps={{
                                shrink: true,
                            }} onChange={setData} value={repairData ? repairData.productCode:null} disabled={selectedValue !== "Injektor EU5"}/> */}
            </Grid>
            {seccessMsg ? (
              <Grid sx={{ mt: 2, fontSize: '1.2rem', color: '#25bd04' }}>{seccessMsg}</Grid>
            ) : (
              errorMsg && (
                <Grid sx={{ mt: 2, fontSize: '1.2rem', color: '#e2000e', marginRight: '130px' }}>{errorMsg}</Grid>
              )
            )}

            <Box>
              <IconLabelButtons
                endIcon={<RightArrow />}
                type="button"
                label="Record"
                className={`${classes.recordBtn} customButton transLabel dark endTrans`}
                onClick={addRecord}
              />
            </Box>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Box className={classes.innerWrapper}>
            <Box className={classes.stat}>
              <ListIcon width="25.83" height="32.5" />
              <p className={`repairIt_text ${classes.m2}`}>{t('repairIt.downloadTxt')}</p>
            </Box>

            <div className={classes.center}>
              <IconLabelButtons
                endIcon={<RightArrow />}
                type="button"
                label="Download"
                className="customButton dark"
                onClick={exportRecord}
              />
            </div>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default RepairIt;
