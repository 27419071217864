/** @format */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CLEAR,
  CREATE_CONTENT_POST,
  CREATE_MARKETING_POST,
  CREATE_NEWS_POST,
  DELETE_CONTENT_POST,
  DELETE_MARKETING_POST,
  DELETE_NEWS_POST,
  EDIT_CONTENT_POST,
  EDIT_MARKETING_POST,
  EDIT_NEWS_POST,
  EDITOR_OPEN,
  EDITOR_STEP,
  FILETR_CLEAR_FILTERS,
  FILETR_DELETE_SEARCH_OPTIONS,
  FILETR_SEARCH_OPTIONS,
  GET_CONTACT_DEPARTMENTS,
  GET_EDITORS_LIST,
  GET_INFO_LIST,
  GET_MARKET_LIST,
  GET_ROLES,
  GET_TAG_LIST,
  PREVIEW_OPEN,
  PREVIEW_STEP,
  UPDATE_CONTENT_POST,
  UPDATE_MARKETING_POST,
  UPDATE_NEWS_POST,
  TOGGLE_POST,
  CLONE_CONTENT_POST,
  CLONE_NEWS_POST,
  CLONE_MARKETING_POST
} from './type.actions';
import axiosInstance from '../../utils/axios';

const editorOpen = createAction(EDITOR_OPEN);
const editorStepAction = createAction(EDITOR_STEP);
const previewOpen = createAction(PREVIEW_OPEN);
const previewStepAction = createAction(PREVIEW_STEP);

const filtersearchOption = createAction(FILETR_SEARCH_OPTIONS);
const filterdeleteSearchOption = createAction(FILETR_DELETE_SEARCH_OPTIONS);
const filterclearFilters = createAction(FILETR_CLEAR_FILTERS);
const clearData = createAction(CLEAR);

const getInfoTypeList = createAsyncThunk(GET_INFO_LIST, async payload => {
  try {
    const headers = {
      'Accept-Language': payload.lang
    };
    const response = await axiosInstance.get(`/info-types`, { headers });

    return response.data;
  } catch (error) {
    return error;
  }
});

const getRolesList = createAsyncThunk(GET_ROLES, async payload => {
  try {
    const headers = {
      'Accept-Language': payload.lang
    };
    const response = await axiosInstance.get(`/roles`, { headers });

    return response.data;
  } catch (error) {
    return error;
  }
});

const getContactDepartmentList = createAsyncThunk(GET_CONTACT_DEPARTMENTS, async payload => {
  try {
    const headers = {
      'Accept-Language': payload.lang
    };
    const response = await axiosInstance.get(`/departments`, { headers });

    return response.data;
  } catch (error) {
    return error;
  }
});

const getTagList = createAsyncThunk(GET_TAG_LIST, async payload => {
  try {
    const headers = {
      'Accept-Language': payload.lang
    };
    const response = await axiosInstance.get(`/tags`, { headers });

    return response.data;
  } catch (error) {
    return error;
  }
});

const saveNewsArticle = createAsyncThunk(CREATE_NEWS_POST, async payload => {
  try {
    const headers = {
      'Content-type': 'multipart/form-data'
    };
    const response = await axiosInstance.post(`/news-posts`, payload, { headers });
    return response.data;
  } catch (error) {
    return error;
  }
});

const saveMarketingPost = createAsyncThunk(CREATE_MARKETING_POST, async payload => {
  try {
    const headers = {
      'Content-type': 'multipart/form-data'
    };
    const response = await axiosInstance.post(`/marketing-posts`, payload, { headers });
    return response.data;
  } catch (error) {
    return error;
  }
});

const saveContentPost = createAsyncThunk(CREATE_CONTENT_POST, async payload => {
  try {
    const headers = {
      'Content-type': 'multipart/form-data'
    };
    const response = await axiosInstance.post(`/content-posts`, payload, { headers });
    return response.data;
  } catch (error) {
    return error;
  }
});

const getMarketList = createAsyncThunk(GET_MARKET_LIST, async payload => {
  try {
    const headers = {
      'Accept-Language': payload.lang
    };
    const response = await axiosInstance.get(`/markets`, { headers });

    return response.data;
  } catch (error) {
    return error;
  }
});

const getEditorsList = createAsyncThunk(GET_EDITORS_LIST, async payload => {
  try {
    const headers = {
      'Accept-Language': payload.lang
    };
    const response = await axiosInstance.get(`/editors`, { headers });

    return response.data;
  } catch (error) {
    return error;
  }
});

const cloneContentPost = createAsyncThunk(CLONE_CONTENT_POST, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/content-posts/${payload.id}/editor`, { headers });

  return response.data;
});
const cloneNewsPost = createAsyncThunk(CLONE_NEWS_POST, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/news-posts/${payload.id}/editor`, { headers });

  return response.data;
});
const cloneMarketingPost = createAsyncThunk(CLONE_MARKETING_POST, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/marketing-posts/${payload.id}/editor`, { headers });

  return response.data;
});

const editContentPost = createAsyncThunk(EDIT_CONTENT_POST, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/content-posts/${payload.id}/editor`, { headers });

  return response.data;
});

const editNewsPost = createAsyncThunk(EDIT_NEWS_POST, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/news-posts/${payload.id}/editor`, { headers });

  return response.data;
});

const editMarketingPost = createAsyncThunk(EDIT_MARKETING_POST, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`/marketing-posts/${payload.id}/editor`, { headers });

  return response.data;
});

const updateMarketingPost = createAsyncThunk(UPDATE_MARKETING_POST, async data => {
  try {
    const { id, payload } = data;
    const headers = {
      'Content-type': 'multipart/form-data'
    };

    return await axiosInstance.post(`/marketing-posts/${id}?_method=PUT`, payload, { headers });
  } catch (error) {
    return error;
  }
});

const updateNewsPost = createAsyncThunk(UPDATE_NEWS_POST, async data => {
  try {
    const { id, payload } = data;
    const headers = {
      'Content-type': 'multipart/form-data'
    };
    return await axiosInstance.post(`/news-posts/${id}?_method=PUT`, payload, { headers });
  } catch (error) {
    return error;
  }
});

const updateContentPost = createAsyncThunk(UPDATE_CONTENT_POST, async data => {
  const { id, payload } = data;
  const headers = {
    'Content-type': 'multipart/form-data'
  };

  return await axiosInstance.post(`/content-posts/${id}?_method=PUT`, payload, { headers });
});

const deleteNewsPost = createAsyncThunk(DELETE_NEWS_POST, async id => {
  const response = await axiosInstance.delete(`/news-posts/${id}`);

  return response.data;
});
const deleteMarketingsPost = createAsyncThunk(DELETE_MARKETING_POST, async id => {
  const response = await axiosInstance.delete(`/marketing-posts/${id}`);

  return response.data;
});
const deleteContentPost = createAsyncThunk(DELETE_CONTENT_POST, async id => {
  const response = await axiosInstance.delete(`/content-posts/${id}`);

  return response.data;
});

const disablePost = createAsyncThunk(TOGGLE_POST, async payload => {
  const { type, id, published, locale } = payload;
  const requestBody = {
    [type + 'Post']: [
      {
        published: published,
        locale: locale
      }
    ]
  };

  const response = await axiosInstance.patch(`/${type}-posts/${id}`, requestBody);

  return response.data;
});

export {
  editorStepAction,
  editorOpen,
  previewOpen,
  previewStepAction,
  saveNewsArticle,
  saveContentPost,
  getRolesList,
  getContactDepartmentList,
  getTagList,
  saveMarketingPost,
  getMarketList,
  getEditorsList,
  filtersearchOption,
  filterdeleteSearchOption,
  filterclearFilters,
  cloneContentPost,
  cloneNewsPost,
  cloneMarketingPost,
  editContentPost,
  editNewsPost,
  editMarketingPost,
  clearData,
  updateMarketingPost,
  updateNewsPost,
  updateContentPost,
  deleteNewsPost,
  deleteMarketingsPost,
  deleteContentPost,
  getInfoTypeList,
  disablePost
};
