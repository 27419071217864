/** @format */

import { css, cx } from '@emotion/css';
import { Box } from '@mui/system';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { formatISO9075 } from 'date-fns';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import CloseButton from 'src/components/Buttons/closeButton';
import { CrossIcon } from 'src/components/Icons';
import OutlinedInputFieldsDark from 'src/components/Inputs/OutlinedInputDark';
import MultiSelectDropdownDark from 'src/components/MultiSelectDropdowns/MultiSelectDropdownDark';
import MultiSelectTree from 'src/components/MultiSelectTree';
import { ScrollAble } from 'src/components/ScrollAble';
import SearchChip from 'src/components/SearchChips';
import Subheader from 'src/components/Subheader';
import useAuth from 'src/hooks/useAuth';
import { navigationOpenAction } from 'src/store/action/navigationOpenAction';
import { clearFilters, searchKeyword } from 'src/store/action/search.actions';
import { getTopicList, setTopicContentList } from 'src/store/action/topic.Action';
import { dataLayerPushEvent, SEARCH_FILTER_EVENT } from 'src/utils/dataLayer';

const SearchBaseLayer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
  const [searchData, setSearchData] = useState({});
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);
  const { user } = useAuth();
  const topicList = useSelector(state => state.topicReducer.topicList);
  const { clauses, searchLatestResults } = useSelector(state => state.searchReducer);
  const infoTypeList = useSelector(state => state.editorReducer.infoTypeList).map(o => ({
    ...o,
    name: t('infoTypes.' + o.name)
  }));
  const [checkMount, setCheckMount] = useState(undefined);
  const contentType = [
    { id: 'content', name: t('searchContent.chip.content') },
    { id: 'news', name: t('searchContent.chip.news') }
  ];
  const language = [
    { id: 'de', name: 'DE' },
    { id: 'en', name: 'EN' },
    { id: 'it', name: 'IT' },
    { id: 'nl', name: 'NL' },
    { id: 'fr', name: 'FR' },
    { id: 'es', name: 'ES' },
    { id: 'pl', name: 'PL' }
  ];

  const customCSS = {
    subHead: css`
      h6.subnav_header-sub {
        color: #fff;
      }
    `,
    searchClose: css`
      position: absolute;
      top: 36px;
      right: 40px;
      @media screen and (max-width: 767px) {
        right: 16px;
      }
    `
  };

  useEffect(() => {
    if (topicList.length === 0) {
      const payload = {
        lang: user ? user.locale : 'en'
      };
      dispatch(getTopicList(payload));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCheckMount(true);
    if (checkMount) {
      onSearch(clauses);
      if (clauses.length === 0) {
        setSearchData({});
      }
    }
    // eslint-disable-next-line
  }, [clauses]);

  useEffect(() => {
    const topicContentList = searchLatestResults.map(item => ({
      id: item.id,
      title: item.title,
      teaser: item.teaser,
      releaseDate: item.creationDate,
      tags: item.tags,
      infoType: {
        name: item.infoType[0],
        id: null
      },
      topics: item.topics.map(topic => ({
        id: null,
        name: topic,
        displayName: null
      })),
      locale: item.language,
      contentType: item.contentType
    }));
    dispatch(setTopicContentList(topicContentList));
  }, [dispatch, searchLatestResults]);

  const onSearch = useCallback(
    keywords => {
      let payload = {
        ...searchData,
        keywords,
        lang: user ? user.locale : 'en'
      };
      setSearchData(stats => ({
        ...stats,
        lang: user ? user.locale : 'en',
        keywords: keywords
      }));
      dispatch(searchKeyword(payload));
      dispatch(searchKeyword({ ...payload, order: 'latest' }));

      if (isMobile) dispatch(navigationOpenAction(false));
    },
    [dispatch, searchData, user]
  );

  const setDataByName = useCallback(
    (name, data) => {
      if (name === 'topicId') {
        let topicIds = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i]._children && data[i]._children.length) {
            for (let j = 0; j < data[i]._children.length; j++) {
              topicIds.push({ value: data[i]._children[j] });
            }
            topicIds.push({ value: data[i].id });
          } else {
            topicIds.push({ value: data[i].id });
          }
        }
        Object.assign(searchData, {
          [name]: topicIds
        });

        dataLayerPushEvent(SEARCH_FILTER_EVENT, {
          action: 'Search parameter',
          parameter_name: name,
          parameter_value: data.map(d => d.label).join(',')
        });
      } else {
        Object.assign(searchData, {
          [name]: data
        });
        onSearch(clauses);
      }
    },
    [clauses, onSearch, searchData]
  );

  const resetFilters = () => {
    dispatch(clearFilters());
    setSearchData({});
    setDateFrom(undefined);
    setDateTo(undefined);
    dispatch(searchKeyword({ lang: user ? user.locale : 'en' }));
    dispatch(searchKeyword({ lang: user ? user.locale : 'en', order: 'latest' }));
    dataLayerPushEvent(SEARCH_FILTER_EVENT, {
      action: 'Reset search filter'
    });
  };

  return (
    <Fragment>
      <Box className={cx(customCSS.subHead)}>
        <Subheader title={t('searchPage.title')} subtitle={!isMobile && t('searchPage.subTitle')} />
      </Box>

      <ScrollAble height="100%" marginTop="40px">
        <SearchChip />
        <Box className="filter-options">
          <Box>{t('searchPage.infoType')}</Box>
          <Box>
            <MultiSelectDropdownDark
              listdata={infoTypeList}
              setdatabyname={setDataByName}
              placeholder={t('searchPage.infoType_placeholder')}
              name="infoType"
              defaultValue={searchData.infoType}
            />
          </Box>
          <Box>{t('searchPage.contentType')}</Box>
          <Box>
            <MultiSelectDropdownDark
              listdata={contentType}
              setdatabyname={setDataByName}
              placeholder={t('searchPage.contentType_placeholder')}
              name="contentTypes"
              defaultValue={searchData.contentTypes}
            />
          </Box>
          <Box>{t('searchPage.topic')}</Box>
          <Box style={{ position: 'relative' }}>
            <MultiSelectTree
              placeholder={t('searchPage.topic_placeholder')}
              data={topicList}
              name="topicId"
              setdatabyname={setDataByName}
              defaultValue={searchData.topicId}
              dark={true}
              onBlur={() => {
                let payload = {
                  ...searchData,
                  clauses,
                  lang: user ? user.locale : 'en'
                };
                dispatch(searchKeyword(payload));
              }}
            />
            {/* <MultiSelectDropdownDark
              listdata={topicList}
              setdatabyname={setDataByName}
              placeholder={t('searchPage.topic_placeholder')}
              name="topicId"
              defaultValue={searchData.topicId}
            /> */}
          </Box>
          <Box>{t('searchPage.language')}</Box>
          <Box>
            <MultiSelectDropdownDark
              listdata={language}
              setdatabyname={setDataByName}
              placeholder={t('searchPage.language_placeholder')}
              name="language"
              defaultValue={searchData.language}
            />
          </Box>
          <Box sx={{ mt: `${isMobile ? '48px' : '0'}` }}>{t('searchPage.timeFrame')}</Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box className="datePickerContainer">
              <MobileDatePicker
                toolbarTitle={t('editorBase.labels.title')}
                value={dateFrom === undefined ? t('searchPage.dateFormat') : dateFrom}
                onChange={newValue => {
                  setDateFrom(newValue);
                  setDataByName('dateFrom', formatISO9075(new Date(newValue), { representation: 'date' }));
                  dataLayerPushEvent(SEARCH_FILTER_EVENT, {
                    action: 'Search parameter',
                    parameter_name: 'dateFrom',
                    parameter_value: formatISO9075(new Date(newValue), { representation: 'date' })
                  });
                }}
                maxDate={dateTo ? dateTo : undefined}
                closeOnSelect={true}
                renderInput={params => (
                  <OutlinedInputFieldsDark
                    sx={{ flex: 1 }}
                    value="dd.mm.yyyy"
                    placeholder={t('searchPage.dateFormat')}
                    {...params}
                  />
                )}
              />
            </Box>
            <Box sx={{ ml: '8px' }} className="datePickerContainer">
              <MobileDatePicker
                toolbarTitle={t('editorBase.labels.title')}
                value={dateTo === undefined ? t('searchPage.dateFormat') : dateTo}
                minDate={dateFrom ? dateFrom : undefined}
                onChange={newValue => {
                  setDateTo(newValue);
                  setDataByName('dateTo', formatISO9075(new Date(newValue), { representation: 'date' }));
                  dataLayerPushEvent(SEARCH_FILTER_EVENT, {
                    action: 'Search parameter',
                    parameter_name: 'dateTo',
                    parameter_value: formatISO9075(new Date(newValue), { representation: 'date' })
                  });
                }}
                closeOnSelect={true}
                renderInput={params => (
                  <OutlinedInputFieldsDark
                    sx={{ flex: 1 }}
                    {...params}
                    value="dd.mm.yyyy"
                    placeholder={t('searchPage.dateFormat')}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        {!isMobile && (
          <Box sx={{ mt: '60px' }}>
            <IconLabelButtons
              type="button"
              label={t('searchPage.cta')}
              className="customButton dark"
              onClick={resetFilters}
            />
          </Box>
        )}
      </ScrollAble>
      {isMobile && (
        <Box className={cx(customCSS.searchClose)}>
          <CloseButton
            label={t('searchPage.cta')}
            className={`close-button light`}
            endIcon={<CrossIcon />}
            onClick={resetFilters}
          />
        </Box>
      )}
    </Fragment>
  );
};

export default SearchBaseLayer;
