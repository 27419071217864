/** @format */

import React from 'react';
import { Button } from '@mui/material';

const IconLabelButtons = props => {
  return (
    <Button
      variant="contained"
      type={props.type}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      label={props.label}
      disableRipple={true}
      sx={{ background: `${props.bgcolor}`, color: `${props.txtcolor}` }}
      {...props}
      disabled={!!props.disabled}>
      {props.label}
    </Button>
  );
};
export default IconLabelButtons;
