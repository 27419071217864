/** @format */

import { Box, Stack, Grid } from '@mui/material';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import PlaceholderImage from '../../assets/imgs/preview-1.svg';
import { RightArrow, ChevronLeft, ChevronRight } from '../../components/Icons';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ChipBasic } from 'src/components/Tags';
import IconLabelButtons from 'src/components/Buttons';
import { css, cx } from '@emotion/css';
import { CLICK_BUTTON_EVENT, CLICK_SLIDER_EVENT, dataLayerPushEvent } from 'src/utils/dataLayer';
import { useSelector } from 'react-redux';

const customCSS = {
  headingMobile: css`
    display: none;
  `,
  marketWidgetContent: css`
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 599px) {
      flex-direction: column;
    }
  `,
  marketingImage: css`
    flex-basis: calc(50% - 24px);
    max-width: calc(50% - 24px);
    width: 100%;
    img {
      object-fit: cover;
      max-width: 100%;
      height: 406px;
      border-radius: 10px;
      @media screen and (max-width: 599px) {
        height: 180px;
        width: 100%;
      }
    }
    @media screen and (max-width: 599px) {
      max-width: 100%;
    }
  `,
  marketingDetails: css`
    padding-top: 108px;
    padding-bottom: calc(50px + 24px);
    flex-basis: calc(50% - 24px);
    max-width: calc(50% - 24px);
    margin-left: 48px;
    &.detailsMobile {
      max-width: none;
    }
    h3 {
      margin-top: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: 599px) {
        margin-bottom: 8px;
      }
      &.headingMobile {
        -webkit-line-clamp: 2;
      }
    }
    @media screen and (max-width: 599px) {
      padding-top: 24px;
      margin-left: 0;
      padding-bottom: 53px;
    }
  `,
  detailsText: css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @media screen and (max-width: 599px) {
      -webkit-line-clamp: 1;
    }
  `
};

const SampleNextArrow = props => {
  const { onClick } = props;
  return (
    <IconLabelButtons
      endIcon={<ChevronRight />}
      type="button"
      className={`next customButton noLabel dark small-tailless`}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = props => {
  const { onClick, currentSlide } = props;
  return (
    <IconLabelButtons
      endIcon={<ChevronLeft />}
      type="button"
      className={`previous customButton noLabel dark small-tailless`}
      onClick={onClick}
      disabled={currentSlide === 0 ? true : false}
    />
  );
};

const MarketingWidget = props => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const mobileWidth = useSelector(state => state.navigationReducer.mobileWidth);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: currentSlide => {
      const currentPost = marketingPosts[currentSlide];
      dataLayerPushEvent(CLICK_SLIDER_EVENT, {
        headline_text: currentPost.title
      });
    }
  };

  const marketingPosts =
    props.dashboards && props.dashboards.marketingPosts && props.dashboards.marketingPosts.length
      ? props.dashboards.marketingPosts
      : [];

  return (
    <Box className={'card-container no-cta'} ref={ref}>
      {marketingPosts.length === 0 && <h3>{t('infoTypes.Marketing')}</h3>}
      {/* {marketingSlides} */}

      {marketingPosts.length ? (
        <Slider {...settings} className="market_widget">
          {marketingPosts.map((item, index) => (
            <Box key={index} sx={{ position: 'relative' }}>
              <Box className={cx(customCSS.marketWidgetContent)}>
                <Box className={cx(customCSS.marketingImage)}>
                  <img src={item.pictureUrl ? item.pictureUrl : PlaceholderImage} alt={item.title} />
                </Box>
                <Box className={cx(customCSS.marketingDetails, mobileWidth && `detailsMobile`)}>
                  <Stack direction="row" spacing={1}>
                    {item.tags &&
                      item.tags.map((tag, index) => {
                        return (
                          <ChipBasic label={tag.name} key={index} background="rgba(0, 0, 0, 0.05)" color="#000000" />
                        );
                      })}
                  </Stack>
                  <h3 className={cx(mobileWidth && `headingMobile`)}>{item.title}</h3>
                  {!mobileWidth && <p className={cx(customCSS.detailsText)}>{item.text}</p>}
                </Box>
              </Box>

              <Box className="widget_cta_button" sx={{ bottom: 0, right: 0 }}>
                <IconLabelButtons
                  endIcon={<RightArrow />}
                  type="button"
                  label={item.linkTitle ? item.linkTitle : 'View Post'}
                  className={`customButton transLabel dark endTrans`}
                  onClick={() => {
                    dataLayerPushEvent(CLICK_BUTTON_EVENT, {
                      click_text: item.title
                    });
                    window.open(item.link, '_blank');
                  }}
                />
              </Box>
            </Box>
          ))}
        </Slider>
      ) : (
        <Grid item md={12}>
          <h4>{t('noDataFound')}</h4>
        </Grid>
      )}
    </Box>
  );
};

export default MarketingWidget;
