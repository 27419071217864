import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const FilledInputFieldLight = props => {
    return (
        <Box
            component="form"
            // sx={{
            //     '& > :not(style)': { m: 1 },
            // }}
            noValidate
            autoComplete="off"
        >
            <TextField
                id="filled-basic"
                variant="filled"
                className='text-fields filled-input-field light'
                {...props}
            />
        </Box>
    );
}

export default FilledInputFieldLight;