/** @format */
import { createReducer } from '@reduxjs/toolkit';
import {
  adminTopicsDeleteTopic,
  adminTopicsEdit,
  adminTopicsEditorOpen,
  deleteTopicClause,
  searchTopicClause
} from '../action/adminTopics.Action';

const initialState = {
  openState: false,
  idTopicEdit: null,
  idDeleteTopic: null,
  topicClauses: []
};

const adminTopicsReducer = createReducer(initialState, builder => {
  builder
    .addCase(adminTopicsEditorOpen, (state, action) => {
      state.openState = action.payload;
    })
    .addCase(adminTopicsEdit, (state, action) => {
      state.idTopicEdit = action.payload;
    })
    .addCase(adminTopicsDeleteTopic, (state, action) => {
      state.idDeleteTopic = action.payload;
    })
    .addCase(searchTopicClause, (state, action) => {
      state.topicClauses = [...state.topicClauses, action.payload];
    })
    .addCase(deleteTopicClause, (state, action) => {
      state.topicClauses = state.topicClauses.filter(clause => clause !== action.payload);
    })
    .addDefaultCase((state, action) => {});
});

export default adminTopicsReducer;
