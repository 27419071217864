import { Box } from '@mui/system';
import React from 'react';
import { ChevronRight } from 'src/components/Icons';

const MenuList = props => {
    return (
        <button className={(props.active === props.index ? "topics-card-wrapper active " : "topics-card-wrapper ") + props.className ?? ''} onClick={()=>props.onChangePage(props.index, props.id)}>
            <Box component="span" className="card-topic">{props.menu}</Box>
            <ChevronRight color="#ffffff" width={10} height={15} />
        </button>
    );
}

export default MenuList;