import React, { Fragment } from 'react';
import { Button } from '@mui/material';
import { CloseSmallIcon } from '../Icons';

const CloseButton = (props) => {
    return (
        <Fragment>
            {
                props.startclose ? <Button
                    startIcon={props.startIcon || <CloseSmallIcon height="14.83" width="14.83" />}
                    label={props.label}
                    disableRipple={true}
                    {...props}
                >
                    {props.label}
                </Button>
                    : props.endclose ? <Button
                        endIcon={props.endIcon || <CloseSmallIcon height="14.83" width="14.83" />}
                        label={props.label}
                        disableRipple={true}
                        {...props}
                    >
                        {props.label}
                    </Button>
                        : <Button
                            label={props.label}
                            disableRipple={true}
                            {...props}
                        >
                            {props.label}
                        </Button>
            }

        </Fragment>
    );
};
export default CloseButton;
