import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import {
    UncheckIconYellow, CheckIconYellow, UncheckDarkTransparent,
    CheckDarkTransparent, UncheckLightTransparent, CheckLightTransparent
} from 'src/components/Icons';

export const CheckboxFilled = ({ disabled, onChange, defaultChecked, ...rest }) => {
    return (
        <Checkbox
            className="checkboxLabel"
            icon={<UncheckIconYellow />}
            checkedIcon={<CheckIconYellow />}
            defaultChecked={defaultChecked}
            onChange={onChange}
            {...rest}
            disabled={disabled}
            disableRipple
        />
    );
};

export const CheckLabelFilled = ({ label, disabled, onChange, defaultChecked, ...rest }) => {
    return (
        <FormControlLabel className="checkboxLabel"
            control={
                <Checkbox
                    icon={<UncheckIconYellow />}
                    checkedIcon={<CheckIconYellow />}
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                    {...rest}
                    disableRipple
                />
            }
            label={label}
            disabled={disabled}
        />
    );
};

export const CheckboxTransparentLight = ({ disabled, onChange, defaultChecked, ...rest }) => {
    return (
        <Checkbox
            className="checkboxLabel"
            icon={<UncheckLightTransparent />}
            checkedIcon={<CheckLightTransparent />}
            defaultChecked={defaultChecked}
            onChange={onChange}
            {...rest}
            disabled={disabled}
            disableRipple
        />
    );
};

export const CheckLabelTransparentLight = ({ label, disabled, onChange, defaultChecked, ...rest }) => {
    return (
        <FormControlLabel className="checkboxLabel"
            control={
                <Checkbox
                    icon={<UncheckLightTransparent />}
                    checkedIcon={<CheckLightTransparent />}
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                    {...rest}
                    disableRipple
                />
            }
            label={label}
            disabled={disabled}
        />
    );
};

export const CheckboxTransparentDark = ({ disabled, onChange, defaultChecked, ...rest }) => {
    return (
        <Checkbox
            className="checkboxLabel"
            icon={<UncheckDarkTransparent />}
            checkedIcon={<CheckDarkTransparent />}
            defaultChecked={defaultChecked}
            onChange={onChange}
            {...rest}
            disabled={disabled}
            disableRipple
        />
    );
};

export const CheckLabelTransparentDark = ({ label, disabled, onChange, defaultChecked, ...rest }) => {
    return (
        <FormControlLabel className="checkboxLabel"
            control={
                <Checkbox
                    icon={<UncheckDarkTransparent />}
                    checkedIcon={<CheckDarkTransparent />}
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                    {...rest}
                    disableRipple
                />
            }
            label={label}
            disabled={disabled}
        />
    );
};