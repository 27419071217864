/** @format */
import { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from 'src/contexts/KeycloakContext';

export default function VDOLogin() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/dashboard');
    }
  }, [auth, navigate]);

  return (
    <div className="vdo-login">
      <div>VDO-Login landing page for getting refreshToken and idToken</div>
      {auth.error && auth.error.message && (
        <div className="vdo-login-error">
          {auth.error.message}
          <br />
          {JSON.stringify(auth)}
        </div>
      )}
    </div>
  );
}
