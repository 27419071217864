import { createReducer } from '@reduxjs/toolkit';
import {
    detailsLayerAction, detailsLayerWidth, detailsLayerData,
    formLayerAction, formLayerWidth,
    postDetailLayerAction, postDetailLayerWidth, postDetailLayerData, clearData
} from 'src/store/action/detailsLayerAction';

const initialState = {
    detailsOpenState: false,
    detailsLayerWidth: 60,
    detailsLayerData: undefined,
    detailsLayerHead: undefined,
    formLayerOpen: false,
    layerWidth: 100,
    postDetailOpenState: false,
    postDetailWidth: 100,
    postDetailLayerData: undefined,
    postDetailLayerHead: undefined,
};

const detailsLayerReducer = createReducer(initialState, builder => {
    builder
        .addCase(detailsLayerAction, (state, action) => {
            state.detailsOpenState = action.payload;
        })
        .addCase(detailsLayerWidth, (state, action) => {
            state.detailsLayerWidth = action.payload
        })
        .addCase(formLayerAction, (state, action) => {
            state.formLayerOpen = action.payload
        })
        .addCase(formLayerWidth, (state, action) => {
            state.layerWidth = action.payload
        })
        .addCase(postDetailLayerAction, (state, action) => {
            state.postDetailOpenState = action.payload
        })
        .addCase(postDetailLayerWidth, (state, action) => {
            state.postDetailWidth = action.payload
        })
        .addCase(postDetailLayerData, (state, action) => {
            state.postDetailLayerHead = action.payload.head;
            state.postDetailLayerData = action.payload.data
        })
        .addCase(detailsLayerData, (state, action) => {
            state.detailsLayerHead = action.payload.head;
            state.detailsLayerData = action.payload.data
        })
        .addCase(clearData, (state, action) => {
            state.postDetailLayerHead = initialState.postDetailLayerHead;
            state.postDetailLayerData = initialState.postDetailLayerData;
            state.detailsLayerHead = initialState.detailsLayerHead;
            state.detailsLayerData = initialState.detailsLayerData;
        })
        .addDefaultCase((state, action) => { });
});

export default detailsLayerReducer;
