/** @format */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  PROFILE_PAGE,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  CLEAR,
  ON_BOARDING_COMPLETE,
  GET_NEWSLETTER_SETTINGS,
  UPDATE_NEWSLETTER_SETTINGS
} from './type.actions';
import { ENDPOINTS, USER_PROFILE_API_URL } from 'src/constants';
import axiosInstance from '../../utils/axios';

const profilePageAction = createAction(PROFILE_PAGE);
const onBoardingComplete = createAsyncThunk(ON_BOARDING_COMPLETE, async payload => {
  const { uuid } = payload;
  const response = await axiosInstance.post(`${ENDPOINTS.GET_USER_INFO_ENDPOINT}${uuid}/onboarding-complete`);
  return response.status;
});
const updateProfile = createAsyncThunk(UPDATE_PROFILE, async payload => {
  const { uuid } = payload;
  const response = await axiosInstance.put(`${ENDPOINTS.GET_USER_INFO_ENDPOINT}${uuid}`, payload);
  return response.status;
});

const changePassword = createAsyncThunk(CHANGE_PASSWORD, async payload => {
  const { uuid } = payload;
  const response = await axiosInstance.put(`${ENDPOINTS.GET_USER_INFO_ENDPOINT}${uuid}/password`, payload);
  return response.status;
});
const getNewsletterSettings = createAsyncThunk(GET_NEWSLETTER_SETTINGS, async payload => {
  const { uuid } = payload;
  const response = await axiosInstance.get(`${ENDPOINTS.GET_USER_INFO_ENDPOINT}${uuid}/newsletter/config`);
  return response.data;
});
const updateNewsletterSettings = createAsyncThunk(UPDATE_NEWSLETTER_SETTINGS, async payload => {
  const { settings, uuid } = payload;
  try {
    const response = await axiosInstance.put(`${ENDPOINTS.GET_USER_INFO_ENDPOINT}${uuid}/newsletter/config`, {
      ...settings
    });
    return response.status;
  } catch (error) {
    return { errors: error.data.errors };
  }
});
const clear = createAction(CLEAR);

// User Profile Picture
const updateUserProfilePicture = async payload => {
  try {
    const response = await axiosInstance.get(`${USER_PROFILE_API_URL}api/v1/user/picture`, {
      responseType: 'arraybuffer'
    });
    // dispatch(setUserProfilePicture({ response }));
  } catch (err) {}
};

const uploadUserProfilePicture = async payload => {
  const { data } = payload;
  try {
    const response = await axiosInstance.post(`${USER_PROFILE_API_URL}api/v1/user/picture`, data, {
      headers: {
        'Content-Type': 'image/png'
      }
    });
    if (!response.error) {
      // dispatch(updateUserProfilePictureSuccess());
    }
  } catch (err) {
    // dispatch(updateUserProfilePictureFailure({ error: err.message }));
  }
};

export {
  profilePageAction,
  updateProfile,
  changePassword,
  clear,
  onBoardingComplete,
  getNewsletterSettings,
  updateNewsletterSettings,
  updateUserProfilePicture,
  uploadUserProfilePicture
};
