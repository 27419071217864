/** @format */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_EXTRANET_ACCESS, GET_FOOTER_LINK, GET_PAGE_DATA, UPDATE_USER_ROLES } from './type.actions';
import axiosInstance from '../../utils/axios';

export const getfooterLinks = createAsyncThunk(GET_FOOTER_LINK, async lang => {
  const headers = {
    'Accept-Language': lang
  };
  const response = await axiosInstance.get(`public/footer-links`, { headers });

  return response.data;
});

export const getPageData = createAsyncThunk(GET_PAGE_DATA, async payload => {
  const headers = {
    'Accept-Language': payload.lang
  };
  const response = await axiosInstance.get(`public/contents/${payload.id}/view`, { headers });

  return response.data;
});

export const getExtraNetAccess = createAsyncThunk(GET_EXTRANET_ACCESS, async payload => {
  try {
    return await axiosInstance.post(`candidates/${payload.uuid}/request`);
  } catch (error) {
    return { error };
  }
});

export const updatUserRoles = createAsyncThunk(UPDATE_USER_ROLES, async payload => {
  try {
    await axiosInstance.put(`users/${payload.uuid}/roles`, payload);
    return {};
  } catch (error) {
    return { error };
  }
});
