/** @format */
import { createReducer } from '@reduxjs/toolkit';
import { getNewsList, newsById, clear } from 'src/store/action/news.Action';
const initialState = {
  newsList: [],
  newsData:""
};

const newsReducer = createReducer(initialState, builder => {
  builder
    .addCase(getNewsList.fulfilled, (state, action) => {
      state.newsList = action.payload.newsPosts;
    })
    .addCase(getNewsList.rejected, (state, action) => {
        //   state.loading = false;
          state.newsList = initialState.newsList
      })
    .addCase(newsById.fulfilled, (state, action) => {
        state.newsData = action.payload.newsPost;
      })
    .addCase(clear, (state, action) => {
        state.newsData = initialState.newsData;
    })  
    .addDefaultCase((state, action) => {});
});

export default newsReducer;
