/** @format */

import { css, cx } from '@emotion/css';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import MultiSelectDropdownDark from 'src/components/MultiSelectDropdowns/MultiSelectDropdownDark';
import UserSearchChip from 'src/components/SearchChips/userSearch';
import Subheader from 'src/components/Subheader';
import useAuth from 'src/hooks/useAuth';
import { updateUserListPayload } from 'src/store/action/user.Action';
import { CheckLabelTransparentDark } from 'src/components/Checkbox';
import { BRANDS } from 'src/constants';
import TopicSearchChip from 'src/components/SearchChips/topicSearch';
import { deleteTopicClause } from 'src/store/action/adminTopics.Action';

const customCSS = {
  filterRow: css`
    margin-top: 22px;
    align-items: center;
  `,
  filterLabel: css`
    font: 500 17px/25px ContinentalStagSansW-M;
    @media screen and (max-width: 899px) {
      margin-bottom: 16px !important;
    }
  `
};

const AdminTopicsBaseLayer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const roles = useSelector(state => state.adminReducer.roles);
  const [roleOptions, setRoleOptions] = useState([]);
  const userListPayload = useSelector(state => state.userReducer.userListPayload);
  const rolesList = useSelector(state => state.editorReducer.rolesList);
  const topicClauses = useSelector(state => state.adminTopicsReducer.topicClauses);
  const [status, setStatus] = useState();
  const [filter, setFilter] = useState({ role: [], brand: [], status: true });

  useEffect(() => {
    const locale = user ? user.locale : 'en';
    if (roles[locale] && roleOptions.length === 0) setRoleOptions(roles[locale]);
  }, [roleOptions, user, roles]);

  const resetFilters = () => {
    setFilter({
      role: [],
      brand: [],
      status: true
    });
    topicClauses.map(c => {
      dispatch(deleteTopicClause(c));
    });
  };

  const handleFilter = (name, data) => {
    setFilter({ ...filter, [name]: data });
  };

  return (
    <>
      <Subheader title={t('editorBase.title')} />
      <Grid height="100%" marginTop="32px">
        <Box className="editorBaseWrapper">
          <Box className="search-chip dark">
            <Grid className={cx(customCSS.filterLabel)} sx={{ marginBottom: 4, opacity: '.6' }}>
              {t('adminTopics.searchChip')}
            </Grid>
            <TopicSearchChip />
          </Box>
          <Grid container className={cx(customCSS.filterRow)} sx={{ mt: '40px' }}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('adminTopics.role')}
            </Grid>
            <Grid item xs={12} lg={7}>
              <MultiSelectDropdownDark
                listdata={rolesList}
                name="role"
                setdatabyname={(name, data) => handleFilter(name, data)}
                defaultValue={filter.role || []}
                value={filter.role || []}
              />
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)} sx={{ mt: '40px' }}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('adminTopics.brand')}
            </Grid>
            <Grid item xs={12} lg={7}>
              <MultiSelectDropdownDark
                listdata={Object.keys(BRANDS).map(b => ({ name: b, id: b }))}
                name="brand"
                setdatabyname={(name, data) => handleFilter(name, data)}
                defaultValue={filter.brand || []}
                value={filter.brand || []}
              />
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)}>
            <Grid item xs={12} lg={5} className={cx(customCSS.filterLabel)}>
              {t('adminTopics.status')}
            </Grid>
            <Grid
              item
              container
              xs={12}
              lg={7}
              className={cx(customCSS.checkboxWrapper)}
              justifyContent={'space-between'}>
              <Grid className={cx(customCSS.checkBox)}>
                <CheckLabelTransparentDark
                  label={t('adminTopics.published')}
                  name="status"
                  value="true"
                  onChange={() => setFilter({ ...filter, status: true })}
                  checked={filter.status}
                />
              </Grid>
              <Grid className={cx(customCSS.checkBox)}>
                <CheckLabelTransparentDark
                  label={t('adminTopics.unPublished')}
                  name="status"
                  value="false"
                  onChange={() => setFilter({ ...filter, status: false })}
                  checked={!filter.status}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={cx(customCSS.filterRow)} sx={{ mt: '32px' }}>
            <IconLabelButtons
              type="button"
              label={t('adminTopics.cta')}
              className="customButton dark"
              onClick={resetFilters}
            />
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default AdminTopicsBaseLayer;
