/** @format */

import React, { Fragment, useEffect } from 'react';
import { DashboardHeader } from 'src/components/PageHeader';
import { ScrollAble } from 'src/components/ScrollAble';
import WidgetGrid from './WidgetGrid';
import { css, cx } from '@emotion/css';
import CardLayer from 'src/widgets/cardLayer';
import DetailsLayer from 'src/widgets/cardLayer/detailsLayer';
import PostDetailLayer from 'src/widgets/cardLayer/postDetailLayer';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import Footer from 'src/layout/footer';
import NortificationLayer from 'src/widgets/cardLayer/nortificationCard';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.isWorkshop) {
      navigate('/topics');
    }
    // eslint-disable-next-line
  }, []);

  const customCSS = {
    dashboardHead: css`
      margin-bottom: 51px;
      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    `,
    footer: css`
      margin: 50px 25px 72px;
    `
  };

  return (
    <Fragment>
      <Box className={cx(customCSS.dashboardHead)}>
        {/* <DashboardHeader text={`${t('welcomeMessage')}, ${user?.firstName ? user.firstName : 'uhmm..'}!`} /> */}
        <DashboardHeader text={`${t('welcomeMessage')}, ${user?.firstName}!`} />
      </Box>
      <ScrollAble
        className={css`
          width: calc(100% + 40px) !important; // only for grid-widget dashboard
          margin-left: -24px;
          margin-right: -48px;
        `}>
        <WidgetGrid />
        <CardLayer />
        <DetailsLayer />
        <PostDetailLayer />
        <NortificationLayer />
        <Box className={cx(customCSS.footer)}>
          <Footer />
        </Box>
      </ScrollAble>
    </Fragment>
  );
};

export default Dashboard;
