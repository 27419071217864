/** @format */

import { Box } from '@mui/system';
import { useRef, useCallback } from 'react';
import ContentList from './contentList';
import { useDispatch, useSelector } from 'react-redux';
import { getTopicContentListInfoTypeNextPage } from 'src/store/action/topic.Action';
import useAuth from 'src/hooks/useAuth';
// import useAuth from "src/hooks/useAuth";
// import { postDetailLayerAction, postDetailLayerWidth, postDetailLayerData } from "src/store/action/detailsLayerAction";
const SubTabContent = props => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  // const postDetailOpenState = useSelector(state => state.detailsLayerReducer.postDetailOpenState);
  const subtopicNextCursor = useSelector(state => state.topicReducer.subtopicNextCursor);
  const topicId = useSelector(state => state.topicReducer.topicId);
  // const topicsList = [
  //     { topic: "ProViu®ASL360 - neue Softwareversion V4.5.28", tag1: "Marketing", tag2: "PPT", tag3: "Topic", tag4: "DE", date: "June 06, 2022" },
  //     { topic: "DTCO® 4.0", tag1: "Marketing", tag2: "PPT", tag3: "Topic", tag4: "DE", date: "June 07, 2022" },
  // ]

  // Pagination Start
  const intObserver = useRef();
  const lastPostRef = useCallback(
    post => {
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver(posts => {
        if (posts[0].isIntersecting && subtopicNextCursor) {
          // debugger
          const payload = {
            lang: user ? user.locale : 'en',
            id: topicId,
            limit: 25,
            cursor: subtopicNextCursor
          };
          dispatch(getTopicContentListInfoTypeNextPage(payload));
        }
      });

      if (post) intObserver.current.observe(post);
      // eslint-disable-next-line
    },
    [subtopicNextCursor]
  );

  // const onClickTopicDetail = (type, topicDetailsId)=>{
  //     if(type === 'content'){
  //         let payload = {
  //             lang: user ? user.locale:'en',
  //             id:topicDetailsId
  //         }
  //         dispatch(getContentDetailById(payload)).then((data) => {
  //             if (data.payload) {
  //                 const head_payload = {
  //                     title: data.payload.contentPost.title ,
  //                 }
  //                 dispatch(postDetailLayerAction(!postDetailOpenState))
  //                 dispatch(postDetailLayerWidth(91))
  //                 dispatch(postDetailLayerData(head_payload, data.payload.contentPost))
  //               }
  //           })
  //     }
  //     if(type === 'news'){
  //         let payload = {
  //             lang: user.locale,
  //             id:topicDetailsId
  //         }
  //         dispatch(getNewDetailById(payload)).then((data) => {
  //             const head_payload = {
  //                 title: data.payload.newsPost.title ,
  //             }
  //             dispatch(postDetailLayerAction(!postDetailOpenState))
  //             dispatch(postDetailLayerWidth(91))
  //             dispatch(postDetailLayerData(head_payload, data.payload.newsPost))

  //           })
  //     }

  // }
  const { topicContentList } = props;
  return (
    <Box>
      {topicContentList.map((item, i) => {
        if (topicContentList.length === i + 1) {
          return <ContentList key={i} item={item} ref={lastPostRef} />;
        }
        return <ContentList key={i} item={item} />;
      })}
    </Box>
  );
};
export default SubTabContent;
