/** @format */

import React, { Fragment, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// import constants
import { admin_routes } from 'src/constants';
import Layouts from './layout';
import ShowComponents from './components';
import Login from './views/login';
import Profile from './views/profile';
import BaseLayer from './views/profile/baseLayer';
import ErrorPage from './views/errors';
import ErrorBaseLayer from './views/errors/baseLayer';
import Loader from './components/Loader';
import Admin from './views/admin';
import AdminCandidates from './views/adminCandidates';
import TopicPage from './views/TopicPage';
import NoAccess from './views/noAccess';
import useAuth from './hooks/useAuth';
import { dataLayerPushEvent, USER_INFORMATION_EVENT } from './utils/dataLayer';
import VDOLogin from './views/vdoLogin';
import AdminBaseLayer from './views/adminCandidates/adminBaseLayer';
import AdminUsers from './views/adminUsers';
import UserBaseLayer from './views/adminUsers/userBaseLayer';
import AdminTopicsBaseLayer from './views/adminTopics/adminTopicsBaseLayer';
import HelpPage from './views/help';
import AdminTopics from './views/adminTopics';
// import Portals from './views/portals';

let profileData = {
  title: 'Profile',
  subItem: BaseLayer
};

let ErrorData = {
  title: '',
  subItem: ErrorBaseLayer
};
let NoneSub = {
  title: '',
  subItem: ''
};
const RenderRoutes = props => {
  const { user } = useAuth();
  useEffect(() => {
    if (user && user.uuid && user.roles) {
      dataLayerPushEvent(USER_INFORMATION_EVENT, {
        userType: user.isAdmin ? 'admin' : user.isEditor ? 'editor' : user.isWorkshop ? 'workshop' : 'user',
        interestGroup: user.roles[0],
        user: `${user.firstName} ${user.lastName}`,
        user_id: user.uuid,
        lang: user.locale
      });
    }
  }, [user]);
  return (
    <BrowserRouter>
      <Routes>
        {admin_routes.map((route, i) => {
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              element={
                <Layout data={route}>
                  {/* send data route */}
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>{route.title}</title>
                  </Helmet>
                  <Suspense fallback={<Loader />}>
                    <Component />
                  </Suspense>
                </Layout>
              }
            />
          );
        })}

        <Route
          path={`/components`}
          element={
            <Suspense fallback={<Loader />}>
              <ShowComponents />
            </Suspense>
          }
        />
        <Route
          path={`/`}
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path={`/register`}
          element={
            <Suspense fallback={<Loader />}>
              <NoAccess />
            </Suspense>
          }
        />
        <Route
          path={`/vdohub`}
          element={
            <Suspense fallback={<Loader />}>
              <VDOLogin />
            </Suspense>
          }
        />

        <Route
          path={`/profile`}
          exact={true}
          element={
            <Layouts data={profileData}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Profile</title>
              </Helmet>
              <Suspense fallback={<Loader />}>
                <Profile />
              </Suspense>
            </Layouts>
          }
        />
        <Route
          path={`/profile/user-reporting`}
          exact={true}
          element={
            <Layouts data={profileData}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Profile - User reporting</title>
              </Helmet>
              <Suspense fallback={<Loader />}>
                <Profile />
              </Suspense>
            </Layouts>
          }
        />
        <Route
          path={`/admin`}
          exact={true}
          element={
            <Layouts
              data={{
                title: 'Admin'
              }}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Admin</title>
              </Helmet>
              <Suspense fallback={<Loader />}>
                <Admin />
              </Suspense>
            </Layouts>
          }
        />
        <Route
          path={`/admin/candidates`}
          exact={true}
          element={
            <Layouts
              data={{
                title: 'Candidates',
                subItem: AdminBaseLayer
              }}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Admin</title>
              </Helmet>
              <Suspense fallback={<Loader />}>
                <AdminCandidates />
              </Suspense>
            </Layouts>
          }
        />
        <Route
          path={`/admin/user`}
          exact={true}
          element={
            <Layouts
              data={{
                title: 'Extranet User',
                subItem: UserBaseLayer
              }}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Extranet User</title>
              </Helmet>
              <Suspense fallback={<Loader />}>
                <AdminUsers />
              </Suspense>
            </Layouts>
          }
        />
        <Route
          path={`/admin/topics`}
          exact={true}
          element={
            <Layouts
              data={{
                title: 'Extranet User',
                subItem: AdminTopicsBaseLayer
              }}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Topics management</title>
              </Helmet>
              <Suspense fallback={<Loader />}>
                <AdminTopics />
              </Suspense>
            </Layouts>
          }
        />
        <Route
          path={`/help`}
          exact={true}
          element={
            <Layouts data={ErrorData} title="Help">
              <Helmet>
                <meta charSet="utf-8" />
                <title>Help</title>
              </Helmet>
              <HelpPage />
            </Layouts>
          }
        />
        <Route
          path={`/portals`}
          exact={true}
          element={
            <Layouts data={ErrorData}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Portals</title>
              </Helmet>
              {/* <Portals /> */}
            </Layouts>
          }
        />
        <Route
          path={`/marketing/:pageId`}
          exact={true}
          element={
            <Layouts data={ErrorData}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Page</title>
              </Helmet>
              <Suspense fallback={<Loader />}>
                <TopicPage />
              </Suspense>
              {/* <Portals /> */}
            </Layouts>
          }
        />
        <Route
          path={`/news/:pageId`}
          exact={true}
          element={
            <Layouts data={ErrorData}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Page</title>
              </Helmet>
              <Suspense fallback={<Loader />}>
                <TopicPage />
              </Suspense>
              {/* <Portals /> */}
            </Layouts>
          }
        />
        <Route
          path={`/content/:pageId`}
          exact={true}
          element={
            <Layouts data={ErrorData}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Page</title>
              </Helmet>
              <Suspense fallback={<Loader />}>
                <TopicPage />
              </Suspense>
              {/* <Portals /> */}
            </Layouts>
          }
        />
        <Route
          path={`*`}
          exact={true}
          element={
            <Layouts data={ErrorData}>
              <Helmet>
                <meta charSet="utf-8" />
                {/* <title>Profile</title> */}
              </Helmet>
              <Suspense fallback={<Loader />}>
                <ErrorPage />
              </Suspense>
            </Layouts>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RenderRoutes;
