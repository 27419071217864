/** @format */

import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import { AutoCompleteSelectLight } from 'src/components/Dropdowns/LightDropdown';
import { CheckIcon } from 'src/components/Icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
import MultiSelectDropdownLight from 'src/components/MultiSelectDropdowns/MultiSelectDropdownLight';
import SwitchLabel from 'src/components/switch/SwitchLabel';
import useAuth from 'src/hooks/useAuth';
import { getMarketList, getRolesList } from 'src/store/action/editor.Action';
import { getAttributeList, getUserReportingStatus, submitUserReporting } from 'src/store/action/userReporting.Action';
import { cx, css } from '@emotion/css';
import { VALIDATE_REGEX } from 'src/constants';

const customCSS = {
  checkingStatus: css`
    color: #333;
  `
};

const UserReporting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useAuth();
  const marketList = useSelector(state => state.editorReducer.marketList);
  const rolesList = useSelector(state => state.editorReducer.rolesList);
  const attributeList = useSelector(state => state.userReportingReducer.attributeList);
  const status = useSelector(state => state.userReportingReducer.status);
  const [valid, setValid] = useState(false);
  const [second, setSecond] = useState(5);
  const [reporting, setReporting] = useState({
    recipient: '',
    locale: '',
    includeRoles: false,
    role: [],
    attributes: []
  });

  const setDataByName = (name, value) => {
    setReporting({ ...reporting, [name]: value });
  };

  const setData = e => {
    setDataByName(e.target.name, e.target.value);
  };

  const setIncludeRoles = () => {
    setDataByName('includeRoles', !reporting.includeRoles);
  };

  const submitReporting = () => {
    const payload = {
      lang: user ? user.locale : 'en',
      data: {
        recipient: user.email,
        includeRoles: reporting.includeRoles,
        locale: reporting.locale ? reporting.locale.id : undefined,
        roles: reporting.role ? reporting.role.map(role => role.name) : undefined,
        attributes: reporting.attributes.length ? reporting.attributes.map(attr => attr.id) : undefined
      }
    };
    dispatch(submitUserReporting(payload)).then(() => {
      dispatch(getUserReportingStatus());
    });
  };

  useEffect(() => {
    const payload = {
      lang: user ? user.locale : 'en'
    };
    if (marketList && marketList.length === 0) {
      dispatch(getMarketList(payload));
    }
  }, [user, marketList, rolesList, attributeList, dispatch]);

  useEffect(() => {
    const payload = {
      lang: user ? user.locale : 'en'
    };
    if (rolesList && rolesList.length === 0) {
      dispatch(getRolesList(payload));
    }
  }, [user, rolesList, dispatch]);

  useEffect(() => {
    const payload = {
      lang: user ? user.locale : 'en'
    };
    if (attributeList && attributeList.length === 0) {
      dispatch(getAttributeList(payload));
    }
  }, [user, attributeList, dispatch]);

  useEffect(() => {
    if (user && marketList.length) {
      const market = marketList.find(market => market.locale === 'en');
      setDataByName('locale', { id: market.locale, name: market.market });
      setDataByName('recipient', user.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, marketList]);

  useEffect(() => {
    if (!status) {
      const timeout = setTimeout(() => {
        if (second > 0) setSecond(second - 1);
      }, 1000);
      if (second === 0) {
        dispatch(getUserReportingStatus()).then(() => {
          setSecond(5);
        });
      }
      return () => clearTimeout(timeout);
    }
  }, [status, second, dispatch]);

  useEffect(() => {
    dispatch(getUserReportingStatus());
  }, [dispatch]);

  useEffect(() => {
    setValid(!!reporting.recipient && VALIDATE_REGEX.email.test(reporting.recipient));
  }, [reporting]);

  return (
    <Grid container={true} sx={{ paddingBottom: 20 }}>
      <h2 className="page_title">{t('profile.menu.userReporting')}</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem' }}>
            <Grid item xs={12} lg={3} className="lbl_txt">
              {t('userReporting.recipient')}*
            </Grid>
            <Grid item xs={12} lg={8}>
              <OutlinedInputFieldsLight
                placeholder={t('userReporting.recipient')}
                onChange={setData}
                value={reporting.recipient}
                name="recipient"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={12} lg={3} className="lbl_txt">
              {t('userReporting.locale')}
            </Grid>
            <Grid item xs={12} lg={8}>
              <Box className={`autoComplete-wrapper light`}>
                <Autocomplete
                  className="autoComplete light"
                  disablePortal
                  id="combo-box-locale"
                  options={marketList.map(market => ({
                    id: market.locale,
                    label: market.market
                  }))}
                  sx={{ width: 300 }}
                  renderInput={params => {
                    return <TextField {...params} placeholder={t('Select')} />;
                  }}
                  style={{ marginTop: 16, marginBottom: 8 }}
                  name="locale"
                  value={reporting.locale}
                  onChange={(event, newValue) => {
                    setDataByName('locale', newValue);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={12} lg={3} className="lbl_txt">
              {t('userReporting.includeRoles')}
            </Grid>
            <Grid item xs={12} lg={8} style={{ marginTop: 16, marginBottom: 8 }}>
              <SwitchLabel
                label={t('userReporting.includeRolesContent')}
                name="activateNotifications"
                onChange={setIncludeRoles}
                checked={reporting.includeRoles}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={12} lg={3} className="lbl_txt">
              {t('userReporting.role')}
            </Grid>
            <Grid item xs={12} lg={8}>
              <Box className={`autoComplete-wrapper light`}>
                <MultiSelectDropdownLight
                  listdata={rolesList.map(role => ({ id: role.id, name: role.name }))}
                  setdatabyname={setDataByName}
                  name="role"
                  defaultValue={reporting.role}
                  style={{ marginTop: 16, marginBottom: 8 }}
                />
                {/* <Autocomplete
                  className="autoComplete light"
                  disablePortal
                  id="combo-box-role"
                  options={rolesList.map(role => ({ id: role.id, label: role.name }))}
                  sx={{ width: 300 }}
                  renderInput={params => {
                    return <TextField {...params} placeholder={t('Select')} />;
                  }}
                  style={{ marginTop: 16, marginBottom: 8 }}
                  name="role"
                  value={reporting.role}
                  onChange={(event, newValue) => {
                    setDataByName('role', newValue);
                  }}
                /> */}
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={12} lg={3} className="lbl_txt">
              {t('userReporting.attribute')}
            </Grid>
            <Grid item xs={12} lg={8}>
              <MultiSelectDropdownLight
                listdata={attributeList.map(attr => ({ id: attr, name: attr }))}
                setdatabyname={setDataByName}
                name="attributes"
                defaultValue={reporting.attributes}
                style={{ marginTop: 16, marginBottom: 8 }}
              />
            </Grid>
          </Grid>
        </Grid>
        {status && (
          <Grid item xs={12} xl={12}>
            <div style={{ textAlign: 'right' }}>
              <IconLabelButtons
                endIcon={<CheckIcon />}
                type="button"
                label={t('userReporting.submitBtn')}
                className="customButton dark"
                onClick={submitReporting}
                disabled={!valid}
              />
            </div>
          </Grid>
        )}
        {status !== null && (
          <Grid item xs={12}>
            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid item xs={12} lg={3} className="lbl_txt">
                {t('userReporting.status')}
              </Grid>
              {status ? (
                <Grid
                  item
                  xs={12}
                  lg={8}
                  style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: 16, color: 'green' }}>
                  <>
                    <CheckCircleIcon /> {t('userReporting.available')}
                  </>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  lg={8}
                  style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: 16, color: 'red' }}>
                  <>
                    <ErrorIcon /> {t('userReporting.reportIsBeingGenerated')}{' '}
                    <span className={cx(customCSS.checkingStatus)}>
                      {second === 0 ? 'Fetching...' : `Checking status... ${second}s`}
                    </span>
                  </>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default UserReporting;
