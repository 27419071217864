/** @format */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getTopicListByBrand, setSelectedBrand } from 'src/store/action/topic.Action';
import useAuth from 'src/hooks/useAuth';
import { BRANDS } from 'src/constants';

export const TabTopicBrands = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const selectedBrand = useSelector(state => state.topicReducer.selectedBrand);

  const changeBrand = brand => {
    dispatch(setSelectedBrand(brand));
    dispatch(
      getTopicListByBrand({
        lang: user ? user.locale : 'en',
        brand: brand
      })
    );
    props.setActiveItem(null);
    props.setActiveSubItem(null);
    props.setActive3rdChild(null);
  };

  return (
    <ul className="topic_brand_lists">
      {Object.keys(BRANDS).map(brand => {
        return (
          <li key={brand}>
            <button
              onClick={() => changeBrand(BRANDS[brand])}
              className={(!selectedBrand && brand === 'ALL') || selectedBrand === BRANDS[brand] ? 'active' : ''}>
              {brand}
            </button>
          </li>
        );
      })}
    </ul>
  );
};
